import { getClient, putClient } from '../modules/client';
import { Contact } from '../slices/contacts';

export const fetchContactsDB = (address: string | null) => {
  if (!address) return {} as Contact;
  return getClient(`contacts/${address}`);
};

export const setContactDB = async ({
  address,
  data,
}: {
  address: string;
  data: any;
}) => {
  await putClient(`contacts/${address}`, data);
  return data;
};