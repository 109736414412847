import { StyleSheet, View } from 'react-native';
import HylloText from '../components/HylloText';
import { useState } from 'react';
import Feather from '@expo/vector-icons/Feather';
import { NeutralColor, ShadeColor, textWeights } from '../utils';
import { Card, CardRow } from '../components/Card';
import ListSeparator from '../components/ListSeparator';
import HylloSwitch from '../components/HylloSwitch';

const styles = StyleSheet.create({
  notificationsLabel: {
    ...textWeights.semibold,
    color: NeutralColor.Gray06,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  privateChatsContainer: {
    padding: 16,
    backgroundColor: ShadeColor.Black,
    flex: 1,
  },
});

const GroupChatsSettings = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [messagePreview, setMessagePreview] = useState(false);
  return (
    <View style={styles.privateChatsContainer}>
      <Card>
        <CardRow style={{ justifyContent: 'space-between' }}>
          <HylloText>Show Notifications</HylloText>
          <HylloSwitch
            onValueChange={() => setShowNotifications(!showNotifications)}
            value={showNotifications}
          />
        </CardRow>
      </Card>
      <View>
        <HylloText style={styles.notificationsLabel}>More Options</HylloText>
        <Card>
          <CardRow style={{ justifyContent: 'space-between' }}>
            <HylloText>Message Preview</HylloText>
            <HylloSwitch
              onValueChange={() => setMessagePreview(!messagePreview)}
              value={messagePreview}
            />
          </CardRow>
          <ListSeparator marginLeft={16} />
          <CardRow style={{ justifyContent: 'space-between' }}>
            <HylloText>Sound</HylloText>
            <Feather
              name="chevron-right"
              size={24}
              color={NeutralColor.Gray07}
            />
          </CardRow>
        </Card>
      </View>
    </View>
  );
};

export default GroupChatsSettings;
