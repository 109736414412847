import React, { useRef, useState } from 'react';
import { ScrollView, Dimensions, StyleSheet, ImageBackground, LayoutRectangle } from 'react-native';
import { ShadeColor } from '../utils/colors';

import Video from '../components/Video/Video';
import HylloText from '../components/HylloText';

const default_uri = 'http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8';

const BackgroundImage = require('../assets/background-image.png');

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: ShadeColor.Black,
        alignItems: 'center',
    },
    scrollContainer: {
        flex: 1,
        width: '100%'
    },
    scrollContentContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 50,
    },
    playerContainer: {
        marginTop: 20,
        height: Dimensions.get('window').height / 2,
        //width: Dimensions.get('window').width,
        width: '100%',
        backgroundColor: '#ffffff'
    },
});

const VideoPlayer = () => {
    const refScrollView = useRef<any>(null);
    const [isFullscreen, setFullscreen] = useState(false);
    const [playerLayout, setPlayerLayout] = useState<LayoutRectangle>();

    return (
        <ImageBackground
            source={BackgroundImage}
            style={styles.mainContainer}
            resizeMode="stretch"
        >
            <ScrollView
                scrollEnabled={!isFullscreen}
                ref={refScrollView}
                onContentSizeChange={() => {
                    if (isFullscreen) {
                        refScrollView.current?.scrollTo({
                            y: playerLayout?.y,
                            animated: true 
                        });
                    } else {
                        refScrollView.current?.scrollTo({
                            y: 0,
                            animated: true,
                        });
                    }
                }}
                style={styles.scrollContainer}
                contentContainerStyle={styles.scrollContentContainer}
            >
                <HylloText>Livestream</HylloText>
                <Video
                    style={styles.playerContainer}
                    source={
                        { uri: default_uri, }
                    }
                    controller={true}
                    fullscreenCtlEnabled={true}
                    livevideo={true}
                    title={"Hyperbolic Livestream Demo"}
                    fullscreenCallback={(isFullscreen: boolean, layout: LayoutRectangle) => {
                        setPlayerLayout(layout);
                        setFullscreen(isFullscreen);
                    }}
                />
                <HylloText>----------</HylloText>
                <HylloText>Livestream</HylloText>
            </ScrollView>
        </ImageBackground>
    );
}

//<Video source={require('../assets/splash.png')}/>

export default VideoPlayer;
