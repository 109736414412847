import React, { ReactNode, useContext } from 'react';
import CardRow from '../Card/CardRow';
import ContextMenuContext from './ContextMenuContext';

const ContextMenuListItem = ({
  children,
  onPress,
  ...otherProps
}: {
  children: ReactNode;
  onPress?: () => void;
  style?: any;
}) => {
  const { onClose } = useContext(ContextMenuContext);
  const handlePress = () => {
    onPress && onPress();
    onClose();
  };
  return (
    <CardRow {...otherProps} onPress={handlePress}>
      {children}
    </CardRow>
  );
};

export default ContextMenuListItem;
