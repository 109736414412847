import React, { useEffect } from 'react';
import {
  Alert,
  ImageBackground,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import Feather from '@expo/vector-icons/Feather';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  textSizes,
  textWeights,
} from '../utils';
import HylloText from '../components/HylloText';
import wallet from '../utils/wallet';
import useAuthentication from '../hooks/useAuthentication';
import WordTile from '../components/WordTile';
import Button from '../components/Button';
import { Screens } from '../navigator/screens';
import { SafeAreaView } from 'react-native-safe-area-context';

const BackgroundImage = require('../assets/background-image-face.png');

const styles = StyleSheet.create({
  srpContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  srpTitle: {
    marginBottom: 24,
    width: '100%',
  },
  srpSubtitle: {
    marginBottom: 24,
    color: NeutralColor.Gray04,
  },
  srpMnemonic: {
    padding: 12,
    borderWidth: 1,
    borderColor: NeutralColor.Gray06,
    borderRadius: 10,
    height: 120,
  },
  srpBackButton: { marginTop: 12, marginLeft: 12 },
  srpBackContainer: {
    width: '100%',
    alignItems: 'flex-start',
  },
});

const SecretRecoveryPhrase = ({ navigation }: { navigation: any }) => {
  const mnemonic = wallet.getMnemonic();
  const { authenticate } = useAuthentication();

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <ImageBackground source={BackgroundImage} style={styles.srpContainer}>
      <SafeAreaView style={styles.srpBackContainer}>
        <Pressable
          onPress={() => navigation.goBack()}
          style={styles.srpBackButton}
        >
          <Feather
            name="chevron-left"
            size={30}
            color={PrimaryColor.PrimaryPurple}
          />
        </Pressable>
      </SafeAreaView>
      <View style={{ flex: 1, alignItems: 'center', padding: 24 }}>
        <HylloText size="h1" style={styles.srpTitle}>
          Secret Recovery Phrase
        </HylloText>
        <HylloText size="body" style={styles.srpSubtitle}>
          Please keep your secret recovery phrase safe. Writing it down and
          keeping the paper somewhere safe is a good option. Don&#8217;t show it
          to anyone.
        </HylloText>
        <View
          style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 20 }}
        >
          {mnemonic?.split(' ').map((word, index) => (
            <WordTile
              key={index}
              text={word}
              number={index + 1}
              style={{ margin: 6 }}
            />
          ))}
        </View>
        <Button
          variant="link"
          onPress={() => {
            if (mnemonic) {
              Alert.alert(
                'Are you sure you want to copy your seed phrase?',
                'Be careful! Other apps or websites may have access to your clipboard, so it is not 100% safe to store it digitally.',
                [
                  {
                    text: 'Cancel',
                    onPress: () => {},
                    style: 'cancel',
                  },
                  {
                    text: 'Copy it anyway',
                    onPress: () => Clipboard.setStringAsync(mnemonic),
                  },
                ],
                { cancelable: false }
              );
            }
          }}
        >
          Copy to clipboard
        </Button>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            marginBottom: 140,
            width: '100%',
          }}
        >
          <Button
            variant="primary"
            onPress={() => navigation.navigate(Screens.Settings)}
            fullWidth
            style={{ paddingVertical: 16 }}
            textStyle={{ ...textSizes.body, ...textWeights.bold }}
          >
            Done
          </Button>
        </View>
      </View>
    </ImageBackground>
  );
};

export default SecretRecoveryPhrase;
