import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

interface SettingsSlice {
  localAuthEnrolled: boolean;
  localAuthSet: boolean;
  lastAuth: number;
  selectChatsMode: boolean;
  selectedChats: Record<string, boolean>;
}

const initialState: SettingsSlice = {
  localAuthEnrolled: false,
  localAuthSet: false,
  lastAuth: 0,
  selectChatsMode: false,
  selectedChats: {},
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLocalAuthEnrolled: (state, action) => {
      state.localAuthEnrolled = action.payload;
      state.localAuthSet = true;
    },
    setLastAuth: (state, action) => {
      state.lastAuth = action.payload;
    },
    toggleSelectChatsMode: (state) => {
      state.selectChatsMode = !state.selectChatsMode;
      if (!state.selectChatsMode) {
        state.selectedChats = {};
      }
    },
    toggleSelectedChat: (state, action) => {
      const isSelected = state.selectedChats[action.payload];
      if (isSelected) {
        delete state.selectedChats[action.payload];
      } else {
        state.selectedChats[action.payload] = true;
      }
    },
  },
});

export const {
  setLocalAuthEnrolled,
  setLastAuth,
  toggleSelectChatsMode,
  toggleSelectedChat,
} = settingsSlice.actions;

export const getSettings = (state: RootState) => state.settings;

export const getLocalAuthEnrolled = (state: RootState) =>
  state.settings.localAuthEnrolled;
export const getSelectChatsMode = (state: RootState) =>
  state.settings.selectChatsMode;
export const getSelectedChats = (state: RootState) =>
  state.settings.selectedChats;

export default settingsSlice.reducer;
