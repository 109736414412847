import React, { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';
import { PrimaryColor, ShadeColor, textSizes, textWeights } from '../../utils';
import Button from '../Button';
import HylloText from '../HylloText';

const styles = StyleSheet.create({
  modalTitle: {
    color: ShadeColor.White,
    flex: 1,
    textAlign: 'center',
    ...textWeights.bold,
  },
  modalHeader: {
    flexDirection: 'row',
    padding: 20,
    alignItems: 'center',
  },
  modalButton: { position: 'absolute', left: 8, borderWidth: 0 },
  modalButtonText: { color: PrimaryColor.PrimaryPurple, fontWeight: '400' },
  buttonTextStyle: {
    width: 80,
    ...textSizes.body,
    ...textWeights.bold,
  },
});

const ModalHeader = ({
  onClose,
  title,
  titleStyle,
  rightButton,
}: {
  onClose: () => void;
  title: string;
  titleStyle?: any;
  rightButton?: ReactNode;
}) => (
  <View style={styles.modalHeader}>
    <Button variant="link" onPress={onClose} textStyle={styles.buttonTextStyle}>
      Cancel
    </Button>
    <HylloText style={[styles.modalTitle, titleStyle]}>{title}</HylloText>
    <View
      style={{ width: 80, flexDirection: 'row', justifyContent: 'flex-end' }}
    >
      {rightButton}
    </View>
  </View>
);

export default ModalHeader;
