export enum Screens {
  Wallet = 'Wallet',
  ChatList = 'Chat List',
  Chat = 'Chat',
  SearchChat = 'Search Chat',
  Contacts = 'Contacts',
  Settings = 'Settings',
  CreateUser = 'Create User',
  ImportSeed = 'Import From Seed',
  ImportWallet = 'Import Wallet',
  Welcome = 'Welcome',
  Passcode = 'Passcode',
  WalletSuccess = 'Wallet Success',
  CreateWallet = 'Create Wallet',
  SecretRecoveryPhrase = 'Secret Recovery Phrase',
  SecretRecoverySetup = 'Secret Recovery Setup',
  SecretRecoveryQuiz = 'Secret Recovery Quiz',
  FaceID = 'Face ID',
  PushNotifications = 'Push Notifications',
  PrivateChatsSettings = 'Private Chats',
  GroupChatsSettings = 'Group Chats',
  UnknownMessagesSettings = 'Unknown Messages',
  UserProfile = 'User Profile',
  SearchContacts = 'SearchContacts',
  VideoPlayer = 'Livestream'
}

export type TabScreenParamList = {
  [Screens.Wallet]: undefined;
  [Screens.ChatList]: undefined;
  [Screens.Contacts]: undefined;
  [Screens.Settings]: undefined;
};

export type StackParamList = {
  [Screens.Chat]: { chatId: string; address: string };
  [Screens.SearchChat]: undefined;
  [Screens.CreateUser]: undefined;
  TabScreens: TabScreenParamList;
  [Screens.ImportWallet]: undefined;
  [Screens.Passcode]: undefined;
  [Screens.ImportSeed]: undefined;
  [Screens.Welcome]: undefined;
  [Screens.WalletSuccess]: undefined;
  [Screens.CreateWallet]: undefined;
  [Screens.SecretRecoveryPhrase]: undefined;
  [Screens.SecretRecoverySetup]: undefined;
  [Screens.SecretRecoveryQuiz]: undefined;
  [Screens.PushNotifications]: undefined;
  [Screens.PrivateChatsSettings]: undefined;
  [Screens.GroupChatsSettings]: undefined;
  [Screens.UnknownMessagesSettings]: undefined;
  [Screens.UserProfile]: { address: string };
  [Screens.SearchChat]: undefined;
  [Screens.VideoPlayer]: undefined;
};
