import React, { useState } from 'react';
import { FlatList, SafeAreaView, StyleSheet, View } from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import { NeutralColor, ShadeColor, textSizes, textWeights } from '../utils';
import { Screens } from '../navigator/screens';
import ChatListItem from '../components/ChatList/ChatListItem';
import HylloText from '../components/HylloText';
import ListSeparator from '../components/ListSeparator';
import Input from '../components/Input';
import Button from '../components/Button';
import useSearch from '../hooks/useSearch';

const styles = StyleSheet.create({
  searchContainer: { backgroundColor: ShadeColor.Black, flex: 1 },
  headerRow: { flexDirection: 'row', padding: 16, alignItems: 'center' },
  inputContainer: { flex: 1, marginTop: 0, marginBottom: 0, marginRight: 8 },
  input: {
    paddingLeft: 36,
    paddingVertical: 7,
  },
  searchResultsContainer: {
    flex: 1,
    paddingVertical: 24,
  },
  noResultsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsTitle: { marginBottom: 20, color: NeutralColor.Gray06 },
  noResultsTextColor: { color: NeutralColor.Gray06 },
});

const SearchChat = ({ navigation }: { navigation: any }) => {
  const [searchInput, setSearchInput] = useState('');
  const { searchChats } = useSearch();
  const searchedChats = searchChats(searchInput);
  const noResults = !!searchInput && searchedChats.length === 0;
  return (
    <SafeAreaView style={styles.searchContainer}>
      <View style={styles.headerRow}>
        <Input
          variant="secondary"
          startNode={
            <Feather name="search" size={16} color={NeutralColor.Gray07} />
          }
          placeholder="Search Chats"
          placeholderTextColor={NeutralColor.Gray07}
          style={styles.input}
          containerStyle={styles.inputContainer}
          value={searchInput}
          onChangeText={setSearchInput}
          autoFocus
        />
        <Button
          variant="link"
          textStyle={{ ...textSizes.body, ...textWeights.semibold }}
          onPress={() => navigation.goBack()}
        >
          Cancel
        </Button>
      </View>
      <View style={styles.searchResultsContainer}>
        {noResults ? (
          <View style={styles.noResultsContainer}>
            <HylloText weight="bold" style={styles.noResultsTitle}>
              No Results
            </HylloText>
            <HylloText
              size="button"
              weight="regular"
              style={styles.noResultsTextColor}
            >
              There were no results for "{searchInput}".
            </HylloText>
            <HylloText
              size="button"
              weight="regular"
              style={styles.noResultsTextColor}
            >
              Try a new search.
            </HylloText>
          </View>
        ) : (
          <FlatList
            data={searchedChats}
            ItemSeparatorComponent={() => <ListSeparator marginLeft={86} />}
            keyExtractor={(item) =>
              `chat-search-${item.id}${
                item.type === 'message' ? `-${item.messageId}` : ''
              }`
            }
            renderItem={({ item }) => (
              <ChatListItem
                id={`chat-search-${item.id}${
                  item.type === 'message' ? `-${item.messageId}` : ''
                }`}
                address={item.address}
                time={item.time}
                displayMessage={item.displayMessage}
                onPress={() => {
                  navigation.navigate(Screens.Chat, {
                    chatId: item.id,
                    address: item.address,
                  });
                }}
              />
            )}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

export default SearchChat;
