import * as LocalAuthentication from 'expo-local-authentication';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../slices/modals';
import {
  getSettings,
  setLastAuth,
  setLocalAuthEnrolled,
} from '../slices/settings';

const FIVE_MINUTES = 1000 * 60 * 5;

const useAuthentication = () => {
  const dispatch = useDispatch();
  const { localAuthEnrolled, localAuthSet, lastAuth } =
    useSelector(getSettings);
  const [authError, setAuthError] = useState('');

  useEffect(() => {
    const checkLocalAuth = async () => {
      const hasHardware = await LocalAuthentication.hasHardwareAsync();
      if (hasHardware) {
        const isEnrolled = await LocalAuthentication.isEnrolledAsync();
        if (isEnrolled) {
          dispatch(setLocalAuthEnrolled(true));
        }
      }
    };
    if (!localAuthSet) {
      checkLocalAuth();
    }
  }, [localAuthSet]);

  const authenticate = async () => {
    if (Platform.OS === 'web') return true;
    const now = Date.now();
    const timeSinceLastAuth = now - lastAuth;
    const lastAuthWasRecent = timeSinceLastAuth < FIVE_MINUTES;

    if (lastAuthWasRecent) return true;
    dispatch(setLastAuth(Date.now()));
    if (localAuthEnrolled) {
      const result = await LocalAuthentication.authenticateAsync();
      if (result.success) {
        return true;
      } else {
        setAuthError(result.error);
        return false;
      }
    } else {
      dispatch(
        openModal({
          name: 'verifyPasscode',
          props: {
            disableOnRequestClose: true,
            presentationStyle: 'fullScreen',
          },
        })
      );
    }
  };

  return { localAuthEnrolled, authenticate, authError };
};

export default useAuthentication;
