import 'react-native-get-random-values';
import '@ethersproject/shims';
import './expo-crypto-shim';
import 'text-encoding-polyfill';

import React, { useCallback, useRef } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Provider } from 'react-redux';
import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from '@expo-google-fonts/roboto';
import {
  useFonts as usePoppinsFonts,
  Poppins_600SemiBold,
} from '@expo-google-fonts/poppins';
import * as SplashScreen from 'expo-splash-screen';

import store from './redux/store';
import BaseNavigator from './navigator/BaseNavigator';
import { ShadeColor } from './utils/colors';
import ModalPortal from './components/ModalPortal';
import useInit from './hooks/useInit';
import Web3Modal from './components/Web3Modal';
import { wagmiConfig } from './utils/constants';

SplashScreen.preventAutoHideAsync();

const FullStackWagmiConfig =
  Platform.OS === 'web'
    ? require('wagmi').WagmiConfig
    : ({ children }: any) => children;

export default function App() {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef: any = useRef();
  const { storageLoaded } = useInit();
  let [fontsLoaded] = useFonts({
    Roboto_100Thin,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    Roboto_900Black,
  });
  let [poppinsFontsLoaded] = usePoppinsFonts({
    Poppins_600SemiBold,
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded && poppinsFontsLoaded && storageLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded, poppinsFontsLoaded, storageLoaded]);

  if (!fontsLoaded || !poppinsFontsLoaded || !storageLoaded) {
    return null;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef?.getCurrentRoute()?.name;
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef?.getCurrentRoute()?.name;

        console.log('navigation:', previousRouteName, '->', currentRouteName);
        routeNameRef.current = currentRouteName;
      }}
    >
      <ActionSheetProvider>
        <FullStackWagmiConfig config={wagmiConfig}>
          <Provider store={store}>
            <TouchableWithoutFeedback
              onPress={() => {
                if (Platform.OS !== 'web') {
                  Keyboard.dismiss();
                }
              }}
              accessible={false}
            >
              <View style={styles.container} onLayout={onLayoutRootView}>
                <BaseNavigator />
                <StatusBar style="light" />
                <ModalPortal />
                <Web3Modal />
              </View>
            </TouchableWithoutFeedback>
          </Provider>
        </FullStackWagmiConfig>
      </ActionSheetProvider>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: ShadeColor.White,
    backgroundColor: ShadeColor.Black,
  },
});
