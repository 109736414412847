import { useCallback } from 'react';
import { hasUserId } from '../utils/user';
import { useSelector } from 'react-redux';
import { getUser } from '../slices/user';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  Screens,
  StackParamList,
  TabScreenParamList,
} from '../navigator/screens';
import { Platform } from 'react-native';
import { User } from '../utils';

const useOnboardingNavigate = () => {
  const reduxUser = useSelector(getUser);
  const navigation =
    useNavigation<
      NativeStackNavigationProp<StackParamList, Screens.CreateUser>
    >();
  const tabNavigation =
    useNavigation<
      NativeStackNavigationProp<TabScreenParamList, Screens.ChatList>
    >();

  const navigateToNextStep = useCallback(
    (overridingUser?: User) => {
      const user = overridingUser || reduxUser;
      if (!hasUserId(user) && !user.newUser) {
        navigation.navigate(Screens.CreateUser);
      } else if (Platform.OS !== 'web' && !user.hasPIN && !user.newUser) {
        navigation.navigate(Screens.Passcode);
      } else if (user.newUser) {
        navigation.navigate(Screens.WalletSuccess);
      } else {
        tabNavigation.navigate(Screens.ChatList);
      }
    },
    [reduxUser, navigation]
  );

  return navigateToNextStep;
};

export default useOnboardingNavigate;
