import React from 'react';
import {
  Image,
  ImageBackground,
  Pressable,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  textSizes,
  textWeights,
} from '../utils';
import HylloText from '../components/HylloText';
import Button from '../components/Button';
import { Screens } from '../navigator/screens';
import WalletConnectButton from '../components/WalletConnectButton';

const BackgroundImage = require('../assets/background-image.png');
const LogoLockImage = require('../assets/logo-lock.png');

const styles = StyleSheet.create({
  importWalletContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
    alignItems: 'center',
  },
  importWalletImage: {
    width: 240,
    height: 133,
  },
  importWalletTitle: {
    marginTop: 70,
    color: ShadeColor.White,
  },
  importWalletDescription: {
    marginTop: 24,
    color: NeutralColor.Gray04,
  },
  buttonContainer: {
    paddingHorizontal: 24,
    marginTop: 100,
    width: '100%',
    maxWidth: 520,
  },
  importWalletButton: {
    paddingVertical: 16,
  },
  importWalletButtonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
  importWalletBackButton: { marginLeft: 12, marginVertical: 12 },
  importWalletBackContainer: {
    marginHorizontal: 24,
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 134,
  },
});

const ImportWallet = ({ navigation }: { navigation: any }) => (
  <ImageBackground
    source={BackgroundImage}
    style={styles.importWalletContainer}
    resizeMode="cover"
  >
    <SafeAreaView style={styles.importWalletBackContainer}>
      <Pressable
        onPress={() => navigation.goBack()}
        style={styles.importWalletBackButton}
      >
        <Feather
          name="chevron-left"
          size={30}
          color={PrimaryColor.PrimaryPurple}
        />
      </Pressable>
    </SafeAreaView>
    <Image source={LogoLockImage} style={styles.importWalletImage} />
    <HylloText size="poppinsH1" style={styles.importWalletTitle}>
      Explore the app
    </HylloText>
    <HylloText style={styles.importWalletDescription}>
      With Hyllo, your data belongs to you
    </HylloText>
    <View style={styles.buttonContainer}>
      <Button
        variant="primary"
        onPress={() => navigation.navigate(Screens.ImportSeed)}
        style={styles.importWalletButton}
        textStyle={styles.importWalletButtonText}
        fullWidth
      >
        Import From Seed
      </Button>
      <WalletConnectButton />
    </View>
  </ImageBackground>
);

export default ImportWallet;
