import {
  Image,
  ImageBackground,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import { ShadeColor, textSizes, textWeights } from '../utils';
import HylloText from '../components/HylloText';
import Button from '../components/Button';
import { FaceIDIcon } from '../components/Icons';
import useAuthentication from '../hooks/useAuthentication';
import { Screens } from '../navigator/screens';

const LogoLock = require('../assets/logo-lock.png');
const BackgroundImage = require('../assets/background-image-face.png');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
    padding: 32,
    paddingTop: 130,
    alignItems: 'center',
  },
  srpImage: {
    width: 240,
    height: 133,
    marginBottom: 42,
  },
  srpTitle: {
    marginBottom: 20,
    width: '100%',
  },
  srpDesc1: {
    marginBottom: 16,
    width: '100%',
  },
  srpDesc2: {
    width: '100%',
  },
  srpButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 72,
    width: '100%',
  },
  srpButton: {
    paddingVertical: 16,
  },
  srpButtonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
});

const SecretRecoverySetup = ({ navigation }: { navigation: any }) => {
  const { authenticate } = useAuthentication();

  const handlePress = async () => {
    await authenticate();
    navigation.navigate(Screens.SecretRecoveryPhrase);
  };

  return (
    <ImageBackground
      source={BackgroundImage}
      style={styles.container}
      resizeMode="cover"
    >
      <Image source={LogoLock} style={styles.srpImage} />
      <HylloText size="h1" style={styles.srpTitle}>
        Prepare to write down your recovery phrase
      </HylloText>
      <HylloText style={styles.srpDesc1}>
        If you device gets lost or stolen, you can restore your wallet using
        your recovery phrase.
      </HylloText>
      <HylloText>
        Don&#8217;t store it digitally on any of your devices, write it down,
        and keep it offline.
      </HylloText>
      <View style={styles.srpButtonContainer}>
        <Button
          variant="primary"
          onPress={handlePress}
          style={styles.srpButton}
          textStyle={styles.srpButtonText}
          startIcon={<FaceIDIcon size={28} />}
          fullWidth
        >
          Start Now
        </Button>
      </View>
    </ImageBackground>
  );
};

export default SecretRecoverySetup;
