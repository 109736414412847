import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { createUser, fetchUser, getAddress } from '../slices/user';
import { Screens, StackParamList } from '../navigator/screens';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useWalletConnect from './useWalletConnect';
import useContactInfo from './useContactInfo';

const useWeb3Sync = () => {
  const dispatch = useDispatch<AppDispatch>();
  const address = useSelector(getAddress);
  const navigation =
    useNavigation<
      NativeStackNavigationProp<StackParamList, Screens.CreateUser>
    >();
  const { fetchContactInfo } = useContactInfo();
  const { address: currentAddress, isConnected } = useWalletConnect();

  useEffect(() => {
    const updateCurrentAddress = async () => {
      if (currentAddress) {
        const user = await dispatch(fetchUser(currentAddress)).unwrap();
        if (!user) {
          await dispatch(createUser({ address: currentAddress }));
          navigation.navigate(Screens.CreateUser);
        }
        fetchContactInfo(currentAddress);
      }
    };
    if (isConnected && address !== currentAddress) {
      updateCurrentAddress();
    }
  }, [currentAddress, isConnected]);
};

export default useWeb3Sync;
