import React from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';
import { ShadeColor } from '../utils/colors';
import { MessageList, MessageBar } from '../components/Chat';

const styles = StyleSheet.create({
  chatContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  chatTitleContainer: {
    padding: 20,
    borderBottomWidth: 1,
  },
  chatTitle: {
    fontSize: 20,
    fontWeight: '600',
  },
});

const Chat = () => {
  const headerHeight = useHeaderHeight();
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.chatContainer}
      keyboardVerticalOffset={headerHeight}
    >
      <MessageList />
      <MessageBar />
    </KeyboardAvoidingView>
  );
};

export default Chat;
