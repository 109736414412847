import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const QRScanIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}: any) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...otherProps}
  >
    <G fillRule="evenodd" clipRule="evenodd" fill={color}>
      <Path d="M7.759 2H8a1 1 0 110 2h-.2c-.857 0-1.439 0-1.889.038-.438.035-.663.1-.819.18a2 2 0 00-.874.874c-.08.156-.145.38-.18.819C4 6.361 4 6.943 4 7.8V8a1 1 0 11-2 0v-.241c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 011.748-1.748c.485-.247 1.002-.346 1.564-.392C6.29 2 6.954 2 7.758 2zM3 15a1 1 0 011 1v.2c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.819.18C6.361 20 6.943 20 7.8 20H8a1 1 0 110 2h-.241c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 01-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C2 17.71 2 17.046 2 16.242V16a1 1 0 011-1zM18.089 4.038C17.639 4 17.057 4 16.2 4H16a1 1 0 110-2H16.241c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 011.748 1.748c.247.485.346 1.002.392 1.564C22 6.29 22 6.954 22 7.758V8a1 1 0 11-2 0v-.2c0-.857 0-1.439-.038-1.889-.035-.438-.1-.663-.18-.819a2 2 0 00-.874-.874c-.156-.08-.38-.145-.819-.18zM21 15a1 1 0 011 1v.241c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 01-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H16a1 1 0 110-2h.2c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 00.874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V16a1 1 0 011-1zM2 12a1 1 0 011-1h.01a1 1 0 110 2H3a1 1 0 01-1-1zM6.5 12a1 1 0 011-1h.01a1 1 0 110 2H7.5a1 1 0 01-1-1zM15.5 12a1 1 0 011-1h.01a1 1 0 110 2h-.01a1 1 0 01-1-1zM11 12a1 1 0 011-1h.01a1 1 0 110 2H12a1 1 0 01-1-1zM20 12a1 1 0 011-1h.01a1 1 0 110 2H21a1 1 0 01-1-1z" />
    </G>
  </Svg>
);

export default QRScanIcon;
