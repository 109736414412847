import { getBlob } from '../utils/file';
import {
  getClient,
  getImageClient,
  putClient,
  putImageClient,
} from '../modules/client';
import { Platform } from 'react-native';

export const uploadImageDB = async ({
  address,
  imageUri,
}: {
  address: string;
  imageUri: string;
}) => {
  if (Platform.OS === 'web') {
    const blob = await getBlob(imageUri);
    const buffer = new Uint8Array(await blob.arrayBuffer());

    await putClient(`users/${address}/profilePhoto`, buffer, true);

    return getDownloadURLFromBlob(blob);
  }

  await putImageClient(`users/${address}/profilePhoto`, imageUri);

  return imageUri;
};

export const getDownloadURLFromBlob = async (blob: Blob): Promise<string> => {
  const url = URL.createObjectURL(blob);
  return url;
};

export const getDownloadURLForAddressDB = async (
  address: string
): Promise<string> => {
  if (Platform.OS === 'web') {
    const buffer = await getClient(`users/${address}/profilePhoto`, true);
    if (!buffer) return '';
    const blob = new Blob([buffer], { type: 'image/jpeg' });
    return getDownloadURLFromBlob(blob);
  }
  const response = await getImageClient(`users/${address}/profilePhoto`);
  if (!response) return '';
  return response;
};
