import React, { ReactNode } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { NeutralColor, ShadeColor } from '../../utils';
import ProfilePhoto from '../ProfilePhoto';
import HylloText from '../HylloText';
import { Screens, StackParamList } from '../../navigator/screens';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useContactInfo from '../../hooks/useContactInfo';
import { displayNickname, displayUserId } from '../../utils/user';

export interface ContactsListItemProps {
  address: string;
  onPress?: (address: string) => void;
  condensed?: boolean;
  rightButton?: ReactNode;
  nickname?: string;
  userId?: string;
}

const styles = StyleSheet.create({
  contactsListItemContainer: {
    flexDirection: 'row',
    padding: 16,
  },
  profilePhoto: {
    marginRight: 16,
  },
  contactsNameContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  contactName: {
    color: ShadeColor.White,
  },
  contactAddress: {
    color: NeutralColor.Gray04,
  },
  contactsListItemContainerCondensed: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});

const ContactsListItem = ({
  address,
  onPress = () => void 0,
  condensed = false,
  rightButton,
  nickname,
  userId,
}: ContactsListItemProps) => {
  const { findContactWithInfo } = useContactInfo();
  const contactInfo = findContactWithInfo(address);
  const navigation =
    useNavigation<
      NativeStackNavigationProp<StackParamList, Screens.UserProfile>
    >();
  return (
    <Pressable
      style={[
        styles.contactsListItemContainer,
        condensed && styles.contactsListItemContainerCondensed,
      ]}
      onPress={() => onPress(address)}
    >
      <Pressable
        onPress={() => navigation.navigate(Screens.UserProfile, { address })}
      >
        <ProfilePhoto
          address={address}
          size={condensed ? 36 : 54}
          style={styles.profilePhoto}
          initial={contactInfo?.name?.[0]}
        />
      </Pressable>
      <View
        style={[
          styles.contactsNameContainer,
          condensed && { justifyContent: 'center' },
        ]}
      >
        <HylloText weight="bold" style={styles.contactName}>
          {nickname || displayNickname(contactInfo)}
        </HylloText>
        {!condensed && (
          <HylloText size="caption" style={styles.contactAddress}>
            {userId || displayUserId(contactInfo)}
          </HylloText>
        )}
      </View>
      {rightButton}
    </Pressable>
  );
};

export default ContactsListItem;
