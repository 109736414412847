import React, { useCallback } from 'react';
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import { Pressable, SafeAreaView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Feather from '@expo/vector-icons/Feather';
import Ionicons from '@expo/vector-icons/Ionicons';
import * as Clipboard from 'expo-clipboard';
import { Screens, StackParamList } from '../navigator/screens';
import {
  fetchCurrentContactInfo,
  getContacts,
  getCurrentContactInfo,
  setContact,
} from '../slices/contacts';
import { AppDispatch } from '../redux/store';
import HylloText from '../components/HylloText';
import { PrimaryColor, ShadeColor, displayAddress } from '../utils';
import IconButton from '../components/UserProfile/IconButton';
import { Card, CardHeader, CardRow } from '../components/Card';
import ListSeparator from '../components/ListSeparator';
import useOpenChat from '../hooks/useOpenChat';
import { openModal } from '../slices/modals';
import { getAddress } from '../slices/user';
import ProfileInfo from '../components/UserProfile/ProfileInfo';
import { displayNickname, displayUserId } from '../utils/user';
import { BellOffIcon, PhoneCallIcon, VideoIcon } from '../components/Icons';

const styles = StyleSheet.create({
  userProfileInfo: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
    alignItems: 'center',
  },
  bannerContainer: {
    width: '100%',
    backgroundColor: PrimaryColor.Purple02,
    height: 166,
    padding: 12,
  },
  bannerButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profilePhotoContainer: {
    marginTop: -50,
  },
  contentContainer: { padding: 16 },
  actionButtonsContainer: {
    marginHorizontal: -8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  infoCard: { margin: 16 },
  infoCardText: { flex: 1, marginLeft: 8 },
  infoCardValue: { color: PrimaryColor.Purple03 },
  infoCardValueContainer: { flexDirection: 'row', alignItems: 'center' },
  copyIcon: { marginLeft: 6 },
});

const UserProfile = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const route = useRoute<RouteProp<StackParamList, Screens.UserProfile>>();
  const currentUserAddress = route.params?.address;
  const address = useSelector(getAddress);
  const contactInfo = useSelector(getCurrentContactInfo);
  const contacts = useSelector(getContacts);
  const handleOpenChat = useOpenChat();
  const isContact = contacts?.[currentUserAddress];

  useFocusEffect(
    useCallback(() => {
      dispatch(fetchCurrentContactInfo(currentUserAddress));
    }, [currentUserAddress])
  );

  return (
    <View style={styles.userProfileInfo}>
      <View style={styles.bannerContainer}>
        <SafeAreaView style={styles.bannerButtonsContainer}>
          <Pressable onPress={() => navigation.goBack()}>
            <Feather name="chevron-left" size={30} color={ShadeColor.White} />
          </Pressable>
          {isContact && (
            <Pressable
              onPress={() =>
                dispatch(
                  openModal({
                    name: 'contact',
                    props: { address: contactInfo?.address },
                  })
                )
              }
            >
              <HylloText>Edit</HylloText>
            </Pressable>
          )}
        </SafeAreaView>
      </View>
      <ProfileInfo
        address={currentUserAddress}
        name={displayNickname(contactInfo, displayAddress(currentUserAddress))}
        userId={displayUserId(contactInfo, displayAddress(currentUserAddress))}
        photoStyle={styles.profilePhotoContainer}
      />
      <View style={styles.actionButtonsContainer}>
        {!isContact && (
          <IconButton
            icon={
              <Feather
                name="user-plus"
                size={24}
                color={PrimaryColor.PrimaryPurple}
              />
            }
            label="add"
            onPress={() =>
              dispatch(
                setContact({ address, data: { address: contactInfo?.address } })
              )
            }
          />
        )}
        <IconButton
          icon={
            <Ionicons
              name="chatbox-ellipses"
              size={24}
              color={PrimaryColor.PrimaryPurple}
            />
          }
          label="chat"
          onPress={() => handleOpenChat(contactInfo?.address)}
        />
        <IconButton
          icon={<PhoneCallIcon size={24} color={PrimaryColor.PrimaryPurple} />}
          label="call"
          onPress={() => {}}
        />
        <IconButton
          icon={<VideoIcon size={24} color={PrimaryColor.PrimaryPurple} />}
          label="video"
          onPress={() => {}}
        />
        <IconButton
          icon={<BellOffIcon size={24} color={PrimaryColor.PrimaryPurple} />}
          label="mute"
          onPress={() => {}}
        />
        <IconButton
          icon={
            <Feather
              name="more-horizontal"
              size={24}
              color={PrimaryColor.PrimaryPurple}
            />
          }
          label="more"
          onPress={() => {}}
        />
      </View>
      <View style={{ width: '100%' }}>
        <Card style={styles.infoCard}>
          <CardHeader title="Information" />
          <CardRow>
            <Feather name="user" size={20} color={ShadeColor.White} />
            <HylloText style={styles.infoCardText}>User ID</HylloText>
            <HylloText style={styles.infoCardValue}>
              {displayUserId(
                contactInfo,
                displayAddress(currentUserAddress),
                false
              )}
            </HylloText>
          </CardRow>
          <ListSeparator marginLeft={44} />
          <CardRow>
            <Ionicons
              name="wallet-outline"
              size={20}
              color={ShadeColor.White}
            />
            <HylloText style={styles.infoCardText}>Wallet Address</HylloText>
            <Pressable
              style={styles.infoCardValueContainer}
              onPress={() =>
                Clipboard.setStringAsync(contactInfo?.address || '')
              }
            >
              <HylloText style={styles.infoCardValue}>
                {displayAddress(currentUserAddress)}
              </HylloText>
              <Feather
                name="copy"
                size={18}
                color={PrimaryColor.Purple03}
                style={styles.copyIcon}
              />
            </Pressable>
          </CardRow>
        </Card>
      </View>
    </View>
  );
};

export default UserProfile;
