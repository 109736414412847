import { ReactNode } from 'react';
import { Pressable, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  cardRow: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    height: 42,
  },
});

const CardRow = ({
  children,
  onPress,
  style,
}: {
  children: ReactNode;
  onPress?: () => void;
  style?: any;
}) => (
  <Pressable onPress={onPress} style={[styles.cardRow, style]}>
    {children}
  </Pressable>
);

export default CardRow;
