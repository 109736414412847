import React, { useCallback, useEffect, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';
import { isAddress } from 'ethers/lib/utils';
import { getAddress } from '../../slices/user';
import { AppDispatch } from '../../redux/store';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  WarningColor,
} from '../../utils/colors';
import { getContacts, setContact } from '../../slices/contacts';
import ModalHeader from './ModalHeader';
import useOpenChat from '../../hooks/useOpenChat';
import { fetchUserDB, fetchUserIdDB } from '../../services/users';
import HylloText from '../HylloText';
import Input from '../Input';
import ContactsListItem from '../Contacts/ContactsListItem';
import Button from '../Button';
import { isENS } from '../../utils';
import { provider } from '../../utils/constants';
import { QRScanIcon } from '../Icons';
import { displayNickname, displayUserId } from '../../utils/user';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  modalBody: {
    flex: 1,
    padding: 16,
    paddingTop: 12,
  },
  modalError: {
    color: WarningColor.Red,
    marginTop: 12,
  },
  input: { paddingVertical: 11 },
});

const NewContactModal = ({
  initialAddress = '',
  initialInfo = {},
  onClose,
}: {
  initialAddress?: string;
  initialInfo?: any;
  onClose: () => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const contacts = useSelector(getContacts);
  const address = useSelector(getAddress);
  const [addressInput, setAddressInput] = useState(initialAddress || '');
  const [contactInfo, setContactInfo] = useState(initialInfo || {});
  const [error, setError] = useState('');
  const handleOpenChat = useOpenChat();
  const handleClose = () => {
    setAddressInput('');
    setContactInfo('');
    onClose();
  };
  const contactExists = !!contacts?.[contactInfo.address];
  const handleChangeAddress = useCallback(async (addressInput: string) => {
    setAddressInput(addressInput);
    if (isAddress(addressInput)) {
      const user = await fetchUserDB(addressInput);
      if (user) {
        setContactInfo(user);
      }
    } else if (isENS(addressInput)) {
      const contactAddress = await provider.resolveName(addressInput);
      const user = await fetchUserDB(contactAddress);
      if (user) {
        setContactInfo({ ...user, ens: addressInput });
      }
    } else {
      const contactAddress = await fetchUserIdDB(addressInput);
      const user = await fetchUserDB(contactAddress);
      if (user) {
        setContactInfo(user);
      }
    }
  }, []);
  const handleButtonPress = useCallback(async () => {
    if (contactExists) {
      setError('This contact already exists!');
    } else {
      await dispatch(
        setContact({ address, data: { address: contactInfo.address } })
      );
      await handleOpenChat(contactInfo.address);
    }
    setError('');
    handleClose();
  }, [address, contactInfo, setError, dispatch, contacts, handleOpenChat]);
  useEffect(() => {
    if (initialAddress) {
      handleChangeAddress(initialAddress);
    }
  }, [initialAddress]);
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== 'web') {
          Keyboard.dismiss();
        }
      }}
      accessible={false}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.modalContainer}
      >
        <ModalHeader
          onClose={handleClose}
          title="Add Contact"
          rightButton={
            <QRScanIcon
              size={24}
              style={{ color: PrimaryColor.PrimaryPurple }}
            />
          }
        />
        <View style={styles.modalBody}>
          <Input
            variant="secondary"
            value={addressInput}
            style={styles.input}
            onChangeText={handleChangeAddress}
            placeholder="User ID / Wallet Address"
            placeholderTextColor={NeutralColor.Gray07}
            keyboardAppearance="dark"
            autoCapitalize="none"
          />
          {Object.keys(contactInfo).length > 0 && (
            <ContactsListItem
              address={contactInfo.address}
              nickname={displayNickname(contactInfo)}
              userId={displayUserId(contactInfo)}
              rightButton={
                <Button
                  variant="link"
                  onPress={handleButtonPress}
                  disabled={contactExists}
                >
                  Add{contactExists && 'ed'}
                </Button>
              }
            />
          )}
          {!!error && <HylloText style={styles.modalError}>{error}</HylloText>}
        </View>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};

export default NewContactModal;
