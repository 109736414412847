import React, { useCallback, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import { TabView } from 'react-native-tab-view';
import { useDispatch, useSelector } from 'react-redux';
import Feather from '@expo/vector-icons/Feather';
import { fetchChats, getChats } from '../slices/chats';
import { AppDispatch } from '../redux/store';
import { getAddress } from '../slices/user';
import { Screens } from '../navigator/screens';
import { NeutralColor, ShadeColor } from '../utils/colors';
import Button from '../components/Button';
import { openModal } from '../slices/modals';
import HylloText from '../components/HylloText';
import { useFocusEffect } from '@react-navigation/native';
import { getSelectChatsMode } from '../slices/settings';
import SelectChatsModeBanner from '../components/SelectChatsModeBanner';
import { headerStyles } from '../utils/navigation';
import Tab from '../components/Tabs/Tab';
import TabBar from '../components/Tabs/TabBar';
import FilteredChatList from '../components/ChatList/FilteredChatList';
import { fetchChatsByUserDB } from '../services/chat';

const styles = StyleSheet.create({
  chatListContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: ShadeColor.Black,
  },
  emptyChatList: {
    backgroundColor: ShadeColor.Black,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyChatTitle: {
    color: ShadeColor.White,
    marginBottom: 12,
  },
  emptyChatText: {
    color: NeutralColor.Gray04,
    marginBottom: 24,
  },
  chatListHeader: { paddingHorizontal: 16, marginVertical: 16 },
});

const routes = [
  { key: 'all', title: 'All Chats' },
  { key: 'unread', title: 'Unread' },
  { key: 'strangers', title: 'Strangers' },
];

const ChatList = ({ navigation }: { navigation: any }) => {
  const layout = useWindowDimensions();

  const [index, setIndex] = useState(0);
  const address = useSelector(getAddress);
  const dispatch: AppDispatch = useDispatch();
  const chats = useSelector(getChats);
  const selectChatsMode = useSelector(getSelectChatsMode);

  useFocusEffect(
    useCallback(() => {
      if (address) {
        dispatch(fetchChats(address));
      }
    }, [address])
  );

  useFocusEffect(
    useCallback(() => {
      if (selectChatsMode) {
        navigation.setOptions({ tabBarStyle: { display: 'none' } });
      } else {
        navigation.setOptions({
          tabBarStyle: headerStyles.tabBarStyle,
        });
      }
    }, [selectChatsMode])
  );

  if (!address) {
    return (
      <View style={styles.emptyChatList}>
        <HylloText size="h2" style={styles.emptyChatTitle}>
          Not connected yet?
        </HylloText>
        <HylloText size="caption" style={styles.emptyChatText}>
          Add your wallet address to send chats.
        </HylloText>
        <Button
          variant="primary"
          onPress={() => navigation.navigate(Screens.Wallet)}
        >
          Add your address
        </Button>
      </View>
    );
  }

  if (chats.length === 0) {
    return (
      <View style={styles.emptyChatList}>
        <HylloText size="h2" style={styles.emptyChatTitle}>
          No chats yet!
        </HylloText>
        <HylloText size="caption" style={styles.emptyChatText}>
          Say hyllo to some friends!
        </HylloText>
        <Button
          variant="primary"
          onPress={() => dispatch(openModal({ name: 'newChat' }))}
        >
          Send a message
        </Button>
      </View>
    );
  }

  const renderList = (props: any) => {
    const routeKey = props.route.key;
    return <FilteredChatList filterKey={routeKey} />;
  };

  const renderTabBar = (props: any) => (
    <TabBar>
      {props.navigationState.routes.map((route: any, i: number) => (
        <Tab
          key={`chat-list-tab-${i}`}
          isActive={props.navigationState.index === i}
          onPress={() => setIndex(i)}
        >
          {route.title}
        </Tab>
      ))}
    </TabBar>
  );

  return (
    <View style={styles.chatListContainer}>
      <SafeAreaView>
        <View style={styles.chatListHeader}>
          <Button
            variant="input"
            onPress={() => navigation.navigate(Screens.SearchChat)}
            style={{ paddingVertical: 7 }}
            startIcon={
              <Feather name="search" size={16} color={NeutralColor.Gray07} />
            }
            fullWidth
          >
            Search Chats
          </Button>
        </View>
      </SafeAreaView>
      <TabView
        navigationState={{ index, routes }}
        renderTabBar={renderTabBar}
        renderScene={renderList}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
      />
      <SelectChatsModeBanner />
    </View>
  );
};

export default ChatList;
