export const encodeData = (str: string) => {
  let encoder = new TextEncoder();
  let uint8Array = encoder.encode(str);
  return uint8Array;
};

export const decodeData = (uint8Array: Uint8Array) => {
  let decoder = new TextDecoder('utf-8');
  let str = decoder.decode(uint8Array);
  return str;
};

// export const encodeData = (value: any): Uint8Array => {
//   if (typeof value === 'string') {
//     return encodeUtf8(value);
//   }
//   return encodeUtf8(JSON.stringify(value));
// };

// export const decodeData = (value: Uint8Array) => {
//   try {
//     return JSON.parse(decodeUtf8(value));
//   } catch {
//     return decodeUtf8(value);
//   }
// };
