import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  formatTime,
} from '../../utils';
import HylloText from '../HylloText';

const styles = StyleSheet.create({
  messageContainer: {
    marginVertical: 12,
    flexDirection: 'row-reverse',
    paddingHorizontal: 16,
  },
  themMessageContainer: {
    flexDirection: 'row',
  },
  messageBubble: {
    backgroundColor: PrimaryColor.PrimaryPurple,
    borderRadius: 24,
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    maxWidth: '75%',
  },
  themMessageBubble: {
    backgroundColor: NeutralColor.Gray10,
  },
  messageTimeText: {
    color: NeutralColor.Gray04,
    minWidth: 50,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
    marginLeft: 8,
  },
  messageText: {
    color: ShadeColor.White,
    flexGrow: 1,
    maxWidth: Platform.OS === 'ios' ? '75%' : '100%',
  },
});

const MessageBubble = ({
  message,
  created,
  fromCurrentAddress,
}: {
  message: string;
  created: number;
  fromCurrentAddress: boolean;
}) => (
  <View
    style={[
      styles.messageContainer,
      !fromCurrentAddress && styles.themMessageContainer,
    ]}
  >
    <View
      style={[
        styles.messageBubble,
        !fromCurrentAddress && styles.themMessageBubble,
      ]}
    >
      <HylloText size="button" weight="medium" style={styles.messageText}>
        {message}
      </HylloText>
      <HylloText size="caption" weight="medium" style={styles.messageTimeText}>
        {formatTime(created)}
      </HylloText>
    </View>
  </View>
);

export default MessageBubble;
