import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { NeutralColor } from '../../utils';

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: NeutralColor.Gray10,
    borderRadius: 10,
  },
});

const Card = ({ children, style }: { children: ReactNode; style?: any }) => (
  <View style={[styles.cardContainer, style]}>{children}</View>
);

export default Card;
