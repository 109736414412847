import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import HylloText from '../components/HylloText';
import { NeutralColor, PrimaryColor, ShadeColor, textWeights } from '../utils';
import Feather from '@expo/vector-icons/Feather';
import { Screens } from '../navigator/screens';
import { Card, CardRow } from '../components/Card';
import ListSeparator from '../components/ListSeparator';
import HylloSwitch from '../components/HylloSwitch';

const styles = StyleSheet.create({
  notificationsContainer: {
    padding: 16,
    backgroundColor: ShadeColor.Black,
    flex: 1,
  },
  notificationsLabel: {
    ...textWeights.semibold,
    color: NeutralColor.Gray06,
    marginVertical: 8,
    marginHorizontal: 16,
  },
});

const PushNotifications = ({ navigation }: { navigation: any }) => {
  const [inAppSounds, setInAppSounds] = useState(false);
  const [inAppVibrate, setInAppVibrate] = useState(false);
  const [inAppPreview, setInAppPreview] = useState(false);

  return (
    <View style={styles.notificationsContainer}>
      <HylloText style={styles.notificationsLabel}>
        Message Notifications
      </HylloText>
      <Card>
        <CardRow
          onPress={() => navigation.navigate(Screens.PrivateChatsSettings)}
        >
          <Feather
            name="user"
            size={24}
            color={PrimaryColor.PrimaryPurple}
            style={{ marginRight: 24 }}
          />
          <HylloText style={{ flex: 1 }}>Private Chats</HylloText>
          <Feather name="chevron-right" size={24} color={NeutralColor.Gray07} />
        </CardRow>
        <ListSeparator marginLeft={64} />
        <CardRow
          onPress={() => navigation.navigate(Screens.GroupChatsSettings)}
        >
          <Feather
            name="users"
            size={24}
            color={PrimaryColor.PrimaryPurple}
            style={{ marginRight: 24 }}
          />
          <HylloText style={{ flex: 1 }}>Group Chats</HylloText>
          <Feather name="chevron-right" size={24} color={NeutralColor.Gray07} />
        </CardRow>
        <ListSeparator marginLeft={64} />
        <CardRow
          onPress={() => navigation.navigate(Screens.UnknownMessagesSettings)}
        >
          <Feather
            name="user-plus"
            size={24}
            color={PrimaryColor.PrimaryPurple}
            style={{ marginRight: 24 }}
          />
          <HylloText style={{ flex: 1 }}>Unknown Messages</HylloText>
          <Feather name="chevron-right" size={24} color={NeutralColor.Gray07} />
        </CardRow>
      </Card>

      <HylloText style={styles.notificationsLabel}>
        In-App Notifications
      </HylloText>
      <Card>
        <CardRow
          style={{ justifyContent: 'space-between', paddingVertical: 10 }}
        >
          <HylloText>In-App Sounds</HylloText>
          <HylloSwitch
            onValueChange={() => setInAppSounds(!inAppSounds)}
            value={inAppSounds}
          />
        </CardRow>
        <ListSeparator marginLeft={16} />
        <CardRow style={{ justifyContent: 'space-between' }}>
          <HylloText>In-App Vibrate</HylloText>
          <HylloSwitch
            onValueChange={() => setInAppVibrate(!inAppVibrate)}
            value={inAppVibrate}
          />
        </CardRow>
        <ListSeparator marginLeft={16} />
        <CardRow style={{ justifyContent: 'space-between' }}>
          <HylloText>In-App Preview</HylloText>
          <HylloSwitch
            onValueChange={() => setInAppPreview(!inAppPreview)}
            value={inAppPreview}
          />
        </CardRow>
      </Card>
    </View>
  );
};

export default PushNotifications;
