import React, { useRef, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  View,
  Platform,
  LayoutRectangle,
} from 'react-native';

import {
  ResizeMode,
  AVPlaybackStatus,
  AVPlaybackStatusSuccess,
  VideoFullscreenUpdateEvent,
  VideoFullscreenUpdate,
} from 'expo-av';
import { setStatusBarHidden } from 'expo-status-bar';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as NavigationBar from 'expo-navigation-bar';
import VideoPlayer from './lib/index';
import HylloText from '../HylloText';
import { ShadeColor } from '../../utils';

const styles = StyleSheet.create({
  playerTitle: {
    color: ShadeColor.White,
    marginTop: 10,
    marginLeft: 15,
  },
});

interface VideoProps {
  source: any;
  keyframe?: any;
  livevideo?: boolean;
  title?: string;
  style?: any;
  autoplay?: boolean;
  muted?: boolean;
  controller?: boolean;
  fullscreenCtlEnabled?: boolean;
  fullscreenCallback?: (
    isFullscreen: boolean,
    viewLayout: LayoutRectangle
  ) => void;
}

let inFullscreen: boolean = false;

const Video = ({
  source,
  keyframe,
  livevideo = false,
  title = '',
  style = {},
  autoplay = true,
  muted = false,
  controller = true,
  fullscreenCtlEnabled = true,
  fullscreenCallback,
}: VideoProps) => {
  const refVideo = useRef<any>(null);
  const [viewLayout, setViewLayout] = useState<LayoutRectangle>();
  const [inFullscreen, setInFullsreen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  let actualHeight = 300;
  let actualWidth = 480;
  let origHeight = style.height;
  let origWidth = style.width;
  if (typeof (style.width) === "string") {
    actualWidth = parseFloat(style.width) / 100 * Dimensions.get('screen').width;
    if (style.maxWidth) {
      actualWidth = Math.min(style.maxWidth, actualWidth);
    }
  } else if (typeof (style.width) === "number") {
    actualWidth = style.width;
  } else {
    console.log(
      '[VideoPlayer]Check style.width passed in. Has to be either (width in number) or (maxWidth + width in percerntage string)'
    );
    return <div>{false}</div>;
  }

  if (typeof (style.height) === "string") {
    actualHeight = parseFloat(style.height) / 100 * Dimensions.get('screen').height;
    if (style.maxHeight) {
      actualHeight = Math.min(style.maxHeight, actualHeight);
    }
  } else if (typeof (style.height) === "number") {
    actualHeight = style.height;
  } else {
    console.log(
      '[VideoPlayer]Check style.height passed in. Has to be either (height in number) or (maxHeight + height in percerntage string)'
    );
    return <div>{false}</div>;
  }

  return (
    <View
      onLayout={({ nativeEvent }) => {
        setViewLayout(nativeEvent.layout);
      }}
      style={[
        style,
        {
          width: inFullscreen ? Dimensions.get('screen').width : origWidth,
          height: inFullscreen ? Dimensions.get('screen').height : origHeight,
        },
      ]}
    >
      <VideoPlayer
        videoProps={{
          shouldPlay: autoplay,
          resizeMode: ResizeMode.CONTAIN,
          source: source,
          isMuted: isMuted,
          videoStyle: {
            height: '100%',
            width: '100%',
          },
          ...(keyframe && {
            posterSource: keyframe,
            usePoster: true,
          }),
          onLoad: (status: AVPlaybackStatus) => {
            // Check if duration is NULL, then disable the duration shown around slider
            if (
              (status as AVPlaybackStatusSuccess).isLoaded &&
              !(status as AVPlaybackStatusSuccess).durationMillis
            ) {
              //setIsSliderDurationShown(false);
              // TODO: need to expose the binding in order to update the slider(componment modification needed)
            }
          },
          ref: refVideo,
        }}
        mute={{
          visible: true,
          enterMute: () => setIsMuted(!isMuted),
          exitMute: () => setIsMuted(!isMuted),
          isMute: !isMuted,
        }}
        style={{
          height: inFullscreen ? Dimensions.get('screen').height : actualHeight,
          width: inFullscreen ? Dimensions.get('screen').width : actualWidth,
          videoBackgroundColor: '#000000',
        }}
        liveVideo={livevideo}
        slider={{
          visible: controller,
        }}
        timeVisible={controller}
        {...(!controller
          ? { fullscreen: undefined }
          : {
              visible: fullscreenCtlEnabled,
              enterFullscreen: async () => {
                if (Platform.OS == 'android') {
                  await NavigationBar.setVisibilityAsync('hidden');
                }

                if (fullscreenCallback) {
                  fullscreenCallback(true, viewLayout as LayoutRectangle);
                }
                setStatusBarHidden(true, 'fade');
                setInFullsreen(!inFullscreen);
                if (Platform.OS != 'web') {
                  await ScreenOrientation.lockAsync(
                    ScreenOrientation.OrientationLock.LANDSCAPE
                  );
                } else {
                  refVideo.current?.presentFullscreenPlayer();
                }
              },
              exitFullscreen: async () => {
                if (Platform.OS == 'android') {
                  await NavigationBar.setVisibilityAsync('visible');
                }

                if (fullscreenCallback) {
                  fullscreenCallback(false, viewLayout as LayoutRectangle);
                }
                setStatusBarHidden(false, 'fade');
                setInFullsreen(!inFullscreen);
                if (Platform.OS != 'web') {
                  await ScreenOrientation.lockAsync(
                    ScreenOrientation.OrientationLock.PORTRAIT_UP
                  );
                } else {
                  refVideo.current?.dismissFullscreenPlayer();
                }
              },
              updateFullscreenUpdate: (event: VideoFullscreenUpdateEvent) => {
                if (
                  event.fullscreenUpdate ==
                  VideoFullscreenUpdate.PLAYER_DID_DISMISS
                ) {
                  setStatusBarHidden(false, 'fade');
                  setInFullsreen(!inFullscreen);
                }
              },
              inFullscreen: inFullscreen,
            })}
        {...(title != '' && {
          header: <HylloText style={styles.playerTitle}>{title}</HylloText>,
        })}
      />
    </View>
  );
};

export default Video;
