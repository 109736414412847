import Button from './Button';
import { Image, StyleSheet } from 'react-native';
import { textSizes, textWeights } from '../utils';
import useWalletConnect from '../hooks/useWalletConnect';

const WalletConnectImage = require('../assets/wallet-connect.png');

const styles = StyleSheet.create({
  importWalletButton: {
    paddingVertical: 16,
  },
  importWalletButtonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
});

const WalletConnectButton = () => {
  const { open } = useWalletConnect();
  return (
    <Button
      variant="secondary"
      onPress={open}
      style={[styles.importWalletButton, { marginTop: 16 }]}
      textStyle={styles.importWalletButtonText}
      startIcon={
        <Image source={WalletConnectImage} style={{ height: 24, width: 24 }} />
      }
      fullWidth
    >
      Continue with WalletConnect
    </Button>
  );
};

export default WalletConnectButton;
