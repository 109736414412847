import { StyleSheet, View } from 'react-native';
import HylloText from './HylloText';
import { NeutralColor, ShadeColor, textWeights } from '../utils';

const styles = StyleSheet.create({
  wordTileContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 5,
    backgroundColor: ShadeColor.White,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
  },
  wordTileNumber: {
    color: NeutralColor.Gray06,
    ...textWeights.regular,
    marginRight: 6,
  },
  wordTileText: {
    color: ShadeColor.Black,
    ...textWeights.regular,
  },
});

const WordTile = ({
  style,
  text,
  number,
}: {
  style?: any;
  text: string;
  number: number;
}) => {
  return (
    <View style={[styles.wordTileContainer, style]}>
      <HylloText size="button" style={styles.wordTileNumber}>
        {number}
      </HylloText>
      <HylloText size="button" style={styles.wordTileText}>{text}</HylloText>
    </View>
  );
};

export default WordTile;
