import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import HylloText, { FontSize } from './HylloText';
import BlinkingCursor from './BlinkingCursor';

const AnimatedTyping = ({
  text,
  size = 'body',
  onFinish,
  showCursor = true,
  style,
  delayStart = 0,
  textStyle,
}: {
  text: string;
  size?: FontSize;
  onFinish?: () => void;
  showCursor?: boolean;
  style?: any;
  delayStart?: number;
  textStyle?: any;
}) => {
  const linedText = text.split('\\n');
  const [displayedText, setDisplayedText] = useState<string[]>(
    Array.from({ length: linedText.length }, () => '')
  );
  const [currentLine, setCurrentLine] = useState(0);

  useEffect(() => {
    if (displayedText[currentLine].length < linedText[currentLine].length) {
      setTimeout(
        () => {
          setDisplayedText(
            displayedText.map((t, i) =>
              i === currentLine ? linedText[i].slice(0, t.length + 1) : t
            )
          );
        },
        currentLine === 0 && displayedText[0].length === 0 ? delayStart : 30
      );
    } else if (currentLine < linedText.length - 1) {
      setTimeout(() => {
        setCurrentLine((prevState) => prevState + 1);
        setDisplayedText(
          displayedText.map((t, i) =>
            i === currentLine + 1 ? linedText[i].slice(0, 1) : t
          )
        );
      }, 30);
    } else if (
      currentLine === linedText.length - 1 &&
      displayedText[currentLine].length === linedText[currentLine].length
    ) {
      onFinish && onFinish();
    }
  }, [displayedText, text]);

  const stillTyping =
    displayedText[currentLine].length > 0 &&
    displayedText[currentLine].length !== linedText[currentLine].length;

  return (
    <View style={style}>
      {displayedText.map((t, i) => (
        <HylloText
          key={i}
          size={size}
          style={[{ flexDirection: 'row', flexWrap: 'wrap' }, textStyle]}
        >
          {t}
          {currentLine === i && showCursor && (
            <BlinkingCursor size={size} forceShow={stillTyping} />
          )}
        </HylloText>
      ))}
    </View>
  );
};

export default AnimatedTyping;
