import React, { ReactNode } from 'react';
import Card from '../Card/Card';
import { StyleSheet } from 'react-native';
import { NeutralColor } from '../../utils';

const styles = StyleSheet.create({
  contextMenuList: {
    width: '100%',
    backgroundColor: NeutralColor.Gray09,
  },
});

const ContextMenuList = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: any;
}) => <Card style={[styles.contextMenuList, style]}>{children}</Card>;

export default ContextMenuList;
