import React, { ReactNode } from 'react';
import { StatusBar, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  tabBar: {
    flexDirection: 'row',
    paddingTop: StatusBar.currentHeight,
  },
});

const TabBar = ({ children, style }: { children: ReactNode; style?: any }) => (
  <View style={[styles.tabBar, style]}>{children}</View>
);

export default TabBar;
