import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Feather from '@expo/vector-icons/Feather';
import Ionicons from '@expo/vector-icons/Ionicons';
import { getAddress } from '../slices/user';
import Chat from '../screens/Chat';
import ChatList from '../screens/ChatList';
import Wallet from '../screens/Wallet';
import { Screens } from './screens';
import { AppDispatch } from '../redux/store';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  WarningColor,
} from '../utils/colors';
import { textSizes } from '../utils/text';
import SearchChat from '../screens/SearchChat';
import Contacts from '../screens/Contacts';
import ChatHeader from '../components/Chat/ChatHeader';
import { openModal } from '../slices/modals';
import Settings from '../screens/Settings';
import useWeb3Sync from '../hooks/useWeb3Sync';
import CreateUser from '../screens/CreateUser';
import ImportSeed from '../screens/ImportSeed';
import Passcode from '../screens/Passcode';
import ImportWallet from '../screens/ImportWallet';
import Welcome from '../screens/Welcome';
import WalletSuccess from '../screens/WalletSuccess';
import CreateWallet from '../screens/CreateWallet';
import SecretRecoveryPhrase from '../screens/SecretRecoveryPhrase';
import useFinishOnboarding from '../hooks/useFinishOnboarding';
import FaceID from '../screens/FaceID';
import SecretRecoverySetup from '../screens/SecretRecoverySetup';
import PushNotifications from '../screens/PushNotifications';
import PrivateChatsSettings from '../screens/PrivateChatsSettings';
import GroupChatsSettings from '../screens/GroupChatsSettings';
import UnknownMessagesSettings from '../screens/UnknownMessagesSettings';
import UserProfile from '../screens/UserProfile';
import SearchContacts from '../screens/SearchContacts';
import VideoPlayer from '../screens/VideoPlayer';
import { ChatIcon, PinIcon } from '../components/Icons';
import { getTotalUnreadCount } from '../slices/chats';
import SelectIcon from '../components/Icons/SelectIcon';
import HylloText from '../components/HylloText';
import ListSeparator from '../components/ListSeparator';
import {
  ContextMenu,
  ContextMenuList,
  ContextMenuListItem,
} from '../components/ContextMenu';
import { getSelectChatsMode, toggleSelectChatsMode } from '../slices/settings';
import { defaultHeaderOptions, headerStyles } from '../utils/navigation';
import Button from '../components/Button';
import useConnectClient from '../hooks/useConnectClient';

const Stack = createNativeStackNavigator();

const Tab = createBottomTabNavigator();

const TabScreens = () => {
  const dispatch: AppDispatch = useDispatch();
  const totalUnreadCount = useSelector(getTotalUnreadCount);
  const selectChatsMode = useSelector(getSelectChatsMode);
  return (
    <Tab.Navigator initialRouteName={Screens.ChatList}>
      <Tab.Screen
        name={Screens.ChatList}
        component={ChatList}
        options={() => ({
          title: 'Chats',
          headerLeft: () =>
            !selectChatsMode && (
              <ContextMenu
                width={254}
                height={105}
                button={
                  <View style={{ padding: 12 }}>
                    <Feather
                      name="more-horizontal"
                      size={22}
                      color={PrimaryColor.PrimaryPurple}
                    />
                  </View>
                }
                popover={
                  <ContextMenuList>
                    <ContextMenuListItem
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      onPress={() => dispatch(toggleSelectChatsMode())}
                    >
                      <HylloText>Select Messages</HylloText>
                      <SelectIcon size={16} color={ShadeColor.White} />
                    </ContextMenuListItem>
                    <ListSeparator
                      style={{ backgroundColor: NeutralColor.Gray08 }}
                    />
                    <ContextMenuListItem
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <HylloText>Edit Pins</HylloText>
                      <PinIcon size={18} color={ShadeColor.White} />
                    </ContextMenuListItem>
                  </ContextMenuList>
                }
              />
            ),
          headerRight: () =>
            selectChatsMode ? (
              <Button
                variant="link"
                style={{ marginRight: 12 }}
                onPress={() => dispatch(toggleSelectChatsMode())}
              >
                Done
              </Button>
            ) : (
              <Feather
                name="edit"
                size={22}
                onPress={() => dispatch(openModal({ name: 'newChat' }))}
                style={{ marginRight: 12, color: PrimaryColor.PrimaryPurple }}
              />
            ),
          tabBarIcon: ({ focused }) => (
            <ChatIcon
              size={24}
              color={focused ? PrimaryColor.PrimaryPurple : NeutralColor.Gray05}
            />
          ),
          tabBarBadge: !!totalUnreadCount ? totalUnreadCount : undefined,
          tabBarBadgeStyle: {
            fontSize: 10,
            backgroundColor: WarningColor.Red,
            marginTop: Platform.OS !== 'web' ? -10 : 0,
          },
          ...defaultHeaderOptions,
        })}
      />
      <Tab.Screen
        name={Screens.Contacts}
        component={Contacts}
        options={{
          headerRight: () => (
            <Feather
              name="user-plus"
              size={22}
              onPress={() => dispatch(openModal({ name: 'newContact' }))}
              style={{ marginRight: 12, color: PrimaryColor.PrimaryPurple }}
            />
          ),
          tabBarIcon: (props) => (
            <Feather
              name="users"
              size={24}
              style={{
                color: props.focused
                  ? PrimaryColor.PrimaryPurple
                  : NeutralColor.Gray05,
              }}
            />
          ),
          ...defaultHeaderOptions,
        }}
      />
      <Tab.Screen
        name={Screens.Wallet}
        component={Wallet}
        options={{
          tabBarIcon: (props) => (
            <Ionicons
              name="wallet-outline"
              size={24}
              style={{
                color: props.focused
                  ? PrimaryColor.PrimaryPurple
                  : NeutralColor.Gray05,
              }}
            />
          ),
          ...defaultHeaderOptions,
        }}
      />
      <Tab.Screen
        name={Screens.Settings}
        component={Settings}
        options={{
          tabBarIcon: (props) => (
            <Feather
              name="settings"
              size={24}
              style={{
                color: props.focused
                  ? PrimaryColor.PrimaryPurple
                  : NeutralColor.Gray05,
              }}
            />
          ),
          ...defaultHeaderOptions,
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
};

const BaseNavigator = () => {
  const address = useSelector(getAddress);

  useFinishOnboarding();
  useWeb3Sync();
  useConnectClient();

  return (
    <Stack.Navigator
      initialRouteName={address ? Screens.ChatList : Screens.Welcome}
    >
      {address ? (
        <>
          <Stack.Screen
            name="TabScreens"
            component={TabScreens}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.Chat}
            component={Chat}
            options={{
              header: ({
                navigation,
                route,
                back,
              }: {
                navigation: any;
                route: any;
                back?: any;
              }) => (
                <ChatHeader
                  address={route.params?.address}
                  showBackButton={back}
                  onBackPress={navigation.goBack}
                />
              ),
            }}
          />
          <Stack.Screen
            name={Screens.SearchChat}
            component={SearchChat}
            options={{
              headerShown: false,
              animation: 'fade',
            }}
          />
          <Stack.Screen
            name={Screens.SearchContacts}
            component={SearchContacts}
            options={{
              headerShown: false,
              animation: 'fade',
            }}
          />
          <Stack.Screen
            name={Screens.CreateUser}
            component={CreateUser}
            options={{
              title: 'Hyllo',
              headerStyle: headerStyles.headerStyle,
              headerTintColor: PrimaryColor.PrimaryPurple,
              headerTitleStyle: textSizes.poppinsH2,
              headerTitleAlign: 'center',
              headerLeft: () => null,
            }}
          />
          <Stack.Screen
            name={Screens.Passcode}
            component={Passcode}
            options={{
              title: 'Hyllo',
              headerStyle: headerStyles.headerStyle,
              headerTintColor: PrimaryColor.PrimaryPurple,
              headerTitleStyle: textSizes.poppinsH2,
              headerTitleAlign: 'center',
            }}
          />
          <Stack.Screen
            name={Screens.WalletSuccess}
            component={WalletSuccess}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.SecretRecoveryPhrase}
            component={SecretRecoveryPhrase}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.FaceID}
            component={FaceID}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.CreateWallet}
            component={CreateWallet}
            options={{
              headerShown: false,
              headerLeft: () => null,
            }}
          />
          <Stack.Screen
            name={Screens.SecretRecoverySetup}
            component={SecretRecoverySetup}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.PushNotifications}
            component={PushNotifications}
            options={{
              ...defaultHeaderOptions,
            }}
          />
          <Stack.Screen
            name={Screens.PrivateChatsSettings}
            component={PrivateChatsSettings}
            options={{
              ...defaultHeaderOptions,
            }}
          />
          <Stack.Screen
            name={Screens.GroupChatsSettings}
            component={GroupChatsSettings}
            options={{
              ...defaultHeaderOptions,
            }}
          />
          <Stack.Screen
            name={Screens.UnknownMessagesSettings}
            component={UnknownMessagesSettings}
            options={{
              ...defaultHeaderOptions,
            }}
          />
          <Stack.Screen
            name={Screens.UserProfile}
            component={UserProfile}
            options={{
              headerShown: false,
            }}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            name={Screens.Welcome}
            component={Welcome}
            options={{
              ...defaultHeaderOptions,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.VideoPlayer}
            component={VideoPlayer}
            options={{
              headerShown: false,
              animation: 'fade',
            }}
          />
          <Stack.Screen
            name={Screens.CreateWallet}
            component={CreateWallet}
            options={{
              headerShown: false,
              headerLeft: () => null,
            }}
          />
          <Stack.Screen
            name={Screens.ImportWallet}
            component={ImportWallet}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name={Screens.Wallet}
            component={Wallet}
            options={defaultHeaderOptions}
          />
          <Stack.Screen
            name={Screens.ImportSeed}
            component={ImportSeed}
            options={{
              title: 'Hyllo',
              headerStyle: headerStyles.headerStyle,
              headerTintColor: PrimaryColor.PrimaryPurple,
              headerTitleStyle: textSizes.poppinsH2,
              headerTitleAlign: 'center',
              headerBackTitleVisible: false,
            }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export default BaseNavigator;
