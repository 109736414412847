import React, { useCallback, useState } from 'react';
import {
  View,
  StyleSheet,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
} from 'react-native';
import { NeutralColor, ShadeColor, WarningColor } from '../utils';
import { useHeaderHeight } from '@react-navigation/elements';
import { setItemAsync } from 'expo-secure-store';
import { Screens } from '../navigator/screens';
import HylloText from '../components/HylloText';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { getAddress, updateUser } from '../slices/user';
import PIN from '../components/PIN';

const styles = StyleSheet.create({
  passcodeContainer: {
    backgroundColor: ShadeColor.Black,
    flex: 1,
    alignItems: 'center',
  },
  passcodeHeader: {
    color: ShadeColor.White,
    marginVertical: 24,
  },
  passcodeDescription: {
    color: NeutralColor.Gray04,
    marginBottom: 132,
  },
  pinContainer: {
    flexDirection: 'row',
  },
  pinCircle: {
    width: 14,
    height: 14,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: ShadeColor.White,
  },
  pinFilled: {
    backgroundColor: ShadeColor.White,
  },
  input: {
    width: 0,
  },
  error: {
    color: WarningColor.Red,
    marginBottom: 132,
  },
  passcodeCaptionText: {
    color: NeutralColor.Gray04,
  },
  passcodeCaption: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: Platform.OS === 'web' ? 48 : 16,
    marginHorizontal: 24,
  },
});

const delayTime = 300;

const Passcode = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const address = useSelector(getAddress);
  const headerHeight = useHeaderHeight();
  const [pin, setPin] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [isConfirmingPin, setIsConfirmingPin] = useState<boolean>(false);

  const pinValue = isConfirmingPin ? confirmPin : pin;

  const handlePin = useCallback(
    (text: string) => {
      if (text.length === 6) {
        setTimeout(() => {
          setIsConfirmingPin(true);
        }, delayTime);
      }
      setPin(text);
    },
    [setPin, setIsConfirmingPin]
  );

  const handleConfirmPin = useCallback(
    async (text: string) => {
      if (text.length === 6 && text !== pin) {
        setError(`PIN doesn't match. Please try again.`);
        setConfirmPin('');
      } else {
        setConfirmPin(text);
        setError('');
      }
      setTimeout(async () => {
        if (text.length === 6 && text === pin) {
          await setItemAsync('pin', pin);
          await dispatch(updateUser({ address, hasPIN: true }));
          Keyboard.dismiss();
          navigation.navigate(Screens.FaceID);
        }
      }, delayTime);
    },
    [pin, address, dispatch, navigation]
  );

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.passcodeContainer}
      keyboardVerticalOffset={headerHeight}
    >
      <HylloText size="h2" style={styles.passcodeHeader}>
        {isConfirmingPin ? 'Confirm Your PIN' : 'Create a PIN'}
      </HylloText>
      {error ? (
        <HylloText style={styles.error}>{error}</HylloText>
      ) : (
        <HylloText style={styles.passcodeDescription}>
          {isConfirmingPin
            ? 'Put in your PIN one more time to verify.'
            : 'So no one can unlock your wallet except you.'}
        </HylloText>
      )}
      <PIN
        pinValue={pinValue}
        onChangePin={isConfirmingPin ? handleConfirmPin : handlePin}
      />
      <View style={styles.passcodeCaption}>
        <HylloText size="caption" style={styles.passcodeCaptionText}>
          PINs are used to help us confirm your identity when your account
          authorizes a transaction.
        </HylloText>
      </View>
    </KeyboardAvoidingView>
  );
};

export default Passcode;
