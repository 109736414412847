import { StyleSheet, View } from 'react-native';
import { NeutralColor } from '../utils';
import HylloText from './HylloText';

const styles = StyleSheet.create({
  defaultImage: {
    backgroundColor: NeutralColor.Gray09,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface DefaultImageProps {
  size?: number;
  rounded?: boolean;
  style?: any;
  text?: string;
}

const DefaultImage = ({
  size = 54,
  rounded = true,
  style,
  text,
}: DefaultImageProps) => (
  <View
    style={[
      styles.defaultImage,
      { height: size, width: size },
      rounded && { borderRadius: 1000 },
      style,
    ]}
  >
    {text && <HylloText size={size > 54 ? 'h2' : 'body'}>{text}</HylloText>}
  </View>
);

export default DefaultImage;
