import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  displayAddress,
} from '../../utils';
import ProfilePhoto from '../ProfilePhoto';
import HylloText from '../HylloText';
import useContactInfo from '../../hooks/useContactInfo';
import { displayNickname } from '../../utils/user';
import CountBadge from '../CountBadge';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectChatsMode,
  getSelectedChats,
  toggleSelectedChat,
} from '../../slices/settings';
import { AppDispatch } from '../../redux/store';
import SelectButton from '../SelectButton';

const styles = StyleSheet.create({
  chatListContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: ShadeColor.Black,
  },
  item: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    flex: 1,
    color: ShadeColor.White,
  },
  profilePhoto: {
    marginRight: 16,
  },
  itemTime: { color: NeutralColor.Gray07 },
  itemContainer: { flex: 1, justifyContent: 'center' },
  itemTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  itemLastMessage: { color: NeutralColor.Gray05 },
  unreadCount: {
    height: 24,
    width: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: PrimaryColor.PrimaryPurple,
    borderRadius: 100,
    color: ShadeColor.White,
  },
  itemLastMessageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

type ItemProps = {
  id: string;
  address: string;
  time: string;
  displayMessage?: string;
  onPress: () => void;
  containerStyle?: any;
  unreadCount?: number;
};

const ChatListItem = ({
  id,
  address,
  time,
  displayMessage,
  onPress,
  containerStyle,
  unreadCount,
}: ItemProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { findContactWithInfo } = useContactInfo();
  const contactInfo = findContactWithInfo(address);
  const selectChatsMode = useSelector(getSelectChatsMode);
  const selectedChats = useSelector(getSelectedChats);
  const handlePress = () => {
    if (selectChatsMode) {
      dispatch(toggleSelectedChat(id));
    } else {
      onPress();
    }
  };
  return (
    <Pressable
      id={id}
      onPress={handlePress}
      style={[styles.item, containerStyle]}
    >
      {selectChatsMode && (
        <SelectButton
          checked={selectedChats[id]}
          onChange={() => dispatch(toggleSelectedChat(id))}
          style={{ marginRight: 16 }}
        />
      )}
      <ProfilePhoto
        address={address}
        style={styles.profilePhoto}
        initial={contactInfo?.name?.[0]}
      />
      <View style={styles.itemContainer}>
        <View style={styles.itemTitle}>
          <HylloText size="button" weight="semibold" style={styles.title}>
            {displayNickname(contactInfo, displayAddress(address))}
          </HylloText>
          {!selectChatsMode && (
            <HylloText size="caption" style={styles.itemTime}>
              {time}
            </HylloText>
          )}
        </View>
        <View style={styles.itemLastMessageContainer}>
          <HylloText
            size="button"
            weight="regular"
            numberOfLines={1}
            style={styles.itemLastMessage}
          >
            {displayMessage}
          </HylloText>
          {!selectChatsMode && !!unreadCount && (
            <CountBadge count={unreadCount} />
          )}
        </View>
      </View>
    </Pressable>
  );
};

export default ChatListItem;
