import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  addChatDB,
  addMessageDB,
  deleteChatDB,
  fetchChatsByUserDB,
  sendReadReceiptDB,
  updateChatMessagesDB,
} from '../services/chat';
import groupBy from 'lodash/groupBy';
import {
  Chat,
  Message,
  ResponseStatus,
  formatDate,
  isUnreadMessage,
} from '../utils';
import orderBy from 'lodash/orderBy';
import { RootState } from '../redux/store';
import { getAddress } from './user';
import sum from 'lodash/sum';
import { sendMessage } from '../modules/client';

interface ChatsSlice {
  chats: Chat[];
  status: ResponseStatus;
  error: string;
  newChatOpen: boolean;
  messageStatus: ResponseStatus;
  messageError: string;
}

const initialState: ChatsSlice = {
  chats: [],
  status: ResponseStatus.Unfetched,
  error: '',
  newChatOpen: false,
  messageStatus: ResponseStatus.Unfetched,
  messageError: '',
};

const fetchChatsConstant = 'fetchChats';
const addChatConstant = 'addChat';
const addMessageConstant = 'addMessage';
const deleteChatConstant = 'deleteChat';
const sendReadReceiptConstant = 'sendReadReceipt';

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    [`${fetchChatsConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchChatsConstant}/fulfilled`]: (state, action) => {
      state.chats = action.payload;
      state.status = ResponseStatus.Success;
    },
    [`${fetchChatsConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${addChatConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${addChatConstant}/fulfilled`]: (state, action) => {
      state.chats.push(action.payload);
      state.status = ResponseStatus.Success;
    },
    [`${addChatConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${addMessageConstant}/pending`]: (state) => {
      state.messageStatus = ResponseStatus.Loading;
    },
    [`${addMessageConstant}/fulfilled`]: (state, action) => {
      state.messageStatus = ResponseStatus.Success;
      const chatIndex = state.chats.findIndex(
        (chat: Chat) => chat.id === action.payload.chatId
      );
      const currentMessages = state.chats[chatIndex].messages;
      // if message exists, update it
      const messageIndex = currentMessages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (messageIndex > -1) {
        const message = currentMessages[messageIndex];
        currentMessages[messageIndex] = { ...message, ...action.payload };
      } else {
        currentMessages.push(action.payload);
      }
    },
    [`${addMessageConstant}/rejected`]: (state, action) => {
      state.messageStatus = ResponseStatus.Failure;
      state.messageError = action.payload;
    },
    [`${deleteChatConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${deleteChatConstant}/fulfilled`]: (state, action) => {
      const chats = state.chats.filter(
        (chat: Chat) => chat.id !== action.payload
      );
      state.chats = chats;
    },
    [`${deleteChatConstant}/rejected`]: (state, action) => {
      state.error = action.payload;
      state.status = ResponseStatus.Failure;
    },
    [`${sendReadReceiptConstant}/pending`]: (state) => {
      // state.status = ResponseStatus.Loading;
    },
    [`${sendReadReceiptConstant}/fulfilled`]: (state, action) => {
      const chat = state.chats.find(
        (chat) => chat.id === action.payload.chatId
      );
      if (chat) {
        const message = chat.messages[action.payload.messageId];
        if (message) {
          if (!message.readReceipts) {
            message.readReceipts = {};
          }
          message.readReceipts[action.payload.address] = action.payload.time;
        }
      }
    },
    [`${sendReadReceiptConstant}/rejected`]: (state, action) => {
      // state.error = action.payload;
      // state.status = ResponseStatus.Failure;
    },
  },
});

export const fetchChats = createAsyncThunk(
  `chats/${fetchChatsConstant}`,
  fetchChatsByUserDB
);

export const addChat = createAsyncThunk(`chats/${addChatConstant}`, addChatDB);

const addMessageLogic = async (message: any, { getState }: any) => {
  const currentChat = getCurrentChat(getState(), message.chatId);
  const newMessage = await addMessageDB(message);
  if (currentChat) {
    const chatMessages = Array.isArray(currentChat.messages)
      ? currentChat.messages
      : Object.keys(currentChat.messages || {});
    const chatMessageIds = chatMessages
      .map((message: any) => message.id)
      .concat(newMessage.id);
    await updateChatMessagesDB(message.chatId, chatMessageIds);
  }
  sendMessage(
    message.to,
    JSON.stringify({ type: 'message', content: newMessage })
  );
  return newMessage;
};

export const addMessageSync = (message: any) => ({
  type: `chats/${addMessageConstant}/fulfilled`,
  payload: message,
});

export const addMessage = createAsyncThunk(
  `chats/${addMessageConstant}`,
  addMessageLogic
);

export const deleteChat = createAsyncThunk(
  `chats/${deleteChatConstant}`,
  deleteChatDB
);

export const sendReadReceipt = createAsyncThunk(
  `chats/${sendReadReceiptConstant}`,
  sendReadReceiptDB
);

export const getChats = (state: RootState) => state.chats.chats;
export const getCurrentChat = (
  state: RootState,
  chatId: string
): Chat | undefined =>
  state.chats.chats.find((chat: Chat) => chat.id === chatId);
export const getCurrentMessages = createSelector(
  [getCurrentChat],
  (chat: Chat | undefined) => {
    // group messages by date
    const messages = chat?.messages;
    // set property of date that is well formatted
    const groupedMessages = groupBy(
      orderBy(messages, 'created', ['asc']),
      (message: Message) => formatDate(message.created || Date.now())
    );
    // today if today, yesterday if yesterday, day of the week if under 7 days, nice month, day if within year, month, day, year else
    return orderBy(
      Object.entries(groupedMessages),
      ([, value]) => value[0].created,
      ['desc']
    );
  }
);

export const getMessages = createSelector(
  [getCurrentChat],
  (chat: Chat | undefined) => chat?.messages
);

export const getUnreadMessagesByChat = createSelector(
  [getAddress, getChats],
  (address: string, chats: Chat[]) => {
    const messagesByChat = chats.reduce((acc: any, chat: Chat) => {
      const unreadMessages = chat.messages.filter((message) =>
        isUnreadMessage(message, address)
      );
      return { ...acc, [chat.id]: unreadMessages };
    }, {});
    return messagesByChat;
  }
);

export const getUnreadCountByChat = createSelector(
  [getUnreadMessagesByChat],
  (unreadMessagesByChat) =>
    Object.entries(unreadMessagesByChat).reduce(
      (acc: any, entries: any[]) => ({
        ...acc,
        [entries[0]]: entries[1].length,
      }),
      {}
    )
);

export const getTotalUnreadCount = createSelector(
  [getUnreadCountByChat],
  (unreadCountByChat) => sum(Object.values(unreadCountByChat))
);

export const getAllMessages = (state: RootState) =>
  state.chats.chats.reduce(
    (acc: any, chat: Chat) => acc.concat(chat.messages),
    []
  );

export const getNewChatOpen = (state: RootState) => state.chats.newChatOpen;

export default chatsSlice.reducer;
