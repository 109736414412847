import React, { useState } from 'react';
import { FlatList, SafeAreaView, StyleSheet, View } from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import Button from '../components/Button';
import Input from '../components/Input';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  textSizes,
  textWeights,
} from '../utils';
import { Contact, getContacts } from '../slices/contacts';
import ContactsListItem from '../components/Contacts/ContactsListItem';
import { useDispatch, useSelector } from 'react-redux';
import useContactInfo from '../hooks/useContactInfo';
import ListSeparator from '../components/ListSeparator';
import HylloText from '../components/HylloText';
import useOpenChat from '../hooks/useOpenChat';
import { AppDispatch } from '../redux/store';
import { openModal } from '../slices/modals';

const styles = StyleSheet.create({
  container: {
    backgroundColor: ShadeColor.Black,
    flex: 1,
  },
  headerRow: { flexDirection: 'row', padding: 16, alignItems: 'center' },
  inputContainer: { flex: 1, marginTop: 0, marginBottom: 0, marginRight: 8 },
  input: {
    paddingLeft: 36,
    paddingVertical: 7,
  },
  noResultsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsTitle: { marginBottom: 20, color: NeutralColor.Gray06 },
  noResultsTextColor: { color: NeutralColor.Gray06 },
  focusedInput: {
    borderColor: PrimaryColor.Purple03,
    borderWidth: 1,
  },
});

const SearchContacts = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [inputFocused, setInputFocused] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const contacts = useSelector(getContacts);
  const { searchContactInfo } = useContactInfo();
  const handleOpenChat = useOpenChat();
  const filteredContacts = !!searchInput
    ? Object.values(contacts).filter((contact: Contact) =>
        searchContactInfo(searchInput, contact)
      )
    : [];
  const noResults = !!searchInput && !filteredContacts.length;
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerRow}>
        <Input
          variant="secondary"
          startNode={
            <Feather name="search" size={16} color={NeutralColor.Gray07} />
          }
          placeholder="Search Contacts"
          placeholderTextColor={NeutralColor.Gray07}
          style={[styles.input, inputFocused && styles.focusedInput]}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          containerStyle={styles.inputContainer}
          value={searchInput}
          onChangeText={setSearchInput}
          autoFocus
          autoCapitalize="none"
        />
        <Button
          variant="link"
          textStyle={{ ...textSizes.body, ...textWeights.semibold }}
          onPress={() => navigation.goBack()}
        >
          Cancel
        </Button>
      </View>
      {noResults ? (
        <View style={styles.noResultsContainer}>
          <HylloText weight="bold" style={styles.noResultsTitle}>
            No Results
          </HylloText>
          <HylloText
            size="button"
            weight="regular"
            style={styles.noResultsTextColor}
          >
            There were no results for "{searchInput}".
          </HylloText>
          <HylloText
            size="button"
            weight="regular"
            style={styles.noResultsTextColor}
          >
            Try a new search
          </HylloText>
          <HylloText
            size="button"
            weight="regular"
            style={[styles.noResultsTextColor, { marginHorizontal: 2 }]}
          >
            or
          </HylloText>
          <View style={{ flexDirection: 'row' }}>
            <Button
              variant="link"
              onPress={() =>
                dispatch(
                  openModal({
                    name: 'newContact',
                    props: { initialAddress: searchInput },
                  })
                )
              }
            >
              Try a new contact
            </Button>
          </View>
        </View>
      ) : (
        <FlatList
          data={filteredContacts}
          ItemSeparatorComponent={() => <ListSeparator marginLeft={86} />}
          keyExtractor={(item: Contact) => item.address}
          renderItem={({ item }: { item: Contact }) => (
            <ContactsListItem address={item.address} onPress={handleOpenChat} />
          )}
        />
      )}
    </SafeAreaView>
  );
};

export default SearchContacts;
