import { ReactNode } from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';
import { textWeights, textSizes, ShadeColor } from '../utils';

const findFontWeight = (size: FontSize, weight?: FontWeight) => {
  if (weight) {
    return textWeights[weight];
  }
  if (size.startsWith('h') || size === 'button') {
    return textWeights.bold;
  }
  return textWeights.regular;
};

export type FontSize =
  | 'poppinsH1'
  | 'poppinsH2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'body'
  | 'button'
  | 'caption'
  | 'tab';

export type FontWeight =
  | 'thin'
  | 'light'
  | 'regular'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'black';

interface HylloTextProps extends TextProps {
  children: ReactNode;
  size?: FontSize;
  weight?: FontWeight;
}

const styles = StyleSheet.create({
  defaultText: {
    color: ShadeColor.White,
  },
});

const HylloText = ({ size = 'body', weight, ...props }: HylloTextProps) => {
  const adjustedWeight = findFontWeight(size, weight);
  return (
    <Text
      {...props}
      style={[textSizes[size], styles.defaultText, adjustedWeight, props.style]}
    />
  );
};

export default HylloText;
