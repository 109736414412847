import React, { forwardRef } from 'react';
import {
  Pressable,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
} from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import { NeutralColor, ShadeColor, WarningColor, textSizes } from '../utils';

interface InputProps extends TextInputProps {
  variant?: 'primary' | 'secondary';
  showCancel?: boolean;
  onCancel?: () => void;
  startNode?: React.ReactNode;
  containerStyle?: any;
  hasError?: boolean;
}

const styles = StyleSheet.create({
  inputContainer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 12,
    width: '100%',
  },
  input: {
    ...textSizes.inputBody,
    color: ShadeColor.White,
    paddingVertical: 16,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: NeutralColor.Gray06,
    flex: 1,
  },
  secondary: {
    backgroundColor: NeutralColor.Gray10,
    borderWidth: 0,
  },
  cancelButton: {
    position: 'absolute',
    right: 12,
  },
  cancelButtonSecondary: {
    borderRadius: 100,
    backgroundColor: NeutralColor.Gray06,
    padding: 2,
  },
  startNode: {
    position: 'absolute',
    left: 12,
    zIndex: 100,
  },
  errorInput: {
    borderColor: WarningColor.Red,
  }
});

const Input = forwardRef<TextInput, InputProps>(
  (
    {
      variant = 'primary',
      showCancel,
      onCancel,
      style,
      containerStyle,
      startNode,
      hasError,
      ...otherProps
    },
    ref
  ) => (
    <View style={[styles.inputContainer, containerStyle]}>
      {startNode && <View style={styles.startNode}>{startNode}</View>}
      <TextInput
        ref={ref}
        style={[
          styles.input,
          variant === 'secondary' && styles.secondary,
          hasError && styles.errorInput,
          style,
        ]}
        {...otherProps}
      />
      {showCancel && (
        <Pressable
          style={[
            styles.cancelButton,
            variant === 'secondary' && styles.cancelButtonSecondary,
          ]}
          onPress={onCancel}
        >
          {variant === 'secondary' ? (
            <Feather name="x" color={NeutralColor.Gray10} size={16} />
          ) : (
            <Feather name="x-circle" color={NeutralColor.Gray06} size={20} />
          )}
        </Pressable>
      )}
    </View>
  )
);

export default Input;
