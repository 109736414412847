import isDate from 'lodash/isDate';

export const MILLISECOND = 1;
export const SECOND = 1000 * MILLISECOND;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

const usLocale = 'en-US';

const intlFormatDateTime = (
  datetime: number | Date,
  opts: Intl.DateTimeFormatOptions
) => {
  const date = isDate(datetime) ? datetime : new Date(datetime);
  const dateFormat = new Intl.DateTimeFormat(usLocale, opts);
  return dateFormat.format(date);
};

export const formatTime = (datetime: number) => {
  return intlFormatDateTime(datetime, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatDateTime = (datetime: number): string => {
  const today = new Date();
  const date = new Date(datetime);
  if (today.toDateString() === date.toDateString()) {
    return intlFormatDateTime(date, {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  if (
    today.getDay() - date.getDay() === 1 ||
    today.getDay() - date.getDay() === -6
  ) {
    return 'Yesterday';
  }
  const within7Days: boolean = today.getTime() - date.getTime() < 7 * DAY;
  if (within7Days && today.getDay() !== date.getDay()) {
    return intlFormatDateTime(date, { weekday: 'long' });
  }
  return intlFormatDateTime(date, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

export const formatDate = (datetime: number) => {
  const today = new Date();
  const date = new Date(datetime);
  if (today.toDateString() === date.toDateString()) {
    return 'Today';
  }
  const within2Days = today.getTime() - datetime < 2 * DAY;
  if (
    within2Days &&
    (today.getDay() - date.getDay() === 1 ||
      today.getDay() - date.getDay() === -6)
  ) {
    return 'Yesterday';
  }
  const within7Days = today.getTime() - datetime < 7 * DAY;
  if (within7Days && today.getDay() !== date.getDay()) {
    return intlFormatDateTime(date, { weekday: 'long' });
  }
  const dateOpts: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };
  const withinYear: boolean = today.getTime() - date.getTime() < 365 * DAY;
  if (withinYear) {
    return intlFormatDateTime(date, dateOpts);
  }
  const dateYearOpts: Intl.DateTimeFormatOptions = {
    ...dateOpts,
    year: 'numeric',
  };
  return intlFormatDateTime(date, dateYearOpts);
};
