import { FlatList, StyleSheet } from 'react-native';
import {
  Chat,
  NeutralColor,
  findChatAddress,
  findNewestMessage,
  formatDateTime,
} from '../../utils';
import { useSelector } from 'react-redux';
import { getChats, getUnreadCountByChat } from '../../slices/chats';
import { getContacts } from '../../slices/contacts';
import { getSelectChatsMode } from '../../slices/settings';
import { orderBy } from 'lodash';
import { getAddress } from '../../slices/user';
import ListSeparator from '../ListSeparator';
import SwipeableChatListItem from './SwipeableChatListItem';
import { Screens, StackParamList } from '../../navigator/screens';
import { Swipeable } from 'react-native-gesture-handler';
import { useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

const styles = StyleSheet.create({
  chatListListContainer: {
    backgroundColor: NeutralColor.Gray10,
  },
});

const FilteredChatList = ({
  filterKey = 'all',
}: {
  filterKey: 'strangers' | 'unread' | 'all';
}) => {
  const address = useSelector(getAddress);
  const chats = useSelector(getChats);
  const contacts = useSelector(getContacts);
  const unreadByChat = useSelector(getUnreadCountByChat);
  const selectChatsMode = useSelector(getSelectChatsMode);
  const swipeRef = useRef<any>(null);
  const navigation =
    useNavigation<NativeStackNavigationProp<StackParamList, Screens.Chat>>();

  const sortedChats = orderBy(
    Object.values(chats || {}),
    (chat: Chat) => findNewestMessage(chat).created,
    'desc'
  );
  const displayedChats = sortedChats.filter((chat: Chat) => {
    if (filterKey === 'strangers') {
      const chatAddress = findChatAddress(chat, address);
      return contacts?.[chatAddress] === undefined;
    } else if (filterKey === 'unread') {
      return unreadByChat[chat.id] > 0;
    }
    return true;
  });
  const handleSwipeOpen = (ref: Swipeable) => {
    if (swipeRef.current) {
      swipeRef.current?.close();
    }
    swipeRef.current = ref;
  };

  const handleSwipeClose = (ref: Swipeable) => {
    if (ref == swipeRef.current) {
      swipeRef.current = null;
    }
  };
  return (
    <FlatList
      data={displayedChats}
      style={styles.chatListListContainer}
      ItemSeparatorComponent={() => (
        <ListSeparator marginLeft={selectChatsMode ? 126 : 86} />
      )}
      renderItem={({ item }) => {
        const newestMessage = findNewestMessage(item);
        return (
          <SwipeableChatListItem
            id={item.id}
            address={findChatAddress(item, address)}
            time={formatDateTime(newestMessage.created)}
            displayMessage={
              item.messages ? newestMessage.message : 'Start chatting now.'
            }
            onPress={() => {
              navigation.navigate(Screens.Chat, {
                chatId: item.id,
                address: findChatAddress(item, address),
              });
            }}
            onSwipeOpen={handleSwipeOpen}
            onSwipeClose={handleSwipeClose}
            unreadCount={unreadByChat[item.id]}
          />
        );
      }}
      keyExtractor={(item) => `${item.id}-chat`}
    />
  );
};

export default FilteredChatList;
