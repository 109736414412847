import { isAddress } from 'ethers/lib/utils';

export const displayAddress = (address: string | undefined) => {
  if (!address) return '';
  if (!isAddress(address)) return address;
  const addressPart1 = address.slice(0, 6);
  const addressPart2 = address.slice(address.length - 5);
  return `${addressPart1}...${addressPart2}`;
};

export const isAddressEqual = (address1: string, address2: string) =>
  address1.toLowerCase() === address2.toLowerCase();

export const isENS = (address: string) => address.includes('.eth');

export const normalizeAddress = (address: string) => address.toLowerCase();
