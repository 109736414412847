import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from '../slices/user';
import useOnboardingNavigate from './useOnboardingNavigate';

const useFinishOnboarding = () => {
  const { hasLoaded } = useSelector(getUserData);

  const onboardingNavigate = useOnboardingNavigate();

  useEffect(() => {
    if (hasLoaded) {
      onboardingNavigate();
    }
  }, [hasLoaded]);
};

export default useFinishOnboarding;
