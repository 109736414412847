import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { addChat, getChats } from '../slices/chats';
import { useNavigation } from '@react-navigation/native';
import { Screens, StackParamList } from '../navigator/screens';
import { getAddress } from '../slices/user';
import { useCallback } from 'react';
import {
  Chat,
  findChatAddress,
  isAddressEqual,
  normalizeAddress,
} from '../utils';

const useOpenChat = () => {
  const chats = useSelector(getChats);
  const navigation =
    useNavigation<NativeStackNavigationProp<StackParamList, Screens.Chat>>();
  const address = useSelector(getAddress);
  const dispatch = useDispatch<AppDispatch>();
  const handleOpenChat = useCallback(
    async (chatAddress?: string) => {
      if (!chatAddress) return;
      let chat;
      const sameAddress = isAddressEqual(address, chatAddress);
      const previousChat = chats.find((chat: Chat) =>
        sameAddress
          ? chat.addresses.filter((addy: string) =>
              isAddressEqual(addy, chatAddress)
            ).length === 2
          : chat.addresses.includes(chatAddress)
      );
      if (previousChat) {
        chat = previousChat;
      } else {
        const id =
          address < chatAddress
            ? `${address}-${chatAddress}`
            : `${chatAddress}-${address}`;
        const data = {
          id,
        };
        const newChat = await dispatch(addChat(data)).unwrap();
        chat = newChat;
      }
      navigation.navigate(Screens.Chat, {
        chatId: chat?.id,
        address: findChatAddress(chat, address),
      });
    },
    [address, chats, dispatch, navigation]
  );

  return handleOpenChat;
};

export default useOpenChat;
