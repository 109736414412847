import React from 'react';
import { SectionList, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { Contact, getContacts } from '../../slices/contacts';
import { NeutralColor, PrimaryColor, textWeights } from '../../utils';
import HylloText from '../HylloText';
import SwipeableContactsListItem from './SwipeableContactsListItem';
import ListSeparator from '../ListSeparator';
import useContactInfo from '../../hooks/useContactInfo';
import sortBy from 'lodash/sortBy';

export interface ContactsListProps {
  containerStyle?: any;
  condensed?: boolean;
  onContactPress: (address: string) => void;
  filterInput?: string;
  ListHeaderComponent?: any;
}

const styles = StyleSheet.create({
  contactsListContainer: {
    backgroundColor: NeutralColor.Gray10,
  },
  contactsListHeader: {
    ...textWeights.bold,
    marginHorizontal: 16,
    marginVertical: 12,
    color: PrimaryColor.PrimaryPurple,
  },
});

const ContactsList = ({
  containerStyle,
  condensed = false,
  onContactPress,
  filterInput = '',
  ListHeaderComponent,
}: ContactsListProps) => {
  const swipeRef = React.useRef<any>(null);
  const contacts = useSelector(getContacts);
  const { findContactWithInfo } = useContactInfo();
  const filteredContacts = Object.values(contacts).filter(
    (contact: Contact) => {
      const contactInfo = findContactWithInfo(contact.address);
      const addressCheck = contactInfo?.address
        .toLowerCase()
        .includes(filterInput.toLowerCase());
      const nameCheck = contactInfo?.name
        ?.toLowerCase()
        .includes(filterInput.toLowerCase());
      return addressCheck || nameCheck;
    }
  );
  const groupedContacts = sortBy(
    Object.entries(
      groupBy(filteredContacts, (contact) => {
        const contactInfo = findContactWithInfo(contact.address);
        return contactInfo?.name?.[0]?.toLowerCase() || '0x';
      })
    ).map(([title, data]) => ({ title, data: sortBy(data, 'name') })),
    'title'
  );
  const handleSwipeOpen = (ref: any) => {
    if (swipeRef.current) {
      swipeRef.current.close();
    }
    swipeRef.current = ref;
  };
  const handleSwipeClose = (ref: any) => {
    if (swipeRef === ref) {
      swipeRef.current = null;
    }
  };
  return (
    <SectionList
      sections={groupedContacts}
      style={[styles.contactsListContainer, containerStyle]}
      ListHeaderComponent={ListHeaderComponent}
      renderItem={({ item }: { item: Contact }) => (
        <SwipeableContactsListItem
          {...item}
          onPress={onContactPress}
          condensed={condensed}
          onSwipeOpen={handleSwipeOpen}
          onSwipeClose={handleSwipeClose}
        />
      )}
      renderSectionHeader={({ section: { title } }) => (
        <HylloText style={styles.contactsListHeader}>
          {title.length === 1 ? title.toUpperCase() : title}
        </HylloText>
      )}
      ItemSeparatorComponent={() => <ListSeparator marginLeft={68} />}
    />
  );
};

export default ContactsList;
