import React from 'react';
import { Pressable, View } from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import { NeutralColor, PrimaryColor, ShadeColor } from '../utils';

const SelectButton = ({
  checked,
  onChange,
  style,
}: {
  checked: boolean;
  onChange: () => void;
  style?: any;
}) => {
  return (
    <Pressable style={style} onPress={onChange}>
      {checked ? (
        <View
          style={{
            height: 24,
            width: 24,
            borderWidth: 1,
            borderColor: PrimaryColor.PrimaryPurple,
            backgroundColor: PrimaryColor.PrimaryPurple,
            borderRadius: 100,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Feather name="check" size={20} color={ShadeColor.White} />
        </View>
      ) : (
        <View
          style={{
            height: 24,
            width: 24,
            borderRadius: 100,
            borderWidth: 1,
            borderColor: NeutralColor.Gray09,
          }}
        />
      )}
    </Pressable>
  );
};

export default SelectButton;
