import React from 'react';
import Svg, { Path } from 'react-native-svg';

const VideoIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}: any) => (
  <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...otherProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.181 3H5.069c-.604 0-1.102 0-1.508.033-.421.035-.809.109-1.173.294a3 3 0 00-1.311 1.311c-.185.364-.26.752-.294 1.173C.75 6.217.75 6.715.75 7.32v3.362c0 .604 0 1.102.033 1.508.035.421.109.809.294 1.173a3 3 0 001.311 1.311c.364.185.752.26 1.173.294.406.033.904.033 1.508.033h4.112c.604 0 1.102 0 1.508-.033.421-.035.809-.109 1.173-.294a3 3 0 001.311-1.311c.185-.364.26-.752.294-1.173.03-.377.033-.832.033-1.378l1.465 1.465c.143.143.284.284.408.388.11.092.342.277.664.302.359.028.71-.117.944-.39.209-.246.242-.54.255-.684.014-.16.014-.36.014-.563V6.671c0-.203 0-.403-.014-.563-.013-.143-.046-.438-.256-.683a1.125 1.125 0 00-.943-.391c-.322.025-.554.21-.664.302a6.864 6.864 0 00-.408.388L13.5 7.19c0-.546-.002-1.001-.033-1.378-.035-.421-.109-.809-.294-1.173a3 3 0 00-1.311-1.311c-.364-.185-.752-.26-1.173-.294C10.283 3 9.785 3 9.18 3z"
      fill={color}
    />
  </Svg>
);

export default VideoIcon;
