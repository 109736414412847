import { StyleSheet, TextInput, View } from 'react-native';
import {
  NeutralColor,
  ShadeColor,
  WarningColor,
  textSizes,
  textWeights,
} from '../utils';
import { useState } from 'react';
import Button from '../components/Button';
import { Screens } from '../navigator/screens';
import HylloText from '../components/HylloText';
import useWallet from '../hooks/useWallet';
import { hasUserId } from '../utils/user';
import useOnboardingNavigate from '../hooks/useOnboardingNavigate';

const styles = StyleSheet.create({
  container: {
    backgroundColor: ShadeColor.Black,
    padding: 24,
    flex: 1,
  },
  importTitle: {
    color: ShadeColor.White,
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 40,
  },
  description: {
    color: NeutralColor.Gray04,
  },
  input: {
    ...textSizes.inputBody,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: NeutralColor.Gray06,
    color: ShadeColor.White,
    marginVertical: 32,
    paddingTop: 12,
    paddingBottom: 12,
    paddingHorizontal: 16,
    height: 136,
  },
  disclaimer: {
    color: NeutralColor.Gray04,
  },
  footer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 100,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingHorizontal: 24,
  },
  error: {
    color: WarningColor.Red,
  },
  button: {
    width: 120,
  },
  buttonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
});

const ImportSeed = () => {
  const [mnemonic, setMnemonic] = useState('');
  const [error, setError] = useState('');
  const { importWallet } = useWallet();
  const onboardingNavigate = useOnboardingNavigate();
  const handleContinue = async () => {
    try {
      const user = await importWallet(mnemonic);
      onboardingNavigate(user);
    } catch (e: any) {
      setError(e?.message);
      return;
    }
  };
  return (
    <View style={styles.container}>
      <HylloText size="h2" style={styles.importTitle}>
        Import from Seed
      </HylloText>
      <HylloText style={[styles.description, error ? styles.error : null]}>
        {error ||
          'Enter your secret recovery phrase and unlock the power of your digital identity - safely!'}
      </HylloText>
      <TextInput
        style={styles.input}
        multiline={true}
        numberOfLines={4}
        value={mnemonic}
        onChangeText={setMnemonic}
        returnKeyType="done"
        blurOnSubmit={true}
        onSubmitEditing={handleContinue}
        autoCapitalize="none"
      />
      <HylloText size="caption" style={styles.disclaimer}>
        * Secret recovery phrase (SRP) is a unique phrase that is generated when
        you first set up your wallet, which usually contains 12, 18, or 24
        words.
      </HylloText>
      <View style={styles.footer}>
        <Button
          variant="secondary"
          onPress={() => setMnemonic('')}
          style={styles.button}
          textStyle={styles.buttonText}
          fullWidth
        >
          Clear
        </Button>
        <Button
          onPress={handleContinue}
          variant="primary"
          style={styles.button}
          textStyle={styles.buttonText}
          disabled={!mnemonic}
          fullWidth
        >
          Confirm
        </Button>
      </View>
    </View>
  );
};

export default ImportSeed;
