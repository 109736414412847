import { Platform } from 'react-native';
import { useAccount, useDisconnect } from 'wagmi';
import useLogoutUser from './useLogoutUser';
import { useWeb3Modal } from '@web3modal/react';

const useWalletConnectWeb = () => {
  const logoutUser = useLogoutUser();
  const { address, isConnected } = useAccount({
    onDisconnect: logoutUser,
  });
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  return {
    open,
    address,
    isConnected,
    provider: {
      disconnect,
    },
  };
};

export default useWalletConnectWeb;
