import { StyleSheet, View } from 'react-native';
import HylloText from './HylloText';
import { PrimaryColor, ShadeColor } from '../utils';

const styles = StyleSheet.create({
  countContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: PrimaryColor.PrimaryPurple,
    borderRadius: 100,
  },
  countText: { color: ShadeColor.White },
});

const CountBadge = ({
  count,
  size = 24,
  style,
  textStyle,
}: {
  count: number;
  size?: number;
  style?: any;
  textStyle?: any;
}) => (
  <View style={[styles.countContainer, { height: size, width: size }, style]}>
    <HylloText size="caption" style={[styles.countText, textStyle]}>
      {count}
    </HylloText>
  </View>
);

export default CountBadge;
