import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getAllMessages, getChats } from '../slices/chats';
import {
  Chat,
  Message,
  findChatAddress,
  findNewestMessage,
  formatDateTime,
  normalizeAddress,
} from '../utils';
import { getAddress } from '../slices/user';
import { getContacts } from '../slices/contacts';
import useContactInfo from './useContactInfo';
import { uniqBy } from 'lodash';

const useSearch = () => {
  const chats = useSelector(getChats);
  const allMessages = useSelector(getAllMessages);
  const address = useSelector(getAddress);
  const contacts = useSelector(getContacts);
  const { searchContactInfo } = useContactInfo();
  const searchMessages = useCallback(
    (search: string) =>
      allMessages.filter((message: Message) =>
        message.message.toLowerCase().includes(search.toLowerCase())
      ),
    [allMessages]
  );
  const searchChatAddress = useCallback(
    (search: string) =>
      chats.filter((chat: Chat) => {
        const addressCheck =
          chat.addresses.findIndex((address: string) =>
            normalizeAddress(address).includes(search.toLowerCase())
          ) !== -1;
        return addressCheck;
      }),
    [chats]
  );
  const searchContacts = useCallback(
    (search: string) =>
      chats.filter((chat: Chat) => {
        const chatAddress = findChatAddress(chat, address);
        const contact = contacts?.[chatAddress] || { address: chatAddress };
        return searchContactInfo(search, contact);
      }),
    [chats, address, contacts, searchContactInfo]
  );
  const searchChats = useCallback(
    (search: string) => {
      if (!search) return [];
      const chatsByAddress = searchChatAddress(search);
      const chatsByContacts = searchContacts(search);
      const searchedChats = uniqBy(
        chatsByAddress.concat(chatsByContacts),
        'id'
      );
      const formattedChats = searchedChats.map((chat: Chat) => {
        const newestMessage = findNewestMessage(chat);
        return {
          ...chat,
          type: 'chat',
          messageId: '',
          address: findChatAddress(chat, address),
          time: formatDateTime(newestMessage.created),
          displayMessage: chat.messages ? newestMessage.message : '',
        };
      });
      const messages = searchMessages(search);
      const formattedMessages = messages.map((message: Message) => {
        const chat = chats.find((chat: Chat) => chat.id === message.chatId);
        if (!chat) return;
        return {
          ...chat,
          messageId: message.id,
          type: 'message',
          address: findChatAddress(chat, address),
          time: formatDateTime(message.created),
          displayMessage: message.message,
        };
      });
      return formattedChats.concat(formattedMessages);
    },
    [
      chats,
      address,
      contacts,
      searchContactInfo,
      searchChatAddress,
      searchContacts,
      searchMessages,
    ]
  );
  return { searchMessages, searchChatAddress, searchContacts, searchChats };
};

export default useSearch;
