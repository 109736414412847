import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const SelectIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}: any) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <Path
      d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default SelectIcon;
