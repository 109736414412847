import React from 'react';
import { View } from 'react-native';
import { NeutralColor } from '../utils';

const ListSeparator = ({
  marginLeft = 0,
  style,
}: {
  marginLeft?: number;
  style?: any;
}) => (
  <View
    style={[
      { marginLeft, backgroundColor: NeutralColor.Gray09, height: 1 },
      style,
    ]}
  />
);

export default ListSeparator;
