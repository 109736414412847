import { createSlice } from '@reduxjs/toolkit';

interface ModalsSlice {
  modalName: string;
  modalProps: Record<string, any>;
  modalStyles?: Record<string, any>;
}

const initialState: ModalsSlice = {
  modalName: '',
  modalProps: {},
  modalStyles: {},
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.modalName = action.payload.name;
      state.modalProps = action.payload.props || {};
      state.modalStyles = action.payload.styles || {};
    },
    closeModal: (state) => {
      state.modalName = '';
      state.modalProps = {};
      state.modalStyles = {};
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;

export const getModals = (state: any) => state.modals;

export default modalsSlice.reducer;
