import { ethers } from 'ethers';
import { Platform } from 'react-native';
import { configureChains, createConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';

const { EthereumClient, w3mConnectors, w3mProvider } =
  Platform.OS === 'web'
    ? require('@web3modal/ethereum')
    : { EthereumClient: null, w3mConnectors: null, w3mProvider: null };

export const projectId = 'baae0c7954fa89ed80fb25e6a278f690';

export const providerMetadata = {
  name: 'Hyllo',
  description: 'Decentralized chat application',
  url: 'https://test.hyllo.xyz',
  icons: [
    'https://uploads-ssl.webflow.com/643572541516bb3a69738115/64482b76f943e259a429ddde_Gradient-Solid.svg',
  ],
  redirect: {
    native: 'hyllo://',
  },
};

const chains = [arbitrum, mainnet, polygon];

let wagmiConfig: any;
let ethereumClient: any;

if (Platform.OS === 'web') {
  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  ethereumClient = new EthereumClient(wagmiConfig, chains);
}

export { wagmiConfig, ethereumClient };

export const quickNodeUrl =
  'https://spring-frequent-theorem.discover.quiknode.pro/2e0194b36f5728c3a0e1d2bd9618ce3a200a04b1/';
export const provider = new ethers.providers.JsonRpcProvider(quickNodeUrl);
