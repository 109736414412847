import React from 'react';
import { Pressable, SafeAreaView, StyleSheet, View } from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import { NeutralColor, ShadeColor, displayAddress } from '../../utils';
import ProfilePhoto from '../ProfilePhoto';
import HylloText from '../HylloText';
import { Screens, StackParamList } from '../../navigator/screens';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useContactInfo from '../../hooks/useContactInfo';
import { displayNickname, displayUserId } from '../../utils/user';

const styles = StyleSheet.create({
  headerBackButton: {
    backgroundColor: NeutralColor.Gray09,
    borderRadius: 100,
    height: 44,
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
  },
  chatHeaderStyle: {
    backgroundColor: ShadeColor.Black,
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
  },
  chatPaddingView: {
    paddingTop: 8,
    paddingBottom: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
  },
  profilePhoto: {
    marginRight: 12,
  },
  chatHeaderTitleContainer: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  chatHeaderTitle: { flex: 1 },
  chatHeaderTitleText: {
    color: ShadeColor.White,
    lineHeight: 26,
  },
  chatHeaderTitleSubtext: {
    color: NeutralColor.Gray04,
  },
});

const ChatHeader = ({
  address,
  showBackButton,
  onBackPress,
}: {
  address: string;
  showBackButton: boolean;
  onBackPress: () => void;
}) => {
  const navigation = useNavigation<NativeStackNavigationProp<StackParamList>>();
  const { findContactWithInfo } = useContactInfo();
  const contactInfo = findContactWithInfo(address);
  return (
    <SafeAreaView style={styles.chatHeaderStyle}>
      <View style={styles.chatPaddingView}>
        {showBackButton && (
          <Pressable style={styles.headerBackButton} onPress={onBackPress}>
            <Feather name="arrow-left" size={20} color={ShadeColor.White} />
          </Pressable>
        )}
        <View style={styles.chatHeaderTitleContainer}>
          <Pressable
            onPress={() =>
              navigation.navigate(Screens.UserProfile, { address })
            }
          >
            <ProfilePhoto
              address={address}
              size={44}
              initial={contactInfo?.name?.[0]}
              style={styles.profilePhoto}
            />
          </Pressable>
          <View style={styles.chatHeaderTitle}>
            <HylloText style={styles.chatHeaderTitleText} weight="bold">
              {displayNickname(contactInfo, displayAddress(address))}
            </HylloText>
            <HylloText style={styles.chatHeaderTitleSubtext} size="caption">
              {displayUserId(contactInfo, displayAddress(address))}
            </HylloText>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default ChatHeader;
