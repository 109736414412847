import { ReactNode } from 'react';
import Tooltip from '../Tooltip';

const ContextMenu = ({
  width,
  height,
  popover,
  button,
}: {
  width: number;
  height: number;
  popover: ReactNode;
  button: ReactNode;
}) => (
  <Tooltip
    width={width}
    height={height}
    style={{ backgroundColor: 'transparent', borderWidth: 0 }}
    popover={popover}
    position="bottom"
  >
    {button}
  </Tooltip>
);

export default ContextMenu;
