import { displayAddress } from './address';
import { User } from './types';

export const hasUserId = (user: User) => user?.userId || user?.useEns;

export const displayUserId = (
  user?: User,
  defaultValue = '',
  atSymbol = true
) => {
  if (!user) return '';
  if (user.useEns && user.ens) {
    return user.ens;
  }
  if (user.userId) {
    return atSymbol ? `@${user.userId}` : user.userId;
  }
  return displayAddress(user.address) || defaultValue;
};

export const displayNickname = (user?: User, defaultValue = '') => {
  if (!user) return '';
  return (
    user.name ||
    displayUserId(user) ||
    displayAddress(user.address) ||
    defaultValue
  );
};
