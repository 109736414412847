import { Platform, View } from 'react-native';
import { FontSize } from './HylloText';
import { PrimaryColor, textSizes } from '../utils';
import { useEffect, useRef, useState } from 'react';

const BlinkingCursor = ({
  size = 'body',
  forceShow = false,
}: {
  size?: FontSize;
  forceShow?: boolean;
}) => {
  const [showCursor, setShowCursor] = useState(false);
  const intervalRef = useRef<any>(null);
  const cursorHeight = textSizes[size].fontSize;

  // blink cursor
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setShowCursor((prevState) => !prevState);
    }, 500);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <View
      style={{
        height: cursorHeight,
        width: 8,
        backgroundColor: PrimaryColor.PrimaryPurple,
        opacity: showCursor || forceShow ? 1 : 0,
        marginBottom: Platform.OS === 'web' ? -5 : 0,
      }}
    />
  );
};

export default BlinkingCursor;
