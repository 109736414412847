import React from 'react';
import { StyleSheet, View } from 'react-native';
import HylloText from '../HylloText';
import { PrimaryColor } from '../../utils';
import ListSeparator from '../ListSeparator';

const styles = StyleSheet.create({
  cardTitleContainer: { flexDirection: 'row', zIndex: 100, marginBottom: -1 },
  cardTitle: {
    color: PrimaryColor.PrimaryPurple,
  },
  cardTitleView: {
    borderBottomColor: PrimaryColor.PrimaryPurple,
    borderBottomWidth: 2,
    paddingVertical: 12,
    marginHorizontal: 16,
  },
  emptySpace: { flex: 1 },
});

const CardHeader = ({ title }: { title: string }) => (
  <View>
    <View style={styles.cardTitleContainer}>
      <View style={styles.cardTitleView}>
        <HylloText size="button" weight="regular" style={styles.cardTitle}>
          {title}
        </HylloText>
      </View>
      <View style={styles.emptySpace} />
    </View>
    <ListSeparator marginLeft={16} />
  </View>
);

export default CardHeader;
