import { StyleSheet, View } from 'react-native';
import ProfilePhoto from '../ProfilePhoto';
import HylloText from '../HylloText';
import { NeutralColor, displayAddress } from '../../utils';

const styles = StyleSheet.create({
  profileContainer: {
    marginBottom: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilePhotoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  userIdText: { color: NeutralColor.Gray06 },
});

const ProfileInfo = ({
  address,
  name,
  userId,
  photoStyle,
  containerStyle,
  showAddress = false,
  nameStyle,
}: {
  address: string;
  name: string;
  userId?: string;
  photoStyle?: any;
  containerStyle?: any;
  showAddress?: boolean;
  nameStyle?: any;
}) => {
  return (
    <View style={[styles.profileContainer, containerStyle]}>
      <View style={[styles.profilePhotoContainer, photoStyle]}>
        <ProfilePhoto address={address} size={100} initial={name?.[0]} />
      </View>
      <HylloText weight="semibold" style={nameStyle}>
        {name}
      </HylloText>
      <HylloText size="button" weight="regular" style={styles.userIdText}>
        {userId || address}
      </HylloText>
      {showAddress && (
        <HylloText size="button" weight="regular" style={styles.userIdText}>
          {displayAddress(address)}
        </HylloText>
      )}
    </View>
  );
};

export default ProfileInfo;
