import { generateMnemonic, validateMnemonic } from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';
import { generateWallet, resetWallet } from '../slices/wallet';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { createUser, fetchUser, resetUser } from '../slices/user';
import { useCallback } from 'react';
import useWalletConnect from './useWalletConnect';
import useLogoutUser from './useLogoutUser';
import useContactInfo from './useContactInfo';

const useWallet = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isConnected, provider } = useWalletConnect();
  const logoutUser = useLogoutUser();
  const { fetchContactInfo } = useContactInfo();

  const importWalletFromMnemonic = useCallback(
    async (mnemonic: string) => {
      const generateWalletAction = generateWallet(mnemonic);
      const address = generateWalletAction.payload.firstAddress;
      dispatch(generateWalletAction);
      const user = await dispatch(fetchUser(address)).unwrap();
      if (!user) {
        const newUser = await dispatch(createUser({ address })).unwrap();
        return { ...newUser, newUser: true };
      }
      if (address) {
        await fetchContactInfo(address);
      }

      return user;
    },
    [dispatch]
  );
  const importWallet = useCallback(async (mnemonic: string) => {
    const isValidMnemonic = validateMnemonic(mnemonic, wordlist);
    if (!isValidMnemonic) {
      throw Error(
        `Oops, something went wrong... We couldn't find your wallet with this secret recovery phrase. Please try again.`
      );
    }
    return importWalletFromMnemonic(mnemonic);
  }, []);

  const createWallet = useCallback(() => {
    const mnemonic = generateMnemonic(wordlist);
    return importWalletFromMnemonic(mnemonic);
  }, []);

  const disconnect = useCallback(() => {
    if (isConnected) {
      provider?.disconnect();
    }
    logoutUser();
  }, [isConnected, provider, dispatch]);

  return { importWallet, createWallet, disconnect };
};

export default useWallet;
