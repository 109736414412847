import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { fetchProfileImage, getProfileImage } from '../slices/images';

const useProfilePhoto = (address: string): string => {
  const dispatch = useDispatch<AppDispatch>();
  const profilePhoto = useSelector((state) => getProfileImage(state, address));

  useEffect(() => {
    if (address && !profilePhoto) {
      dispatch(fetchProfileImage(address));
    }
  }, [address]);

  return profilePhoto?.url;
};

export default useProfilePhoto;
