import { useRef } from 'react';
import { Platform, Pressable, StyleSheet, TextInput, View } from 'react-native';
import { ShadeColor } from '../utils';

const pinSpaces = Array.from({ length: 6 }, (_, i) => i);

const styles = StyleSheet.create({
  pinContainer: {
    flexDirection: 'row',
  },
  pinCircle: {
    width: 14,
    height: 14,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: ShadeColor.White,
  },
  pinFilled: {
    backgroundColor: ShadeColor.White,
  },
  input: {
    width: 0,
  },
});

interface PINProps {
  pinValue: string;
  onChangePin: (text: string) => void;
}

const PIN = ({ pinValue, onChangePin }: PINProps) => {
  const ref = useRef<TextInput>(null);
  return (
    <Pressable style={styles.pinContainer} onPress={() => ref.current?.focus()}>
      {pinSpaces.map((_, i) => (
        <View
          key={i}
          style={[
            styles.pinCircle,
            i !== 0 ? { marginLeft: 16 } : null,
            pinValue.length > i ? styles.pinFilled : null,
          ]}
        />
      ))}
      <TextInput
        ref={ref}
        style={styles.input}
        onChangeText={onChangePin}
        value={pinValue}
        keyboardType={Platform.OS === 'ios' ? 'numeric' : 'default'}
        keyboardAppearance="dark"
        autoFocus
      />
    </Pressable>
  );
};

export default PIN;
