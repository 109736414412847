import { Switch, SwitchProps } from 'react-native';
import { NeutralColor, PrimaryColor } from '../utils';

const HylloSwitch = (props: SwitchProps) => (
  <Switch
    trackColor={{
      false: NeutralColor.Gray08,
      true: PrimaryColor.Purple03,
    }}
    ios_backgroundColor="#3e3e3e"
    {...props}
  />
);

export default HylloSwitch;
