import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  LinearGradientColors,
  NeutralColor,
  ShadeColor,
} from '../../utils';
import HylloText from '../HylloText';

const PeaceSignImage = require('../../assets/peace-sign.png');

const styles = StyleSheet.create({
  emptyMessagesContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyImage: { height: 188, width: 188, marginBottom: 36 },
  emptyTitle: {
    color: ShadeColor.White,
    marginBottom: 12,
  },
  emptyText: {
    color: NeutralColor.Gray04,
  },
});

const EmptyMessages = () => (
  <LinearGradient
    colors={[ShadeColor.Black, LinearGradientColors.Purple]}
    start={{ x: 0.4, y: 0.7 }}
    end={{ x: 1.7, y: 1.4 }}
    style={styles.emptyMessagesContainer}
  >
    <Image source={PeaceSignImage} style={styles.emptyImage} />
    <HylloText size="h2" style={styles.emptyTitle}>
      No messages here yet
    </HylloText>
    <HylloText style={styles.emptyText}>
      It's a good time to say 'Hyllo!'
    </HylloText>
  </LinearGradient>
);

export default EmptyMessages;
