import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import Button from '../components/Button';
import { getAddress } from '../slices/user';
import { NeutralColor, ShadeColor, WarningColor } from '../utils/colors';
import { displayAddress } from '../utils/address';
import HylloText from '../components/HylloText';
import useWallet from '../hooks/useWallet';
import useWalletConnect from '../hooks/useWalletConnect';

const styles = StyleSheet.create({
  walletContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ShadeColor.Black,
    color: ShadeColor.White,
  },
  walletBody: {
    backgroundColor: NeutralColor.Gray10,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    width: '90%',
    margin: 12,
    padding: 12,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: ShadeColor.White,
  },
  error: {
    color: WarningColor.Red,
    marginTop: 12,
  },
  baseText: {
    color: ShadeColor.White,
  },
  walletButton: { marginBottom: 16 },
});

const Wallet = () => {
  const address = useSelector(getAddress);
  const { disconnect } = useWallet();
  const { open, isConnected, address: web3Address } = useWalletConnect();

  const isWalletConnect = isConnected && web3Address === address;

  return (
    <View style={styles.walletContainer}>
      <View style={styles.walletBody}>
        <HylloText size="h2" style={styles.baseText}>
          Your address
        </HylloText>
        <HylloText style={{ color: NeutralColor.Gray04, marginBottom: 16 }}>
          {displayAddress(address)}
        </HylloText>
        {isWalletConnect ? (
          <Button variant="primary" onPress={open}>
            View your account
          </Button>
        ) : (
          <Button
            variant="primary"
            onPress={disconnect}
          >
            Reset your address
          </Button>
        )}
      </View>
    </View>
  );
};

export default Wallet;
