import NewChatModal from './NewChatModal';
import NewContactModal from './NewContactModal';
import ContactModal from './ContactModal';
import NicknameModal from './NicknameModal';
import ProfileImageModal from './ProfilePhotoModal';
import VerifyPasscodeModal from './VerifyPasscodeModal';

interface IModals {
  [key: string]: any;
}

const Modals: IModals = {
  newChat: NewChatModal,
  newContact: NewContactModal,
  contact: ContactModal,
  nickname: NicknameModal,
  profilePhoto: ProfileImageModal,
  verifyPasscode: VerifyPasscodeModal,
};

export default Modals;
