import { createContext } from 'react';

export interface ContextMenuContextProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContextMenuContext = createContext<ContextMenuContextProps>({
  isOpen: false,
  onClose: () => {},
});

export default ContextMenuContext;
