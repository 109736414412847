import { EventEmitter, Subscription } from 'expo-modules-core';
// Import the native module. On web, it will be resolved to MyModule.web.ts
// and on native platforms to MyModule.ts
import ClientModule from './src/ClientModule';

console.log('ClientModule', ClientModule);

export async function connectClient(address: string): Promise<string> {
  return await ClientModule.connect(address);
}

export function getConnectStatus(): {
  dCdn: boolean;
  relayer: boolean;
} {
  return ClientModule.getConnectStatus();
}

export async function getClient(key: string, skipDecoding = false) {
  const response = await ClientModule.get(key, skipDecoding);
  if (skipDecoding) {
    return response;
  }
  if (response?.toLowerCase() === 'key does not exist') {
    return null;
  }
  try {
    return JSON.parse(response);
  } catch {
    return response;
  }
}

export async function getImageClient(key: string) {
  const response = await ClientModule.getImage(key);
  if (response?.toLowerCase() === 'key does not exist') {
    return null;
  }

  const dataResponse = `data:image/jpeg;base64,${response}`;
  return dataResponse;
}

export async function putClient(
  key: string,
  value: string | number | object | boolean | Uint8Array,
  skipEncoding = false
) {
  let response;
  if (skipEncoding) {
    response = await ClientModule.put(key, value, skipEncoding);
  } else {
    response = await ClientModule.put(key, JSON.stringify(value));
  }
  if (response === 'DB error') throw new Error(`DB had trouble putting ${key}`);
  return response;
}

export async function putImageClient(key: string, urlString: string) {
  const response = await ClientModule.putImage(key, urlString);
  if (response === 'DB error') throw new Error(`DB had trouble putting ${key}`);
  return response;
}

export async function deleteClient(key: string) {
  const response = await ClientModule.delet(key);
  if (response === 'DB error') throw new Error(`DB had trouble putting ${key}`);
  return response;
}

export async function connectRelayer(address: string): Promise<string> {
  return await ClientModule.connectRelayer(address);
}

export async function sendMessage(address: string, body: string): Promise<string> {
  console.log('sendMessage', address, body);
  return await ClientModule.sendMessage(address, body);
}


const emitter = new EventEmitter(ClientModule);

export function addMessageListener(listener: (event: any) => void): Subscription {
  return emitter.addListener('onReceiveMessage', listener);
}
