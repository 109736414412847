import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Image,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  NeutralColor,
  ShadeColor,
  WarningColor,
  textSizes,
  textWeights,
} from '../utils';
import useWallet from '../hooks/useWallet';
import useOnboardingNavigate from '../hooks/useOnboardingNavigate';
import AnimatedTyping from '../components/AnimatedTyping';
import BlinkingCursor from '../components/BlinkingCursor';
import Button from '../components/Button';
import { useSelector } from 'react-redux';
import { getUser } from '../slices/user';

const LogoLockImage = require('../assets/logo-lock.png');
const BackgroundImage = require('../assets/background-image-face.png');

const styles = StyleSheet.create({
  createWalletContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 32,
    paddingTop: 130,
  },
  createWalletImage: {
    width: 240,
    height: 133,
    marginBottom: 16,
  },
  createWalletTextContainer: {
    textAlign: 'left',
    maxWidth: 520,
    width: '100%',
    maxHeight: 252,
  },
  createWalletTitle: {
    marginBottom: 70,
  },
  createWalletButton: {
    paddingVertical: 16,
  },
  createWalletButtonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
  error: {
    color: WarningColor.Red,
  },
  buttonContainer: {
    flex: 1,
    zIndex: 1,
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: 520,
    marginBottom: 52,
  },
});

const introText = [
  { text: 'Crafting your unique wallet...', delay: 0 },
  {
    text: 'You are stepping into a realm of true data ownership,\nunmatched privacy,\nand full control.',
    delay: 1000,
  },
  { text: 'Welcome to your digital freedom!', delay: 2000 },
];

const findTextColor = (index: number, cursorIndex: number) => {
  if (index === cursorIndex || index === 2) {
    return ShadeColor.White;
  }
  if (index + 1 === cursorIndex || index === 1) {
    return NeutralColor.Gray05;
  }
  return NeutralColor.Gray07;
};

const CreateWallet = () => {
  const [cursorIndex, setCursorIndex] = useState(0);
  const { createWallet } = useWallet();
  const onboardingNavigate = useOnboardingNavigate();
  const scrollRef = useRef<ScrollView>(null);
  const user = useSelector(getUser);
  const handleCreate = async () => {
    try {
      await createWallet();
    } catch (e: any) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (cursorIndex === 0) {
      handleCreate();
    }
  }, [])

  const handlePress = useCallback(() => {
    onboardingNavigate(user);
  }, [user]);

  return (
    <ImageBackground
      source={BackgroundImage}
      style={styles.createWalletContainer}
      resizeMode="cover"
    >
      <SafeAreaView>
        <Image source={LogoLockImage} style={styles.createWalletImage} />
      </SafeAreaView>
      <ScrollView
        ref={scrollRef}
        style={styles.createWalletTextContainer}
        onContentSizeChange={() => scrollRef.current?.scrollToEnd()}
        scrollEnabled={false}
      >
        {introText.map((text, index) => {
          return cursorIndex >= index ? (
            <AnimatedTyping
              key={`animated-typing-${index}`}
              text={text.text}
              size="h2"
              onFinish={() => setCursorIndex(index + 1)}
              delayStart={text.delay}
              showCursor={cursorIndex === index}
              style={{ marginBottom: 16 }}
              textStyle={{
                color: findTextColor(index, cursorIndex),
              }}
            />
          ) : (
            <View key={`animated-typing-${index}`} />
          );
        })}
        {cursorIndex === introText.length && (
          <BlinkingCursor key="cursor" size="h2" />
        )}
      </ScrollView>
      <View style={styles.buttonContainer}>
        <Button
          variant="primary"
          onPress={handlePress}
          disabled={!user || cursorIndex < 2}
          fullWidth
          style={styles.createWalletButton}
          textStyle={styles.createWalletButtonText}
        >
          Next
        </Button>
      </View>
    </ImageBackground>
  );
};

export default CreateWallet;
