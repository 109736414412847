import React, { useCallback, useState } from 'react';
import {
  ImageBackground,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import * as LocalAuthentication from 'expo-local-authentication';
import HylloText from '../components/HylloText';
import { NeutralColor, ShadeColor, WarningColor, textSizes, textWeights } from '../utils';
import Button from '../components/Button';
import { Screens } from '../navigator/screens';

const BackgroundImage = require('../assets/background-image-face.png');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  paddingHor: {
    flex: 1,
    paddingHorizontal: 24,
  },
  titleText: {
    marginTop: 60,
  },
  secondTitle: {
    marginBottom: 36,
  },
  descriptionText: {
    color: NeutralColor.Gray04,
  },
  textSpacing: {
    marginBottom: 24,
  },
  error: {
    color: WarningColor.Red,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: 100,
  },
  button: {
    paddingVertical: 16,
    width: 150,
  },
});

const FaceID = ({ navigation }: { navigation: any }) => {
  const [error, setError] = useState('');
  const handleFaceID = useCallback(async () => {
    const hasHardware = await LocalAuthentication.hasHardwareAsync();
    if (hasHardware) {
      const isEnrolled = await LocalAuthentication.isEnrolledAsync();
      if (isEnrolled) {
        const response = await LocalAuthentication.authenticateAsync({
          promptMessage: 'Log in with Face ID',
          cancelLabel: 'Cancel',
        });
        if (response.success) {
          navigation.navigate(Screens.WalletSuccess);
        } else {
          setError(response.error);
        }
      }
    }
  }, [setError]);

  const faceIdTitle = Platform.OS === 'ios' ? 'Face ID' : 'Face Recognition';

  return (
    <ImageBackground
      source={BackgroundImage}
      style={styles.container}
      resizeMode="cover"
    >
      <View style={styles.paddingHor}>
        <SafeAreaView>
          <HylloText size="h2" style={styles.titleText}>
            Want to turn on
          </HylloText>
        </SafeAreaView>
        <HylloText size="h2" style={styles.secondTitle}>
          Face ID?
        </HylloText>
        <HylloText style={[styles.descriptionText, styles.textSpacing]}>
          With {faceIdTitle}, your face is your PIN. Face ID uses a detailed
          depth map of your face to recognize you so you don&#8217;t have to
          enter the PIN manually each time.
        </HylloText>
        <HylloText style={[styles.descriptionText, styles.textSpacing]}>
          All of your biometric data is stored locally on your phone. Hyllo
          doesn't have access to it nor send it to an external data source.
        </HylloText>
        <HylloText style={styles.descriptionText}>
          If you don&#8217;t want to enable it now, you can always enable it
          later in your account settings.
        </HylloText>
        {error && <HylloText style={styles.error}>{error}</HylloText>}
        <View style={styles.buttonContainer}>
          <Button
            variant="secondary"
            style={styles.button}
            textStyle={{ ...textSizes.body, ...textWeights.bold }}
            onPress={() => navigation.navigate(Screens.WalletSuccess)}
            fullWidth
          >
            Not Now
          </Button>
          <Button
            variant="primary"
            style={styles.button}
            textStyle={{ ...textSizes.body, ...textWeights.bold }}
            onPress={handleFaceID}
            fullWidth
          >
            Turn On
          </Button>
        </View>
      </View>
    </ImageBackground>
  );
};

export default FaceID;
