import { getClient, putClient } from '../modules/client';
import { User } from '../utils';

export const fetchUserDB = async (address: string | null): Promise<User> => {
  if (!address) return {} as User;
  return getClient(`users/${address}`);
};

export const createUserDB = async (user: any): Promise<User> => {
  const modifiedUser = {
    ...user,
    created: Date.now(),
  };
  await putClient(`user/${user.address}`, user);
  return modifiedUser;
};

export const updateUserDB = async (user: any): Promise<User> => {
  const modifiedUser = {
    ...user,
    updated: Date.now(),
  };
  await putClient(`users/${user.address}`, modifiedUser);
  return modifiedUser;
};

export const fetchUserIdDB = async (userId: string): Promise<string> =>
  getClient(`userIds/${userId}`);

export const setUserIdDB = async ({
  userId,
  address,
}: {
  userId: string;
  address: string;
}): Promise<string> => {
  await putClient(`userIds/${userId}`, address);
  return address;
};
