import React, { useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  SafeAreaView,
} from 'react-native';
import { NeutralColor, ShadeColor, WarningColor } from '../../utils';
import HylloText from '../HylloText';
import PIN from '../PIN';
import { getItemAsync } from 'expo-secure-store';

const styles = StyleSheet.create({
  passcodeContainer: {
    marginTop: 48,
    flex: 1,
    alignItems: 'center',
  },
  passcodeHeader: {
    marginVertical: 24,
  },
  passcodeDescription: {
    color: NeutralColor.Gray04,
    marginBottom: 132,
  },
  error: {
    color: WarningColor.Red,
    marginBottom: 132,
  },
});

const VerifyPasscodeModal = ({ onClose }: { onClose: () => void }) => {
  const [error, setError] = useState('');
  const [pin, setPin] = useState('');
  const [actualPin, setActualPin] = useState('');

  useEffect(() => {
    const getPin = async () => {
      const pinFromStore = await getItemAsync('pin');
      if (pinFromStore) {
        setActualPin(pinFromStore);
      }
    };
    getPin();
  }, []);

  const handleChangePin = (text: string) => {
    setPin(text);
    if (text.length === 6) {
      if (text !== actualPin) {
        setError('You entered the wrong pin. Please try again.');
        setPin('');
      } else {
        onClose();
      }
    }
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: ShadeColor.Black }}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.passcodeContainer}
      >
        <HylloText size="h1" style={styles.passcodeHeader}>
          Enter Your PIN
        </HylloText>
        {error ? (
          <HylloText style={styles.error}>{error}</HylloText>
        ) : (
          <HylloText style={styles.passcodeDescription}>
            So we can confirm your identity.
          </HylloText>
        )}
        <PIN pinValue={pin} onChangePin={handleChangePin} />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default VerifyPasscodeModal;
