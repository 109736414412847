import { Web3Modal } from '@web3modal/react';
import { ethereumClient, projectId } from '../utils/constants';

export const Web3ModalWeb = () => (
  <Web3Modal
    projectId={projectId}
    ethereumClient={ethereumClient}
    themeMode="dark"
  />
);

export default Web3ModalWeb;
