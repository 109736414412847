import React, { ReactNode } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { NeutralColor, PrimaryColor } from '../../utils';
import HylloText from '../HylloText';

const styles = StyleSheet.create({
  tabContainer: {
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  tabView: {
    flexDirection: 'column',
    position: 'relative',
    paddingVertical: 16,
  },
  tabText: {
    color: NeutralColor.Gray05,
  },
  tabTextActive: {
    color: PrimaryColor.Purple03,
  },
  bottomBorder: {
    position: 'absolute',
    bottom: 0,
    height: 2,
    width: '100%',
    backgroundColor: PrimaryColor.Purple03,
    borderRadius: 2,
  },
});

const Tab = ({
  onPress,
  children,
  isActive,
  textStyle,
}: {
  onPress: () => void;
  children: ReactNode;
  isActive?: boolean;
  textStyle?: any;
}) => (
  <Pressable style={styles.tabContainer} onPress={onPress}>
    <View style={styles.tabView}>
      <HylloText
        size="button"
        weight="semibold"
        style={[styles.tabText, isActive && styles.tabTextActive, textStyle]}
      >
        {children}
      </HylloText>
      {isActive && <View style={styles.bottomBorder} />}
    </View>
  </Pressable>
);

export default Tab;
