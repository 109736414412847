import React, { useCallback } from 'react';
import ContactsListItem, { ContactsListItemProps } from './ContactsListItem';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { Animated, StyleSheet } from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import { PrimaryColor, ShadeColor, WarningColor } from '../../utils';
import { RectButton } from 'react-native-gesture-handler';
import { AppDispatch } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { deleteContact } from '../../slices/contacts';
import { getAddress } from '../../slices/user';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Screens, StackParamList } from '../../navigator/screens';
import { defaultActionSheetOptions } from '../../utils/action-sheet';

const styles = StyleSheet.create({
  rightActionButton: { width: 64 },
  rightActionContainer: {
    backgroundColor: WarningColor.Red,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightActionIcon: { color: ShadeColor.White },
});

interface SwipeableContactsListItemProps extends ContactsListItemProps {
  onSwipeOpen?: (ref: Swipeable) => void;
  onSwipeClose?: (ref: Swipeable) => void;
}

const SwipeableContactsListItem = (props: SwipeableContactsListItemProps) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const navigation =
    useNavigation<
      NativeStackNavigationProp<StackParamList, Screens.UserProfile>
    >();
  const address = useSelector(getAddress);
  const dispatch = useDispatch<AppDispatch>();
  const handleDeletePress = useCallback(
    (closeCb: () => void) => {
      const options = ['Delete', 'Cancel'];
      const destructiveButtonIndex = 0;
      const cancelButtonIndex = 2;
      const title = 'Delete this contact?';
      showActionSheetWithOptions(
        {
          ...defaultActionSheetOptions,
          options,
          destructiveButtonIndex,
          cancelButtonIndex,
          title,
        },
        (buttonIndex) => {
          if (buttonIndex === 0) {
            dispatch(
              deleteContact({
                userAddress: address,
                contactAddress: props.address,
              })
            );
          }
        }
      );
      closeCb();
    },
    [address, dispatch, showActionSheetWithOptions]
  );
  const handleEditPress = (closeCb: () => void) => {
    navigation.navigate(Screens.UserProfile, { address: props.address });
    closeCb();
  };
  const renderRightAction = (
    progress: Animated.AnimatedInterpolation<string | number>,
    _: any,
    componentRef: Swipeable
  ) => {
    const trans = progress.interpolate({
      inputRange: [0, 1],
      outputRange: [128, 0],
    });
    return (
      <Animated.View style={{ flexDirection: 'row', width: 128 }}>
        <RectButton
          style={styles.rightActionButton}
          onPress={() => handleEditPress(() => componentRef.close())}
        >
          <Animated.View
            style={[
              styles.rightActionContainer,
              { backgroundColor: PrimaryColor.PrimaryPurple },
              { transform: [{ translateX: trans }] },
            ]}
          >
            <Feather name="edit" style={styles.rightActionIcon} size={24} />
          </Animated.View>
        </RectButton>
        <RectButton
          style={styles.rightActionButton}
          onPress={() => handleDeletePress(() => componentRef.close())}
        >
          <Animated.View
            style={[
              styles.rightActionContainer,
              { transform: [{ translateX: trans }] },
            ]}
          >
            <Feather name="trash" style={styles.rightActionIcon} size={24} />
          </Animated.View>
        </RectButton>
      </Animated.View>
    );
  };
  return (
    <Swipeable
      renderRightActions={renderRightAction}
      rightThreshold={41}
      friction={2}
      onSwipeableOpen={(_, ref) => props.onSwipeOpen?.(ref)}
      onSwipeableClose={(_, ref) => props.onSwipeClose?.(ref)}
    >
      <ContactsListItem {...props} />
    </Swipeable>
  );
};

export default SwipeableContactsListItem;
