import React, { useState } from 'react';
import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Feather from '@expo/vector-icons/Feather';
import {
  NeutralColor,
  ShadeColor,
  WarningColor,
  textSizes,
  textWeights,
} from '../../utils';
import ModalHeader from './ModalHeader';
import { getAddress, getUser, updateUser } from '../../slices/user';
import Button from '../Button';
import { AppDispatch } from '../../redux/store';
import HylloText from '../HylloText';
import Input from '../Input';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  modalBody: {
    flex: 1,
    alignItems: 'center',
    padding: 24,
  },
  modalInput: {
    ...textSizes.inputBody,
    color: ShadeColor.White,
    marginVertical: 12,
    padding: 12,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: ShadeColor.White,
    width: '90%',
    maxWidth: 500,
  },
  modalError: {
    color: WarningColor.Red,
  },
  nicknameCaption: {
    color: NeutralColor.Gray04,
  },
  captionContainer: { padding: 8, width: '100%' },
  firstText: { marginBottom: 12 },
  errorIcon: { marginRight: 6 },
  errorContainer: {
    marginTop: 12,
    flexDirection: 'row',
  },
});

const NicknameModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const address = useSelector(getAddress);
  const [nameInput, setNameInput] = useState(user.name || '');
  const [error, setError] = useState('');
  const handleButtonPress = async () => {
    if (!address) {
      setError('Please connect your wallet!');
      return;
    }
    if (nameInput.length > 32) {
      setError('Name exceeds the maximum length of 32 characters and symbols.');
      return;
    }
    if (nameInput) {
      await dispatch(updateUser({ address, name: nameInput }));
      onClose();
    } else {
      setError('User name cannot be empty');
    }
  };
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== 'web') {
          Keyboard.dismiss();
        }
      }}
      accessible={false}
    >
      <View style={styles.modalContainer}>
        <ModalHeader
          onClose={onClose}
          title="Set User Name"
          titleStyle={{ ...textSizes.body, ...textWeights.bold }}
          rightButton={
            <Button
              variant="link"
              onPress={handleButtonPress}
              textStyle={{ ...textSizes.body, ...textWeights.bold }}
            >
              Done
            </Button>
          }
        />
        <View style={styles.modalBody}>
          <Input
            variant="secondary"
            onChangeText={setNameInput}
            value={nameInput}
            placeholder="Type your user name"
            placeholderTextColor={NeutralColor.Gray04}
            keyboardAppearance="dark"
            showCancel={!!nameInput}
            onCancel={() => setNameInput('')}
          />
          {!!error ? (
            <View style={styles.errorContainer}>
              <Feather
                name="alert-circle"
                color={WarningColor.Red}
                style={styles.errorIcon}
                size={20}
              />
              <HylloText style={styles.modalError}>{error}</HylloText>
            </View>
          ) : (
            <View style={styles.captionContainer}>
              <HylloText
                size="caption"
                style={[styles.nicknameCaption, styles.firstText]}
              >
                You can set a nickname for your account so that others can
                easily recognize you.
              </HylloText>

              <HylloText size="caption" style={styles.nicknameCaption}>
                Your nickname can contain up to 20 alphanumeric characters and
                symbols. Please note that emojis and certain special symbols may
                not be accepted.
              </HylloText>
            </View>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default NicknameModal;
