import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Modal, Pressable, StyleSheet, View } from 'react-native';
import { NeutralColor, ShadeColor } from '../utils';
import { get } from 'lodash';
import getTooltipCoordinate from '../utils/getTooltipCoordinate';
import ContextMenuContext from './ContextMenu/ContextMenuContext';

interface TooltipProps {
  children: ReactNode;
  popover: ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  width?: number;
  height?: number;
  style?: any;
  position?: 'bottom';
}

const styles = StyleSheet.create({
  tooltip: {
    position: 'absolute',
    backgroundColor: ShadeColor.Black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flex: 1,
    borderRadius: 10,
    padding: 10,
    borderColor: NeutralColor.Gray06,
    borderWidth: 1,
  },
});

const Tooltip = ({
  children,
  onClose,
  onOpen,
  popover,
  width = 150,
  height = 40,
  style,
  position,
}: TooltipProps) => {
  const containerRef = useRef<View>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [elementPosition, setElementPosition] = useState({
    xOffset: 0,
    yOffset: 0,
    elementWidth: 0,
    elementHeight: 0,
  });

  const toggleTooltip = () => {
    setIsVisible(!isVisible);
    getElementPosition();
    onClose && onClose();
  };

  const getElementPosition = () => {
    if (containerRef.current) {
      containerRef.current.measureInWindow(
        (
          pageOffsetX: number,
          pageOffsetY: number,
          width: number,
          height: number
        ) => {
          setElementPosition({
            xOffset: pageOffsetX,
            yOffset: pageOffsetY,
            elementWidth: width,
            elementHeight: height,
          });
        }
      );
    }
  };

  useEffect(() => {
    getElementPosition();
  }, []);

  const { xOffset, yOffset, elementWidth, elementHeight } = elementPosition;

  const { x, y } = getTooltipCoordinate(
    xOffset,
    yOffset,
    elementWidth,
    elementHeight,
    width,
    false
  );

  const pastMiddleline = yOffset > y;

  return (
    <ContextMenuContext.Provider
      value={{ isOpen: isVisible, onClose: toggleTooltip }}
    >
      <View collapsable={false} ref={containerRef}>
        <Pressable onPress={toggleTooltip}>{children}</Pressable>
        <Modal
          animationType="fade"
          visible={isVisible}
          transparent
          onDismiss={onClose}
          onShow={onOpen}
          onRequestClose={onClose}
        >
          <Pressable
            style={{
              flex: 1,
            }}
            onPress={toggleTooltip}
          >
            <View
              style={[
                styles.tooltip,
                {
                  left: position === 'bottom' ? 0 : x,
                  top: pastMiddleline ? y - height : y,
                  width,
                  height,
                },
                style,
              ]}
            >
              {popover}
            </View>
          </Pressable>
        </Modal>
      </View>
    </ContextMenuContext.Provider>
  );
};

export default Tooltip;
