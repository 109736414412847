import { v4 as uuidV4 } from 'uuid';
import { Chat, Message } from '../utils';
import { deleteClient, getClient, putClient } from '../modules/client';

export const addUserChat = async (address: string, chatId: string) => {
  const userChats = await getClient(`users/${address}/chats`);
  const modifiedUserChats = userChats ? userChats.concat(chatId) : [chatId];
  await putClient(`users/${address}/chats`, modifiedUserChats);
};

export const addChatDB = async (chat: any): Promise<Chat> => {
  const modifiedChat = {
    ...chat,
    created: Date.now(),
  };
  await putClient(`chats/${modifiedChat.id}`, modifiedChat);

  // update both userChats
  const addresses = modifiedChat.id.split('-');
  addresses.forEach((address: string) => addUserChat(address, modifiedChat.id));

  // update chat messages
  await putClient(`chats/${modifiedChat.id}/messages`, []);

  return { ...modifiedChat, addresses, messages: [] };
};

export const fetchChatsByUserDB = async (address: string): Promise<Chat[]> => {
  const chatIds = await getClient(`users/${address}/chats`);
  if (!chatIds) return [];
  const chats = await Promise.all(
    chatIds.map(async (chatId: string) => {
      const chatData = await getClient(`chats/${chatId}`);
      const addresses = chatData.id.split('-');
      const messageIds = await getClient(`chats/${chatId}/messages`);
      const messages = messageIds
        ? await Promise.all(
            messageIds.map(async (id: string) => {
              const message = await getClient(`messages/${id}`);
              const readReceipts = (
                await Promise.all(
                  addresses.map((addy: string) =>
                    getClient(`messages/${id}/readReceipts/${addy}`)
                  )
                )
              ).reduce(
                (acc: any, curr: any, i: number) => ({
                  ...acc,
                  [addresses[i]]: curr,
                }),
                {}
              );
              return {
                ...message,
                readReceipts,
              };
            })
          )
        : [];
      return {
        ...chatData,
        addresses,
        messages,
      };
    })
  );
  return chats;
};

export const addMessageDB = async (message: any): Promise<Message> => {
  const id = uuidV4();
  const modifiedMessage = {
    ...message,
    id,
    created: Date.now(),
  };
  await putClient(`messages/${modifiedMessage.id}`, modifiedMessage);
  return modifiedMessage;
};

export const updateChatMessagesDB = async (
  chatId: string,
  chatMessages: string[]
): Promise<string[]> => {
  await putClient(`chats/${chatId}/messages`, chatMessages);
  return chatMessages;
};

export const sendReadReceiptDB = async ({
  chatId,
  messageId,
  address,
}: {
  chatId: string;
  messageId: string;
  address: string;
}): Promise<any> => {
  const readReceiptTime = Date.now();
  await putClient(
    `messages/${messageId}/readReceipts/${address}`,
    readReceiptTime
  );
  return {
    time: readReceiptTime,
    chatId,
    messageId,
    address,
  };
};

const deleteUserChat = async (address: string, chatId: string) => {
  const userChat = await getClient(`users/${address}/chats`);
  const modifiedUserChats = userChat.filter((id: string) => id !== chatId);
  await putClient(`users/${address}/chats`, modifiedUserChats);
};

export const deleteChatDB = async (chatId: string): Promise<any> => {
  await deleteClient(`chats/${chatId}`);

  const addresses = chatId.split('-');
  addresses.forEach((address: string) => deleteUserChat(address, chatId));

  return chatId;
};
