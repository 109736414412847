import { EventEmitter } from 'expo-modules-core';
import init, { DCdnClient } from '../d-cdn-pkg';
import relayerInit, {
  RelayerClient,
} from '../relayer-pkg';
import { decodeData, encodeData } from './text-encoding';

const serverUrl = '34.213.133.230';
const dCdnPort = '9000';
const relayerPort = '9002';

enum ClientStatus {
  Disconnected,
  Connecting,
  Connected,
}

const createIterator = (client: RelayerClient) => {
  return {
    next: async () => {
      const response = await client.recv();
      const body = decodeData(response.body);
      return {
        done: false,
        value: {
          src: response.src,
          body,
        },
      };
    },
    [Symbol.asyncIterator]() {
      return this;
    },
  };
};

let dCdnClient: DCdnClient;
let dCdnClientStatus = ClientStatus.Disconnected;
let relayerClient: RelayerClient;
let relayerClientStatus = ClientStatus.Disconnected;
const emitter = new EventEmitter({} as any);

const sleep = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

export const relayerConnect = async (address: string): Promise<any> => {
  if (relayerClientStatus === ClientStatus.Connected) return;
  if (relayerClientStatus === ClientStatus.Connecting) {
    await sleep(50);
    return dCdnConnect(address);
  }
  relayerClientStatus = ClientStatus.Connecting;
  await relayerInit();
  relayerClient = await RelayerClient.new(address, '127.0.0.1:10012');
  await relayerClient.connect('relayer', `${serverUrl}:${relayerPort}`);
  relayerClientStatus = ClientStatus.Connected;

  listenForMessages();

  return relayerClient;
};

export const dCdnConnect = async (address: string): Promise<any> => {
  if (dCdnClientStatus === ClientStatus.Connected) return;
  if (dCdnClientStatus === ClientStatus.Connecting) {
    await sleep(50);
    return dCdnConnect(address);
  }
  dCdnClientStatus = ClientStatus.Connecting;
  await init();
  dCdnClient = await DCdnClient.connect(
    '127.0.0.1:10011',
    `${serverUrl}:${dCdnPort}`
  );
  dCdnClientStatus = ClientStatus.Connected;

  return dCdnClient;
};

export const connect = async (address: string): Promise<any> => {
  await dCdnConnect(address);
  await relayerConnect(address);
  return;
};

export const get = async (key: string, skipDecoding = false) => {
  if (dCdnClientStatus !== ClientStatus.Connected) return;
  const data = await dCdnClient.get(key);
  if (typeof data === 'string' && data.toLowerCase() === 'key does not exist')
    return null;
  if (skipDecoding) return data;
  return decodeData(data);
};

export const put = async (key: string, value: any, skipEncoding = false) => {
  if (dCdnClientStatus !== ClientStatus.Connected) return;
  const encodedData = skipEncoding ? value : encodeData(value);
  const response = await dCdnClient.put(key, encodedData);
  if (response === 'DB error') throw new Error(`DB had trouble putting ${key}`);
  return response;
};

export const delet = async (key: string) => {
  if (dCdnClientStatus !== ClientStatus.Connected) return;
  const response = await dCdnClient.delete(key);
  if (response === 'DB error')
    throw new Error(`DB had trouble deleting ${key}`);
  return response;
};

export const sendMessage = async (src: string, message: string) => {
  if (dCdnClientStatus !== ClientStatus.Connected) return;
  const response = await relayerClient.send(src, encodeData(message));
  // if (response === 'DB error')
  //   throw new Error(`DB had trouble sending message ${message}`);
  return response;
};

export const listenForMessages = () => {
  const iterator = createIterator(relayerClient);
  return new Promise(async () => {
    for await (const message of iterator) {
      emitter.emit('message', message);
    }
  });
};

export default {
  connect,
  get,
  put,
  delet,
  sendMessage,
};
