import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const QRCodeIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}: any) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <G stroke={color}>
      <Path d="M13.5 15.833V13.5h2.333v2.333H13.5zM10.833 12.5v-1.667H12.5v1.666h-1.667zM16.834 18.5v-1.666h1.667V18.5h-1.667zM17.5 11.833v-1h1v1h-1zM10.833 18.5v-1h1v1h-1zM13.5 5.833V3.5h2.333v2.333H13.5z" />
      <Path
        d="M17.666 1h-6a.667.667 0 00-.666.667v6c0 .368.299.666.667.666h6a.667.667 0 00.666-.667v-6A.667.667 0 0017.667 1z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M3.5 5.833V3.5h2.333v2.333H3.5z" />
      <Path
        d="M7.666 1h-6A.667.667 0 001 1.667v6c0 .368.298.666.667.666h6a.667.667 0 00.666-.667v-6A.667.667 0 007.666 1z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M3.5 15.833V13.5h2.333v2.333H3.5z" />
      <Path
        d="M7.666 11h-6a.667.667 0 00-.666.666v6c0 .368.298.667.667.667h6a.667.667 0 00.666-.667v-6A.667.667 0 007.666 11z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

export default QRCodeIcon;
