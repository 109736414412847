import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const CPUIcon = ({ size = 24, color = 'currentColor', ...otherProps }: any) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <Path
      d="M8.25 1.833v1.834m5.5-1.834v1.834m-5.5 14.666v1.834m5.5-1.834v1.834M18.333 8.25h1.833m-1.833 4.583h1.833M1.833 8.25h1.833m-1.833 4.583h1.833m4.4 5.5h5.867c1.54 0 2.31 0 2.898-.3a2.751 2.751 0 001.202-1.201c.3-.588.3-1.359.3-2.899V8.067c0-1.54 0-2.31-.3-2.899a2.75 2.75 0 00-1.202-1.202c-.588-.3-1.358-.3-2.898-.3H8.066c-1.54 0-2.31 0-2.898.3a2.75 2.75 0 00-1.202 1.202c-.3.588-.3 1.359-.3 2.899v5.866c0 1.54 0 2.31.3 2.899a2.75 2.75 0 001.202 1.202c.588.3 1.358.3 2.898.3zm1.65-4.583h2.567c.513 0 .77 0 .966-.1a.917.917 0 00.4-.4c.1-.197.1-.453.1-.967V9.717c0-.514 0-.77-.1-.966a.917.917 0 00-.4-.401c-.196-.1-.453-.1-.966-.1H9.716c-.513 0-.77 0-.966.1a.917.917 0 00-.4.4c-.1.197-.1.453-.1.967v2.566c0 .514 0 .77.1.966a.917.917 0 00.4.401c.196.1.453.1.966.1z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CPUIcon;
