import React from 'react';
import Svg, { Path } from 'react-native-svg';

const BellOffIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}: any) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...otherProps}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.707 2.293a1 1 0 00-1.414 1.414l2.856 2.856C5.051 7.033 5 7.513 5 8c0 2.913-.732 4.844-1.5 6.077l-.011.02c-.358.573-.64 1.027-.83 1.358a5.66 5.66 0 00-.247.467c-.032.07-.068.157-.096.251a1.225 1.225 0 00-.051.45c.01.136.039.37.173.611.134.242.318.39.428.47.25.186.538.229.634.243h.004c.147.023.315.034.484.04.335.013.799.013 1.376.013h11.222l3.707 3.707a1 1 0 001.414-1.414L6.997 5.583a.996.996 0 00-.066-.066L3.707 2.293z"
      fill={color}
    />
    <Path
      d="M8.604 20.338a1 1 0 011.412-.088c.53.468 1.222.75 1.984.75.761 0 1.455-.282 1.984-.75a1 1 0 111.323 1.5A4.985 4.985 0 0112 23a4.985 4.985 0 01-3.308-1.25 1 1 0 01-.088-1.412zM21.53 17.288a1.48 1.48 0 00.205-.666 1.224 1.224 0 00-.05-.449 1.988 1.988 0 00-.097-.251 5.677 5.677 0 00-.247-.467c-.19-.33-.472-.784-.83-1.358l-.012-.02C19.732 12.844 19 10.913 19 8A7 7 0 007.173 2.93l14.358 14.358z"
      fill={color}
    />
  </Svg>
);

export default BellOffIcon;
