import React, { useCallback, useState } from 'react';
import {
  Keyboard,
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import Feather from '@expo/vector-icons/Feather';
import { AppDispatch } from '../../redux/store';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
} from '../../utils/colors';
import { textSizes } from '../../utils/text';
import ContactsList from '../Contacts/ContactsList';
import { openModal } from '../../slices/modals';
import ModalHeader from './ModalHeader';
import useOpenChat from '../../hooks/useOpenChat';
import HylloText from '../HylloText';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  searchInputContainer: {
    flexDirection: 'row',
    backgroundColor: NeutralColor.Gray10,
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: NeutralColor.Gray09,
  },
  searchInput: {
    ...textSizes.inputBody,
    marginLeft: 16,
    color: ShadeColor.White,
    flex: 1,
  },
  contactButton: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: NeutralColor.Gray10,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: NeutralColor.Gray09,
  },
  contactButtonTextContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactButtonText: {
    color: PrimaryColor.PrimaryPurple,
    marginLeft: 16,
  },
  contactsListContainer: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
});

const NewChatModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch: AppDispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const handleOpenChat = useOpenChat();

  const handleContactPress = useCallback(
    (contactAddress: string) => {
      handleOpenChat(contactAddress);
      onClose();
    },
    [handleOpenChat]
  );

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== 'web') {
          Keyboard.dismiss();
        }
      }}
      accessible={false}
    >
      <View style={styles.modalContainer}>
        <ModalHeader onClose={onClose} title="New Message" />

        <ContactsList
          containerStyle={styles.contactsListContainer}
          condensed
          onContactPress={handleContactPress}
          filterInput={searchInput}
          ListHeaderComponent={
            <>
              <View style={styles.searchInputContainer}>
                <Feather name="search" size={20} color={NeutralColor.Gray04} />
                <TextInput
                  placeholder="Search"
                  placeholderTextColor={NeutralColor.Gray04}
                  style={styles.searchInput}
                  value={searchInput}
                  onChangeText={setSearchInput}
                  keyboardAppearance="dark"
                />
              </View>
              <Pressable
                style={styles.contactButton}
                onPress={() => {
                  dispatch(openModal({ name: 'newContact' }));
                }}
              >
                <View style={styles.contactButtonTextContainer}>
                  <Feather
                    name="user-plus"
                    size={20}
                    color={PrimaryColor.PrimaryPurple}
                  />
                  <HylloText style={styles.contactButtonText}>
                    New Contact
                  </HylloText>
                </View>
                <Feather
                  name="chevron-right"
                  size={20}
                  color={NeutralColor.Gray04}
                />
              </Pressable>
            </>
          }
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default NewChatModal;
