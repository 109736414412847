import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { createUserDB, fetchUserDB, setUserIdDB, updateUserDB } from '../services/users';
import { ResponseStatus, User } from '../utils';
import { getDownloadURLForAddressDB } from '../services/images';
import { provider } from '../utils/constants';

const fetchUserConstant = 'fetchUser';
const createUserConstant = 'createUser';
const updateUserConstant = 'updateUser';
const fetchProfilePhotoConstant = 'fetchProfilePhoto';

interface UserSlice {
  user: User;
  addressInput: string;
  error: string;
  relayMode: boolean;
  status: ResponseStatus;
  hasLoaded: boolean;
  profilePhoto: string;
  photoError: string;
  photoStatus: ResponseStatus;
}

const initialState: UserSlice = {
  user: {
    address: '',
    name: '',
    created: 0,
  },
  addressInput: '',
  error: '',
  relayMode: false,
  status: ResponseStatus.Unfetched,
  hasLoaded: false,
  profilePhoto: '',
  photoError: '',
  photoStatus: ResponseStatus.Unfetched,
};

const updateUserLoading = (state: UserSlice) => {
  state.status = ResponseStatus.Loading;
};

const updateUserSuccess = (state: UserSlice, action: any) => {
  state.user = { ...state.user, ...action.payload } || initialState.user;
  state.status = ResponseStatus.Success;
  state.hasLoaded = true;
};

const updateUserFailure = (state: UserSlice, action: any) => {
  state.status = ResponseStatus.Failure;
  state.error = action.payload;
  state.hasLoaded = true;
};

const fetchProfilePhotoLoading = (state: UserSlice) => {
  state.photoStatus = ResponseStatus.Loading;
};

const fetchProfilePhotoSuccess = (state: UserSlice, action: any) => {
  state.profilePhoto = action.payload;
  state.photoStatus = ResponseStatus.Success;
  state.photoError = '';
};

const fetchProfilePhotoFailure = (state: UserSlice, action: any) => {
  state.photoStatus = ResponseStatus.Failure;
  state.photoError = action.payload;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAddressInput: (state, action) => {
      state.addressInput = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setRelayMode: (state, action) => {
      state.relayMode = action.payload;
    },
    updateUserLocal: updateUserSuccess,
    [`${fetchUserConstant}/pending`]: updateUserLoading,
    [`${fetchUserConstant}/fulfilled`]: updateUserSuccess,
    [`${fetchUserConstant}/rejected`]: updateUserFailure,
    [`${createUserConstant}/pending`]: updateUserLoading,
    [`${createUserConstant}/fulfilled`]: updateUserSuccess,
    [`${createUserConstant}/rejected`]: updateUserFailure,
    [`${updateUserConstant}/pending`]: updateUserLoading,
    [`${updateUserConstant}/fulfilled`]: updateUserSuccess,
    [`${updateUserConstant}/rejected`]: updateUserFailure,
    [`${fetchProfilePhotoConstant}/pending`]: fetchProfilePhotoLoading,
    [`${fetchProfilePhotoConstant}/fulfilled`]: fetchProfilePhotoSuccess,
    [`${fetchProfilePhotoConstant}/rejected`]: fetchProfilePhotoFailure,
    resetUser: (state) => {
      state.user = initialState.user;
      state.status = initialState.status;
    },
  },
});

export const {
  setAddressInput,
  setError,
  setRelayMode,
  resetUser,
  updateUserLocal,
} = userSlice.actions;

export const fetchUser = createAsyncThunk(
  `user/${fetchUserConstant}`,
  async (address: string | null) => {
    const user = await fetchUserDB(address);
    if (address && user?.useEns) {
      const ens = await provider.lookupAddress(address);
      return {
        ...user,
        ...(ens && { ens }),
      };
    }
    return user;
  }
);

export const createUser = createAsyncThunk(
  `user/${createUserConstant}`,
  createUserDB
);

export const updateUser = createAsyncThunk(
  `user/${updateUserConstant}`,
  async (updateUser: any, { getState }) => {
    const currentUser = getUser(getState() as RootState);
    const user = { ...currentUser, ...updateUser };
    const modifiedUser = await updateUserDB(user);
    if (user.userId !== currentUser.userId) {
      await setUserIdDB({ userId: user.userId, address: user.address });
    }
    return modifiedUser;
  }
);

export const fetchProfilePhoto = createAsyncThunk(
  `user/${fetchProfilePhotoConstant}`,
  (address: string) => getDownloadURLForAddressDB(address)
);

export const getAddress = (state: RootState) => state.user.user.address;
export const getAddressInput = (state: RootState) => state.user.addressInput;
export const getError = (state: RootState) => state.user.error;
export const getUser = (state: RootState) => state.user.user;
export const getUserData = (state: RootState) => state.user;
export const getRelayMode = (state: RootState) => state.user.relayMode;

export default userSlice.reducer;
