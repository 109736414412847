import { useEffect, useState } from 'react';
import { fetchUser, setRelayMode } from '../slices/user';
import { AppDispatch } from '../redux/store';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { getItemAsync } from 'expo-secure-store';
import { generateWallet } from '../slices/wallet';
import { fetchContactInfos, fetchContacts } from '../slices/contacts';
import store from '../redux/store';
import { connectClient, getConnectStatus } from '../modules/client';

const useInit = () => {
  const [storageLoaded, setStorageLoaded] = useState(false);
  const dispatch: AppDispatch = store.dispatch;
  const { getItem: getRelayMode } = useAsyncStorage('relayMode');
  useEffect(() => {
    const readFromStorage = async () => {
      const relayModeValue = await getRelayMode();
      if (relayModeValue) dispatch(setRelayMode(relayModeValue === 'true'));
      if (Platform.OS === 'web') {
        setStorageLoaded(true);
        return;
      }
      const mnemonic = await getItemAsync('mnemonic');
      if (mnemonic) {
        const actionObj = generateWallet(mnemonic);
        const address = actionObj.payload.firstAddress;
        dispatch(actionObj);
        const connectStatus = getConnectStatus();
        if (address && !connectStatus.dCdn) {
          await connectClient(address);
        }
        await dispatch(fetchUser(address));
        const contacts =
          (await dispatch(fetchContacts(address)).unwrap()) || {};
        const contactAddresses: string[] = Object.values(contacts).map(
          (c: any) => c.address
        );
        dispatch(fetchContactInfos(contactAddresses));
      }
      setStorageLoaded(true);
    };
    readFromStorage();
  }, []);

  return { storageLoaded };
};

export default useInit;
