import React, { ReactNode } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { NeutralColor, PrimaryColor, ShadeColor } from '../utils/colors';
import HylloText from './HylloText';
import { textSizes, textWeights } from '../utils';

const styles = StyleSheet.create({
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    flexDirection: 'row',
  },
  buttonText: {
    color: ShadeColor.White,
  },
  startIcon: {
    marginRight: 8,
    justifyContent: 'center',
  },
  primary: {
    backgroundColor: PrimaryColor.Purple02,
  },
  primaryText: {
    color: ShadeColor.Black,
  },
  secondary: {
    borderColor: NeutralColor.Gray06,
    borderWidth: 1,
  },
  secondaryText: {
    color: ShadeColor.White,
  },
  fullWidth: {
    flex: 1,
  },
  disabled: {
    backgroundColor: NeutralColor.Gray09,
  },
  disabledText: { color: NeutralColor.Gray06 },
  buttonContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    borderRadius: 0,
  },
  linkText: {
    color: PrimaryColor.PrimaryPurple,
  },
  input: {
    backgroundColor: NeutralColor.Gray10,
    borderWidth: 0,
  },
  inputText: {
    color: NeutralColor.Gray07,
    ...textSizes.body,
    ...textWeights.regular,
  },
});

interface ButtonProps {
  children: ReactNode;
  onPress: () => void;
  variant?: 'primary' | 'secondary' | 'link' | 'input';
  startIcon?: ReactNode;
  style?: any;
  textStyle?: any;
  disabled?: boolean;
  fullWidth?: boolean;
}

const variantStylesMapping = {
  primary: styles.primary,
  secondary: styles.secondary,
  link: styles.link,
  input: styles.input,
};

const variantTextStylesMapping = {
  primary: styles.primaryText,
  secondary: styles.secondaryText,
  link: styles.linkText,
  input: styles.inputText,
};

const Button = ({
  children,
  onPress,
  startIcon,
  variant,
  style = {},
  textStyle = {},
  disabled = false,
  fullWidth = false,
}: ButtonProps) => (
  <Pressable
    onPress={onPress}
    style={[
      styles.button,
      variant && variantStylesMapping[variant],
      disabled && variant !== 'link' && styles.disabled,
      style,
    ]}
    disabled={disabled}
  >
    <View style={[styles.buttonContent, fullWidth && styles.fullWidth]}>
      {startIcon && <View style={styles.startIcon}>{startIcon}</View>}
      <HylloText
        size="button"
        style={[
          styles.buttonText,
          variant && variantTextStylesMapping[variant],
          disabled && styles.disabledText,
          textStyle,
        ]}
      >
        {children}
      </HylloText>
    </View>
  </Pressable>
);

export default Button;
