import { generateMnemonic, mnemonicToSeedSync } from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';
import { HDKey } from '@scure/bip32';
import { computeAddress } from 'ethers/lib/utils';
import { setItemAsync } from 'expo-secure-store';
import { Platform } from 'react-native';
import { Buffer } from 'buffer';

export const defaultHdPath = `m/44'/60'/0'/0`;

export const generateMnemonicAndSeed = () => {
  const mnemonic = generateMnemonic(wordlist, 256);
  const seed = mnemonicToSeedSync(mnemonic);
  return { mnemonic, seed };
};

export const publicKeyToAddress = (publicKey: Uint8Array | null) => {
  if (!publicKey) return '';
  const address = computeAddress(Buffer.from(publicKey));
  return address;
};

export class Wallet {
  private root?: HDKey;
  private nodes: HDKey[];
  private mnemonic?: string;

  constructor(seed?: Uint8Array) {
    if (seed) {
      const hdKey = HDKey.fromMasterSeed(seed);
      this.root = hdKey.derive(defaultHdPath);
    }
    this.nodes = [];
  }

  init(mnemonic: string) {
    this.mnemonic = mnemonic;
    const seed = mnemonicToSeedSync(mnemonic);
    const hdKey = HDKey.fromMasterSeed(seed);
    this.root = hdKey.derive(defaultHdPath);
    const child = this.root.deriveChild(0);
    if (child.publicKey) {
      this.nodes.push(child);
      const address = computeAddress(Buffer.from(child.publicKey));
      this.save();
      return address;
    }
    return null;
  }

  addAddress() {
    const child = this.root?.deriveChild(this.nodes.length);
    if (child?.publicKey) {
      const address = computeAddress(Buffer.from(child.publicKey));
      this.nodes.push(child);
      return address;
    }
  }

  getMnemonic() {
    return this.mnemonic;
  }

  reset() {
    this.root = undefined;
    this.nodes = [];
  }

  save() {
    if (!this.mnemonic) return;
    if (Platform.OS === 'web') return;
    setItemAsync('mnemonic', this.mnemonic);
  }
}

export default new Wallet();
