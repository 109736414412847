import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import Feather from '@expo/vector-icons/Feather';
import { NeutralColor, ShadeColor } from '../utils';
import { fetchContacts, getContacts } from '../slices/contacts';
import { AppDispatch } from '../redux/store';
import Button from '../components/Button';
import ContactsList from '../components/Contacts/ContactsList';
import { openModal } from '../slices/modals';
import { getAddress } from '../slices/user';
import HylloText from '../components/HylloText';
import useOpenChat from '../hooks/useOpenChat';
import { Screens } from '../navigator/screens';
import useContactInfo from '../hooks/useContactInfo';

const styles = StyleSheet.create({
  contactsContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  contactsListContainer: {
    backgroundColor: NeutralColor.Gray10,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  emptyContactList: {
    backgroundColor: ShadeColor.Black,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyContactTitle: {
    color: ShadeColor.White,
    marginBottom: 12,
  },
  emptyContactText: {
    color: NeutralColor.Gray04,
    marginBottom: 24,
  },
});

const Contacts = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const address = useSelector(getAddress);
  const contacts = useSelector(getContacts);
  const contactsArr = sortBy(Object.values(contacts || {}), 'name');
  const handleOpenChat = useOpenChat();
  const { fetchContactInfo } = useContactInfo();

  useEffect(() => {
    if (address) {
      fetchContactInfo();
    }
  }, [address, dispatch]);

  if (contactsArr.length === 0) {
    return (
      <View style={styles.emptyContactList}>
        <HylloText size="h2" style={styles.emptyContactTitle}>
          No contacts yet!
        </HylloText>
        <HylloText size="caption" style={styles.emptyContactText}>
          Add some friends to your contact list!
        </HylloText>
        <Button
          variant="primary"
          onPress={() => dispatch(openModal({ name: 'newContact' }))}
        >
          Add a contact
        </Button>
      </View>
    );
  }

  return (
    <View style={styles.contactsContainer}>
      <View style={{ padding: 16 }}>
        <Button
          variant="input"
          onPress={() => navigation.navigate(Screens.SearchContacts)}
          style={{ paddingVertical: 7 }}
          startIcon={
            <Feather name="search" size={16} color={NeutralColor.Gray07} />
          }
          fullWidth
        >
          Search Contacts
        </Button>
      </View>
      <ContactsList onContactPress={handleOpenChat} condensed />
    </View>
  );
};

export default Contacts;
