import { createSlice } from '@reduxjs/toolkit';
import { deleteItemAsync } from 'expo-secure-store';
import wallet from '../utils/wallet';
import { Platform } from 'react-native';

interface WalletSlice {
  addresses: string[];
  selectedAddress: string;
}

const initialState: WalletSlice = {
  addresses: [],
  selectedAddress: '',
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    createWallet: (state, action) => {
      const { firstAddress } = action.payload;
      state.addresses = [firstAddress];
      state.selectedAddress = firstAddress;
    },
    addAddress(state, action) {
      state.addresses.push(action.payload);
    },
    reset(state) {
      state.addresses = [];
      state.selectedAddress = '';
    },
  },
});

export const { createWallet, addAddress, reset } = walletSlice.actions;

export const generateWallet = (mnemonic: string) => {
  const firstAddress = wallet.init(mnemonic);
  return {
    type: createWallet.type,
    payload: { mnemonic, firstAddress },
  };
};

export const resetWallet = () => {
  wallet.reset();
  if (Platform.OS !== 'web') {
    deleteItemAsync('mnemonic');
  }
  return reset();
};

export default walletSlice.reducer;
