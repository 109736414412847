import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import {
  Contact,
  fetchContactInfos,
  fetchContacts,
  getContactInfos,
  getContacts,
} from '../slices/contacts';
import { getAddress } from '../slices/user';
import { useCallback } from 'react';
import { User } from '../utils';
import { displayUserId } from '../utils/user';

const useContactInfo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const address = useSelector(getAddress);
  const contacts = useSelector(getContacts);
  const contactInfos = useSelector(getContactInfos);
  const fetchContactInfo = useCallback(
    async (overrideAddress?: string) => {
      const contacts =
        (await dispatch(fetchContacts(overrideAddress || address)).unwrap()) ||
        {};
      const contactAddresses: string[] = Object.values(contacts).map(
        (c: any) => c.address
      );
      return dispatch(fetchContactInfos(contactAddresses));
    },
    [dispatch, address]
  );

  const findContactWithInfo = useCallback(
    (address: string) => {
      const contact = contacts[address];
      const contactInfo = contactInfos.find(
        (info: User) => info.address === address
      );
      return { ...contact, ...contactInfo };
    },
    [contacts, contactInfos]
  );

  const searchContactInfo = useCallback(
    (searchInput: string, contact: Contact) => {
      const contactInfo = findContactWithInfo(contact.address);
      const addressCheck = contact.address
        ?.toLowerCase()
        .includes(searchInput.toLowerCase());
      const nameCheck = contactInfo.name
        ?.toLowerCase()
        .includes(searchInput.toLowerCase());
      const userIdCheck = displayUserId(contactInfo)
        ?.toLowerCase()
        .includes(searchInput.toLowerCase());
      return addressCheck || nameCheck || userIdCheck;
    },
    [findContactWithInfo]
  );

  return { fetchContactInfo, findContactWithInfo, searchContactInfo };
};

export default useContactInfo;
