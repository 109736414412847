import React, { useState } from 'react';
import { View, Image, StyleSheet, Alert } from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import * as ImagePicker from 'expo-image-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import Button from '../Button';
import DefaultImage from '../DefaultImage';
import { ShadeColor, textSizes, textWeights } from '../../utils';
import ModalHeader from './ModalHeader';
import { uploadImageDB } from '../../services/images';
import { getAddress, updateUser } from '../../slices/user';
import { AppDispatch } from '../../redux/store';
import useProfilePhoto from '../../hooks/useProfilePhoto';
import { fetchProfileImage } from '../../slices/images';
import { defaultActionSheetOptions } from '../../utils/action-sheet';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  modalBody: {
    alignItems: 'center',
    flex: 1,
    marginTop: 24,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
    flex: 1,
  },
  image: {
    width: 320,
    height: 320,
    borderRadius: 320,
  },
  profileButton: {
    marginBottom: 36,
  },
  buttonContainer: {
    flex: 1 / 3,
    alignItems: 'center',
    marginVertical: 24,
  },
  existingPhotoButton: { borderWidth: 0 },
});

const ProfilePhotoModal = ({ onClose }: { onClose: () => void }) => {
  const [imageUri, setImageUri] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const address = useSelector(getAddress);
  const { showActionSheetWithOptions } = useActionSheet();
  const photoUrl = useProfilePhoto(address);
  const pickImageAsync = async () => {
    const options = ['Take Photo', 'Open Gallery', 'Cancel'];
    const cancelButtonIndex = 2;
    showActionSheetWithOptions(
      {
        ...defaultActionSheetOptions,
        options,
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        let result: any;
        if (buttonIndex && buttonIndex >= cancelButtonIndex) {
          return;
        }
        try {
          const request = await ImagePicker.requestCameraPermissionsAsync();
          if (request.granted) {
            if (buttonIndex === 0) {
              result = await ImagePicker.launchCameraAsync({
                allowsEditing: true,
                quality: 1,
              });
            } else if (buttonIndex === 1) {
              result = await ImagePicker.launchImageLibraryAsync({
                allowsEditing: true,
                quality: 1,
              });
            }
          }
        } catch (e) {
          console.log(e);
        }

        const manipResult = await manipulateAsync(
          result.assets[0].uri,
          [{ resize: { width: 320 } }],
          { format: SaveFormat.JPEG, compress: 0.8 }
        );

        if (!result?.canceled) {
          setImageUri(manipResult.uri);
        } else {
          Alert.alert('You did not select any image.');
        }
      }
    );
  };

  const uploadImageAsync = async () => {
    const downloadUri = await uploadImageDB({
      address,
      imageUri,
    });
    dispatch(updateUser({ address, hasProfilePhoto: true }));
    dispatch(fetchProfileImage(address));
    setImageUri(downloadUri);
    onClose();
  };

  return (
    <View style={styles.modalContainer}>
      <ModalHeader
        onClose={onClose}
        title="Profile Photo"
        rightButton={
          <Button
            variant="link"
            onPress={uploadImageAsync}
            disabled={!imageUri}
            textStyle={{ ...textSizes.body, ...textWeights.bold }}
          >
            Done
          </Button>
        }
      />
      <View style={styles.modalBody}>
        <View style={styles.imageContainer}>
          {imageUri || photoUrl ? (
            <Image
              source={{ uri: imageUri || photoUrl }}
              style={styles.image}
            />
          ) : (
            <DefaultImage size={320} />
          )}
        </View>
        <View style={styles.buttonContainer}>
          <Button
            style={styles.profileButton}
            onPress={pickImageAsync}
            startIcon={
              <Feather name="image" size={20} color={ShadeColor.White} />
            }
            variant="secondary"
          >
            {imageUri ? 'Update this photo' : 'Choose a photo'}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default ProfilePhotoModal;
