export const NeutralColor = {
  Gray01: '#f7f7f7',
  Gray02: '#efeff0',
  Gray03: '#dfdfe0',
  Gray04: '#ceced1',
  Gray05: '#aeaeb2',
  Gray06: '#7c7c80',
  Gray07: '#545456',
  Gray08: '#444446',
  Gray09: '#363638',
  Gray10: '#1d1d1d',
};

export const PrimaryColor = {
  Purple01: '#e9e8ff',
  Purple02: '#c8c7ff',
  Purple03: '#9b99ff',
  PrimaryPurple: '#7d7aff',
  BluePurple: '#5a57ff',
};

export const SecondaryColor = {
  Brown01: '#d0ccc6',
  Brown02: '#b5afa7',
  Brown03: '#b5a99a',
  Brown04: '#b5a28a',
  Brown05: '#b59469',
};

export const SuccessColor = {
  Green01: '#a4c1b7',
  Green02: '#7cc1a8',
  Primary: '#53c19a',
  Green04: '#48a886',
};

export const WarningColor = {
  Yellow: '#ffd426',
  Orange: '#ffb340',
  Red: '#ff453a',
};

export const ErrorColor = {
  Pink: '#ffb2ad',
  DarkRed: '#d95952',
};

export const ShadeColor = {
  White: '#ffffff',
  Black: '#000000',
};

export const LinearGradientColors = {
  PurpleStart: '#9664FF',
  PurpleEnd: '#271E33',
  Purple: 'rgba(105, 60, 201, 0.4)',
};
