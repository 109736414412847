import React, { useCallback } from 'react';
import { Modal } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import noop from 'lodash/noop';
import { closeModal, getModals } from '../slices/modals';
import modals from './Modals';
import { AppDispatch } from '../redux/store';

const ModalPortal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { modalName, modalProps } = useSelector(getModals);
  const ModalComponent = modals[modalName];
  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);
  return (
    <Modal
      animationType={modalProps.animationType || 'slide'}
      visible={!!ModalComponent}
      onRequestClose={modalProps.disableOnRequestClose ? noop : handleClose}
      presentationStyle={modalProps.presentationStyle || 'pageSheet'}
    >
      {!!ModalComponent && (
        <ModalComponent {...modalProps} onClose={handleClose} />
      )}
    </Modal>
  );
};

export default ModalPortal;
