import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createLogger } from 'redux-logger';

const logger = createLogger({
  collapsed: true,
});
import userReducer from '../slices/user';
import chatsReducer from '../slices/chats';
import contactsReducer from '../slices/contacts';
import modalsReducer from '../slices/modals';
import imagesReducer from '../slices/images';
import walletReducer from '../slices/wallet';
import settingsReducer from '../slices/settings';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const storageMiddleware = (store: any) => (next: any) => (action: any) => {
  const previousState = store.getState();
  next(action);
  const nextState = store.getState();
  if (previousState.user.relayMode !== nextState.user.relayMode) {
    AsyncStorage.setItem('relayMode', JSON.stringify(nextState.user.relayMode));
  }
};
const middlewares: any[] = [];
if (process.env.NODE_ENV === 'development' && Platform.OS === 'web') {
  middlewares.push(logger);
}
middlewares.push(storageMiddleware);

const store = configureStore({
  reducer: {
    user: userReducer,
    chats: chatsReducer,
    contacts: contactsReducer,
    modals: modalsReducer,
    images: imagesReducer,
    wallet: walletReducer,
    settings: settingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
