import React, { ReactNode } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import HylloText from '../HylloText';
import { NeutralColor, PrimaryColor } from '../../utils';

const styles = StyleSheet.create({
  iconButton: {
    backgroundColor: NeutralColor.Gray10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: 65,
    borderRadius: 10,
    marginHorizontal: 4,
  },
  iconButtonLabel: { color: PrimaryColor.PrimaryPurple, marginTop: 2 },
});

const IconButton = ({
  onPress,
  icon,
  label,
}: {
  onPress: () => void;
  icon: ReactNode;
  label: string;
}) => (
  <Pressable style={styles.iconButton} onPress={onPress}>
    {icon}
    <HylloText size="caption" weight="regular" style={styles.iconButtonLabel}>
      {label}
    </HylloText>
  </Pressable>
);

export default IconButton;
