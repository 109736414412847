import React from 'react';
import { StyleSheet, Image } from 'react-native';
import DefaultImage from './DefaultImage';
import useProfilePhoto from '../hooks/useProfilePhoto';

const styles = StyleSheet.create({
  image: {
    width: 320,
    height: 320,
    borderRadius: 320,
  },
});

const ProfilePhoto = ({
  address,
  initial,
  size = 54,
  rounded = true,
  style,
}: {
  address: string;
  initial?: string;
  size?: number;
  rounded?: boolean;
  style?: any;
}) => {
  const photoUrl = useProfilePhoto(address);

  if (!address || !photoUrl) {
    return (
      <DefaultImage
        size={size}
        rounded={rounded}
        style={style}
        text={initial}
      />
    );
  }

  return (
    <Image
      source={{ uri: photoUrl }}
      style={[
        styles.image,
        { height: size, width: size },
        rounded && { borderRadius: size },
        style,
      ]}
    />
  );
};

export default ProfilePhoto;
