import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useCallback } from 'react';
import { Screens, StackParamList } from '../navigator/screens';
import { useDispatch } from 'react-redux';
import { resetUser } from '../slices/user';
import { resetWallet } from '../slices/wallet';

const useLogoutUser = () => {
  const dispatch = useDispatch();
  const navigation =
    useNavigation<NativeStackNavigationProp<StackParamList, Screens.Welcome>>();
  const logoutUser = useCallback(() => {
    dispatch(resetUser());
    dispatch(resetWallet());
    navigation.reset({
      index: 0,
      routes: [{ name: Screens.Welcome }],
    });
  }, [dispatch, navigation]);
  return logoutUser;
};

export default useLogoutUser;
