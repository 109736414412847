export enum ResponseStatus {
  Unfetched = 'UNFETCHED',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export interface Message {
  id: string;
  from: string;
  to?: string;
  message: string;
  created: number;
  updated?: number;
  chatId: string;
  readReceipts?: Record<string, number>;
}

export interface Chat {
  id: string;
  addresses: string[];
  messages: Message[];
  created: number;
  updated?: number;
}

export interface User {
  address: string;
  name: string;
  created: number;
  hasProfilePhoto?: boolean;
  updated?: number;
  userId?: string;
  hasPIN?: boolean;
  newUser?: boolean;
  useEns?: boolean;
  ens?: string;
}
