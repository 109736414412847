import { createSlice } from '@reduxjs/toolkit';
import { ResponseStatus } from '../utils';
import {
  getDownloadURLForAddressDB,
} from '../services/images';
import { AppDispatch } from '../redux/store';

interface Image {
  address: string;
  url: string;
  status: ResponseStatus;
  error: string;
}

interface ImagesSlice {
  images: Record<string, Image>;
}

const initialState: ImagesSlice = {
  images: {},
};

const addImageConstant = 'addImage';

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    [`${addImageConstant}/pending`]: (state, action) => {
      state.images[action.payload.address] = {
        address: action.payload.address,
        url: '',
        status: ResponseStatus.Loading,
        error: '',
      };
    },
    [`${addImageConstant}/fulfilled`]: (state, action) => {
      const { address, url } = action.payload;
      const image = state.images[address];
      state.images[address] = {
        ...image,
        url,
        status: ResponseStatus.Success,
        error: '',
      };
    },
    [`${addImageConstant}/rejected`]: (state, action) => {
      const { address, error } = action.payload;
      const image = state.images[address];
      state.images[address] = {
        ...image,
        status: ResponseStatus.Failure,
        error,
      };
    },
  },
});

export const fetchProfileImage =
  (address: string) => async (dispatch: AppDispatch) => {
    dispatch({
      type: `images/${addImageConstant}/pending`,
      payload: { address },
    });
    try {
      const url = await getDownloadURLForAddressDB(address);
      dispatch({
        type: `images/${addImageConstant}/fulfilled`,
        payload: { address, url },
      });
    } catch (e: any) {
      dispatch({
        type: `images/${addImageConstant}/rejected`,
        payload: { address, error: e.message },
      });
    }
  };

export const getImages = (state: any) => state.images;

export const getProfileImage = (state: any, address: string) =>
  state.images.images[address];

export default imagesSlice.reducer;
