import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const ChatIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}: any) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <G
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    >
      <Path d="M19.398 13.968c-.048-.172.059-.41.16-.58A7.6 7.6 0 0021 8.944C21.015 4.557 17.239 1 12.57 1 8.498 1 5.1 3.716 4.305 7.322a7.5 7.5 0 00-.18 1.627c0 4.39 3.63 8.044 8.3 8.044.742 0 1.741-.219 2.29-.366a15.593 15.593 0 001.233-.395 1.307 1.307 0 01.941.014l2.752.956c.06.025.124.041.19.048.102 0 .201-.04.274-.111a.377.377 0 00.114-.27.598.598 0 00-.025-.128l-.796-2.773z" />
      <Path d="M1.88 9.75a7.19 7.19 0 00.305 7.403c.11.17.172.3.153.388-.02.088-.57 3-.57 3a.394.394 0 00.13.373c.07.056.157.087.246.086.048 0 .095-.01.139-.03l2.68-1.066a.736.736 0 01.571.01c.903.358 1.901.582 2.9.582a7.491 7.491 0 003.816-1.042" />
    </G>
  </Svg>
);

export default ChatIcon;
