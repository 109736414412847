import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getAddress } from '../../slices/user';
import { addMessage, getCurrentChat } from '../../slices/chats';
import { Keyboard, StyleSheet, TextInput, View } from 'react-native';
import { NeutralColor, ShadeColor } from '../../utils/colors';
import Feather from '@expo/vector-icons/Feather';
import { textWeights, textSizes } from '../../utils/text';
import { Screens, StackParamList } from '../../navigator/screens';
import { RouteProp, useRoute } from '@react-navigation/native';
import { sendMessage } from '../../modules/client';

const styles = StyleSheet.create({
  footerContainer: {
    paddingHorizontal: 24,
    paddingTop: 12,
    paddingBottom: 32,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: NeutralColor.Gray10,
  },
  messageInput: {
    ...textSizes.caption,
    ...textWeights.bold,
    color: ShadeColor.White,
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 100,
    backgroundColor: NeutralColor.Gray09,
  },
  sendIcon: { marginLeft: 12, color: ShadeColor.White },
});

const MessageBar = () => {
  const route = useRoute<RouteProp<StackParamList, Screens.Chat>>();
  const currentChatId = route.params?.chatId;
  const dispatch: AppDispatch = useDispatch();
  const address = useSelector(getAddress);
  const chat = useSelector((state: RootState) =>
    getCurrentChat(state, currentChatId)
  );
  const [messageInput, setMessageInput] = useState('');

  // useEffect(() => {
    // const otherAddress = chat?.addresses.find((a) => a !== address) || address;
    // sendMessage(otherAddress, 'hello');
  // }, [chat, address]);

  const handleSendMessage = async () => {
    if (messageInput) {
      const data = {
        from: address,
        message: messageInput,
        chatId: chat?.id,
        to: chat?.addresses.find((a) => a !== address) || address,
      };
      await dispatch(addMessage(data));
      setMessageInput('');
    }
  };
  return (
    <View style={styles.footerContainer}>
      <TextInput
        style={styles.messageInput}
        value={messageInput}
        onChangeText={setMessageInput}
        placeholder="Type your message"
        placeholderTextColor={NeutralColor.Gray04}
        returnKeyType="send"
        onSubmitEditing={handleSendMessage}
        keyboardAppearance="dark"
        blurOnSubmit={false}
      />
      <Feather
        name="send"
        onPress={handleSendMessage}
        size={24}
        style={styles.sendIcon}
      />
    </View>
  );
};

export default MessageBar;
