import { StyleSheet } from 'react-native';
import { NeutralColor, PrimaryColor, ShadeColor } from './colors';
import { textSizes, textWeights } from './text';

export const headerStyles = StyleSheet.create({
  headerStyle: {
    backgroundColor: ShadeColor.Black,
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintStyle: {
    color: ShadeColor.White,
  },
  headerTitleStyle: {
    ...textSizes.body,
    ...textWeights.bold,
    color: ShadeColor.White,
  },
  tabBarLabelStyle: {
    ...textSizes.tab,
    ...textWeights.medium,
  },
  tabBarStyle: {
    backgroundColor: ShadeColor.Black,
    borderTopWidth: 0,
    height: 82,
    padding: 12,
  },
});

interface DefaultHeaderOptions {
  tabBarStyle: any;
  tabBarActiveTintColor: string;
  tabBarInactiveTintColor: string;
  headerStyle: any;
  headerTintColor: string;
  headerTitleStyle: any;
  headerTitleAlign: 'center' | 'left' | undefined;
  tabBarLabelStyle: any;
}

export const defaultHeaderOptions: DefaultHeaderOptions = {
  tabBarStyle: headerStyles.tabBarStyle,
  tabBarActiveTintColor: NeutralColor.Gray05,
  tabBarInactiveTintColor: NeutralColor.Gray05,
  headerStyle: headerStyles.headerStyle,
  headerTintColor: PrimaryColor.PrimaryPurple,
  headerTitleStyle: headerStyles.headerTitleStyle,
  headerTitleAlign: 'center',
  tabBarLabelStyle: headerStyles.tabBarLabelStyle,
};
