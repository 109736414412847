import React, { useCallback, useEffect, useState } from 'react';
import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { isAddress } from 'ethers/lib/utils';
import { getAddress } from '../../slices/user';
import { AppDispatch } from '../../redux/store';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  WarningColor,
} from '../../utils/colors';
import { deleteContact, getContacts, setContact } from '../../slices/contacts';
import { useActionSheet } from '@expo/react-native-action-sheet';
import ModalHeader from './ModalHeader';
import HylloText from '../HylloText';
import { textSizes, textWeights } from '../../utils';
import ProfileInfo from '../UserProfile/ProfileInfo';
import useContactInfo from '../../hooks/useContactInfo';
import Input from '../Input';
import { Card, CardRow } from '../Card';
import { displayNickname, displayUserId } from '../../utils/user';
import { defaultActionSheetOptions } from '../../utils/action-sheet';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalHeader: {
    flexDirection: 'row',
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalButton: { position: 'absolute', left: 0, borderWidth: 0 },
  modalButtonText: { color: PrimaryColor.PrimaryPurple, fontWeight: '400' },
  modalBody: {
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  modalInput: { paddingLeft: 54, paddingVertical: 12 },
  modalLabel: {
    color: NeutralColor.Gray06,
  },
  modalError: {
    color: WarningColor.Red,
    marginTop: 12,
  },
  modalFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
});

const ContactModal = ({
  address: contactAddress,
  onClose,
}: {
  address: string;
  onClose: () => void;
}) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch: AppDispatch = useDispatch();
  const contacts = useSelector(getContacts);
  const { findContactWithInfo } = useContactInfo();
  const contactInfo = findContactWithInfo(contactAddress);
  const currentContact = contacts?.[contactAddress];
  const address = useSelector(getAddress);
  const [addressInput, setAddressInput] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (currentContact) {
      setAddressInput(currentContact?.address || '');
      setNameInput(currentContact?.name || '');
    }
  }, [currentContact]);

  const handleClose = () => {
    onClose();
    setAddressInput('');
    setNameInput('');
  };

  const handleButtonPress = useCallback(async () => {
    if (!isAddress(addressInput)) {
      setError('The address you provided is not an address!');
    } else {
      const contactExists = !!contacts?.[addressInput];
      if (!contactExists) {
        setError('This contact does not exist. Please create it!');
      } else {
        const data = {
          ...currentContact,
          name: nameInput,
          address: addressInput,
        };
        await dispatch(setContact({ address, data }));
      }
      setAddressInput('');
      setError('');
      handleClose();
    }
  }, [
    address,
    addressInput,
    nameInput,
    setError,
    dispatch,
    contacts,
    currentContact,
  ]);

  const handleDeletePress = useCallback(async () => {
    const options = ['Delete', 'Cancel'];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 2;
    const title = 'Delete this contact?';
    showActionSheetWithOptions(
      {
        ...defaultActionSheetOptions,
        options,
        destructiveButtonIndex,
        cancelButtonIndex,
        title,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          dispatch(
            deleteContact({
              userAddress: address,
              contactAddress: currentContact.address,
            })
          );
          handleClose();
        }
      }
    );
  }, [dispatch, handleClose, currentContact]);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== 'web') {
          Keyboard.dismiss();
        }
      }}
      accessible={false}
    >
      <View style={styles.modalContainer}>
        <ModalHeader
          onClose={onClose}
          title=""
          rightButton={
            <Button
              variant="link"
              onPress={handleButtonPress}
              textStyle={{ ...textSizes.body, ...textWeights.bold }}
            >
              Done
            </Button>
          }
        />
        <View style={styles.modalBody}>
          <ProfileInfo
            address={contactAddress}
            name={displayNickname(contactInfo)}
            userId={displayUserId(contactInfo)}
          />
          <Input
            startNode={<HylloText style={styles.modalLabel}>Alias:</HylloText>}
            style={styles.modalInput}
            variant="secondary"
            onChangeText={setNameInput}
            value={nameInput}
            keyboardAppearance="dark"
          />
          {!!error && <HylloText style={styles.modalError}>{error}</HylloText>}
          <Card style={{ width: '100%' }}>
            <CardRow
              style={{ paddingVertical: 11 }}
              onPress={handleDeletePress}
            >
              <HylloText style={{ color: WarningColor.Red }}>
                Delete Contact
              </HylloText>
            </CardRow>
          </Card>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default ContactModal;
