import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const PhoneCallIcon = ({
  size = 24,
  color = 'currentColor',
  ...otherProps
}) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    {...otherProps}
  >
    <G fillRule="evenodd" clipRule="evenodd" fill={color}>
      <Path d="M9.801 4.356a.75.75 0 01.88-.592 4.5 4.5 0 013.555 3.555.75.75 0 11-1.472.287 3 3 0 00-2.37-2.37.75.75 0 01-.593-.88z" />
      <Path d="M9.792 1.417a.75.75 0 01.828-.662 7.5 7.5 0 016.625 6.616.75.75 0 01-1.49.168 6 6 0 00-5.3-5.294.75.75 0 01-.663-.828zM4.753 13.314a15.185 15.185 0 01-3.858-6.58C.785 6.344.7 6.042.698 5.629a3.274 3.274 0 01.384-1.479c.398-.709 1.254-1.618 1.993-1.992a2.25 2.25 0 012.031 0c.627.317 1.346 1.051 1.727 1.638a2.25 2.25 0 010 2.453c-.13.2-.315.385-.53.6-.067.066-.14.114-.091.216A10.202 10.202 0 008.2 9.867c.84.84 1.79 1.503 2.801 1.989.105.05.148-.022.217-.092.215-.215.4-.4.6-.53a2.25 2.25 0 012.452 0c.588.382 1.321 1.1 1.638 1.728a2.25 2.25 0 010 2.03c-.37.734-1.272 1.59-1.992 1.994a3.273 3.273 0 01-1.478.384c-.414-.001-.716-.087-1.105-.198a15.184 15.184 0 01-6.58-3.858z" />
    </G>
  </Svg>
);

export default PhoneCallIcon;
