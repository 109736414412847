import React, { useEffect } from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { isAddressEqual } from '../../utils/address';
import {
  getCurrentChat,
  getCurrentMessages,
  getMessages,
  sendReadReceipt,
} from '../../slices/chats';
import {
  LinearGradientColors,
  NeutralColor,
  ShadeColor,
} from '../../utils/colors';
import { getAddress } from '../../slices/user';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  Screens,
  StackParamList,
  TabScreenParamList,
} from '../../navigator/screens';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import EmptyMessages from './EmptyMessages';
import { LinearGradient } from 'expo-linear-gradient';
import MessageBubble from './MessageBubble';
import { AppDispatch, RootState } from '../../redux/store';
import { Message, isUnreadMessage } from '../../utils';
import HylloText from '../HylloText';

const styles = StyleSheet.create({
  linearGradientContainer: { flex: 1 },
  messagesContainer: {
    flex: 1,
  },
  dateTimeBubble: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 24,
    backgroundColor: NeutralColor.Gray10,
    flexWrap: 'wrap',
  },
  dateTimeText: {
    color: ShadeColor.White,
  },
  dateTimeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 24,
  },
});

const MessageList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const route = useRoute<RouteProp<StackParamList, Screens.Chat>>();
  const currentChatId = route.params?.chatId;
  const navigation =
    useNavigation<
      NativeStackNavigationProp<TabScreenParamList, Screens.ChatList>
    >();
  const address = useSelector(getAddress);
  const chat = useSelector((state: RootState) =>
    getCurrentChat(state, currentChatId)
  );
  const currentMessages = useSelector((state: RootState) =>
    getCurrentMessages(state, currentChatId)
  );
  const messages = useSelector((state: RootState) =>
    getMessages(state, currentChatId)
  );

  useEffect(() => {
    if (!chat) {
      navigation.navigate(Screens.ChatList);
    }
  }, [chat]);

  useEffect(() => {
    messages.forEach((message) => {
      if (isUnreadMessage(message, address)) {
        dispatch(
          sendReadReceipt({
            chatId: currentChatId,
            messageId: message.id,
            address,
          })
        );
      }
    });
  }, [messages, address, currentChatId]);

  if (Object.keys(currentMessages).length === 0) {
    return <EmptyMessages />;
  }

  return (
    <LinearGradient
      colors={[ShadeColor.Black, LinearGradientColors.Purple]}
      start={{ x: 0.4, y: 0.7 }}
      end={{ x: 1.7, y: 1.4 }}
      style={styles.linearGradientContainer}
    >
      <FlatList
        style={styles.messagesContainer}
        data={currentMessages}
        keyExtractor={([dateTime]: [dateTime: string, messages: Message[]]) =>
          dateTime
        }
        inverted
        renderItem={({ item: [dateTime, messages] }) => {
          return (
            <Pressable key={dateTime}>
              <View style={styles.dateTimeContainer}>
                <View style={styles.dateTimeBubble}>
                  <HylloText style={styles.dateTimeText}>{dateTime}</HylloText>
                </View>
              </View>
              {messages.map((message) => {
                const fromCurrentAddress = isAddressEqual(
                  message.from,
                  address
                );
                return (
                  <MessageBubble
                    key={message.id}
                    {...message}
                    fromCurrentAddress={fromCurrentAddress}
                  />
                );
              })}
            </Pressable>
          );
        }}
      />
    </LinearGradient>
  );
};

export default MessageList;
