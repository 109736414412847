import { StyleSheet, Platform } from 'react-native';

export const textWeights = StyleSheet.create({
  black: {
    fontWeight: '900',
  },
  bold: {
    fontWeight: '700',
  },
  semibold: {
    fontWeight: '600',
  },
  medium: {
    fontWeight: '500',
  },
  regular: {
    fontWeight: '400',
  },
  light: {
    fontWeight: '300',
  },
  thin: {
    fontWeight: '100',
  },
});

export const textSizes = StyleSheet.create({
  poppinsH1: {
    fontFamily: 'Poppins_600SemiBold',
    fontWeight: '600',
    fontSize: 30,
    lineHeight: 41,
  },
  poppinsH2: {
    fontFamily: 'Poppins_600SemiBold',
    fontWeight: '600',
    fontSize: 28,
    lineHeight: 41,
  },
  h1: {
    ...textWeights.bold,
    fontSize: 34,
    lineHeight: 41,
  },
  h2: {
    ...textWeights.bold,
    fontSize: 28,
    lineHeight: 34,
  },
  h3: {
    ...textWeights.bold,
    fontSize: 22,
    lineHeight: 28,
  },
  h4: {
    ...textWeights.bold,
    fontSize: 20,
    lineHeight: 25,
  },
  h5: {
    ...textWeights.bold,
    fontSize: 17,
    lineHeight: 22,
  },
  body: {
    ...textWeights.regular,
    fontSize: 16,
    lineHeight: 22,
  },
  inputBody: {
    ...textWeights.regular,
    fontSize: 16,
    lineHeight: 20,
  },
  button: {
    ...textWeights.bold,
    fontSize: 14,
    lineHeight: 20,
  },
  caption: {
    ...textWeights.regular,
    fontSize: 12,
    lineHeight: 16,
  },
  tab: {
    ...textWeights.regular,
    fontSize: 10,
    lineHeight: 16,
  },
});
