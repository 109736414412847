import React, { useCallback, useEffect } from 'react';
import {
  Alert,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import {
  ShadeColor,
  displayAddress,
  NeutralColor,
  WarningColor,
  PrimaryColor,
  textSizes,
} from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUser,
  getAddress,
  getRelayMode,
  getUser,
  setRelayMode,
} from '../slices/user';
import { AppDispatch } from '../redux/store';
import { openModal } from '../slices/modals';
import HylloText from '../components/HylloText';
import { Screens } from '../navigator/screens';
import useWallet from '../hooks/useWallet';
import { displayUserId } from '../utils/user';
import useWalletConnect from '../hooks/useWalletConnect';
import { Card, CardRow } from '../components/Card';
import ListSeparator from '../components/ListSeparator';
import HylloSwitch from '../components/HylloSwitch';
import CPUIcon from '../components/Icons/CPUIcon';
import ProfileInfo from '../components/UserProfile/ProfileInfo';
import QRCodeIcon from '../components/Icons/QRCodeIcon';

const styles = StyleSheet.create({
  settingsContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
  },
  profilePhoto: {
    marginTop: 4,
  },
  pressableContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  pressableContainerBorder: {
    borderBottomWidth: 1,
    borderBottomColor: NeutralColor.Gray09,
  },
  pressableLabel: {
    color: PrimaryColor.PrimaryPurple,
    marginLeft: 20,
    flex: 1,
  },
  settingsSubtitle: {
    color: NeutralColor.Gray05,
    marginTop: 24,
    marginBottom: 12,
  },
  labelWhite: { color: ShadeColor.White },
  settingsListContainer: {
    padding: 20,
  },
});

const Settings = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const address = useSelector(getAddress);
  const relayMode = useSelector(getRelayMode);
  const { disconnect } = useWallet();
  const { isConnected, address: web3Address } = useWalletConnect();

  const isWalletConnect = isConnected && web3Address === address;
  const toggleSwitch = useCallback(() => {
    dispatch(setRelayMode(!relayMode));
  }, [dispatch, relayMode]);

  useEffect(() => {
    dispatch(fetchUser(address));
  }, [address]);

  return (
    <View style={styles.settingsContainer}>
      <ScrollView>
        <SafeAreaView>
          <View style={{ marginHorizontal: 32, marginTop: 12 }}>
            <QRCodeIcon color={PrimaryColor.PrimaryPurple} size={19} />
          </View>
        </SafeAreaView>
        <Pressable>
          <ProfileInfo
            address={address}
            name={user.name || 'User Name'}
            userId={displayUserId(user)}
            showAddress
            photoStyle={styles.profilePhoto}
            nameStyle={textSizes.h3}
          />
          <View style={styles.settingsListContainer}>
            <Card>
              <CardRow
                onPress={() => dispatch(openModal({ name: 'profilePhoto' }))}
              >
                <Feather
                  name="image"
                  size={24}
                  color={PrimaryColor.PrimaryPurple}
                />
                <HylloText style={styles.pressableLabel}>
                  Set Profile Photo
                </HylloText>
              </CardRow>
              <ListSeparator marginLeft={60} />
              <CardRow
                onPress={() => dispatch(openModal({ name: 'nickname' }))}
              >
                <Feather
                  name="at-sign"
                  size={24}
                  color={PrimaryColor.PrimaryPurple}
                />
                <HylloText style={styles.pressableLabel}>
                  Set User Nickname
                </HylloText>
              </CardRow>
            </Card>

            <HylloText weight="bold" style={styles.settingsSubtitle}>
              Data Usage
            </HylloText>
            <Card>
              <CardRow>
                <Feather
                  name="git-pull-request"
                  size={24}
                  color={ShadeColor.White}
                />
                <HylloText style={[styles.pressableLabel, styles.labelWhite]}>
                  Relay Mode
                </HylloText>
                <HylloSwitch onValueChange={toggleSwitch} value={relayMode} />
              </CardRow>
              <ListSeparator marginLeft={60} />
              <CardRow>
                <Feather name="command" size={24} color={ShadeColor.White} />
                <HylloText style={[styles.pressableLabel, styles.labelWhite]}>
                  Maximum Cellular Data
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
              <ListSeparator marginLeft={60} />
              <CardRow>
                <CPUIcon size={24} color={ShadeColor.White} />
                <HylloText style={[styles.pressableLabel, styles.labelWhite]}>
                  Maximum CPU Usage
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
            </Card>

            <HylloText weight="bold" style={styles.settingsSubtitle}>
              Notifications
            </HylloText>
            <Card>
              <CardRow
                onPress={() => navigation.navigate(Screens.PushNotifications)}
              >
                <Feather name="bell" size={24} color={ShadeColor.White} />
                <HylloText style={[styles.pressableLabel, styles.labelWhite]}>
                  Push Notifications
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
              <ListSeparator marginLeft={60} />
              <CardRow
                onPress={() => Alert.alert('open smart messaging modal')}
              >
                <Feather
                  name="codesandbox"
                  size={24}
                  color={ShadeColor.White}
                />
                <HylloText style={[styles.pressableLabel, styles.labelWhite]}>
                  Smart Messaging
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
            </Card>

            {!isWalletConnect && (
              <>
                <HylloText weight="bold" style={styles.settingsSubtitle}>
                  Security and Privacy
                </HylloText>
                <Card>
                  <CardRow onPress={() => Alert.alert('open passcode modal')}>
                    <Feather name="lock" size={24} color={ShadeColor.White} />
                    <HylloText
                      style={[styles.pressableLabel, styles.labelWhite]}
                    >
                      Passcode & Face ID
                    </HylloText>
                    <Feather
                      name="chevron-right"
                      size={24}
                      color={NeutralColor.Gray04}
                    />
                  </CardRow>
                  <ListSeparator marginLeft={60} />
                  <CardRow
                    onPress={() =>
                      navigation.navigate(Screens.SecretRecoveryPhrase)
                    }
                  >
                    <Feather
                      name="alert-octagon"
                      size={24}
                      color={ShadeColor.White}
                    />
                    <HylloText
                      style={[styles.pressableLabel, styles.labelWhite]}
                    >
                      Secret Recovery Phrase
                    </HylloText>
                    <Feather
                      name="chevron-right"
                      size={24}
                      color={NeutralColor.Gray04}
                    />
                  </CardRow>
                </Card>
              </>
            )}

            <HylloText weight="bold" style={styles.settingsSubtitle}>
              More Info
            </HylloText>
            <Card>
              <CardRow onPress={() => Alert.alert('open help & support link')}>
                <Feather name="mail" size={24} color={ShadeColor.White} />
                <HylloText style={[styles.pressableLabel, styles.labelWhite]}>
                  Help & Support
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
            </Card>

            <HylloText weight="bold" style={styles.settingsSubtitle}>
              Login
            </HylloText>
            <Card>
              {/* <CardRow onPress={() => Alert.alert('open switch account modal')}>
                <Feather name="user-plus" size={24} color={ShadeColor.White} />
                <HylloText style={styles.pressableLabel}>
                  Switch Account
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
              <ListSeparator marginLeft={60} /> */}
              <CardRow
                onPress={() => {
                  if (Platform.OS === 'web') {
                    disconnect();
                  } else {
                    Alert.alert(
                      'Are you sure you want to log out?',
                      '',
                      [
                        {
                          text: 'Cancel',
                          onPress: () => {},
                          style: 'cancel',
                        },
                        {
                          text: 'Log Out',
                          onPress: disconnect,
                        },
                      ],
                      { cancelable: false }
                    );
                  }
                }}
              >
                <Feather name="log-out" size={24} color={ShadeColor.White} />
                <HylloText
                  style={[styles.pressableLabel, { color: WarningColor.Red }]}
                >
                  Log Out
                </HylloText>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={NeutralColor.Gray04}
                />
              </CardRow>
            </Card>
          </View>
        </Pressable>
      </ScrollView>
    </View>
  );
};

export default Settings;
