import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectChatsMode,
  getSelectedChats,
  toggleSelectChatsMode,
} from '../slices/settings';
import { StyleSheet, View } from 'react-native';
import { Message, ShadeColor } from '../utils';
import Button from './Button';
import {
  deleteChat,
  getUnreadMessagesByChat,
  sendReadReceipt,
} from '../slices/chats';
import { AppDispatch } from '../redux/store';
import { getAddress } from '../slices/user';
import flatten from 'lodash/flatten';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { defaultActionSheetOptions } from '../utils/action-sheet';

const styles = StyleSheet.create({
  bannerContainer: {
    height: 82,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: ShadeColor.Black,
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },
});

const SelectChatsModeBanner = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showActionSheetWithOptions } = useActionSheet();
  const selectChatsMode = useSelector(getSelectChatsMode);
  const selectedChats = useSelector(getSelectedChats);
  const address = useSelector(getAddress);
  const unreadMessagesByChat = useSelector(getUnreadMessagesByChat);
  if (!selectChatsMode) return null;

  const handleReadAll = () => {
    const unreadMessages: Message[] = flatten(
      Object.values(unreadMessagesByChat)
    );
    unreadMessages.forEach((message: Message) => {
      dispatch(
        sendReadReceipt({
          chatId: message.chatId,
          messageId: message.id,
          address,
        })
      );
    });
    dispatch(toggleSelectChatsMode());
  };

  const handleReadSelected = () => {
    const unreadMessagesForSelected: Message[] = flatten(
      Object.keys(selectedChats).map((chatId) => unreadMessagesByChat[chatId])
    );
    unreadMessagesForSelected.forEach((message: Message) => {
      if (message) {
        dispatch(
          sendReadReceipt({
            chatId: message.chatId,
            messageId: message.id,
            address,
          })
        );
      }
    });
    dispatch(toggleSelectChatsMode());
  };

  const handleDeleteSelected = () => {
    const options = ['Delete', 'Cancel'];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;
    const title = 'Are you sure you want to delete these conversations?';
    showActionSheetWithOptions(
      {
        ...defaultActionSheetOptions,
        options,
        destructiveButtonIndex,
        cancelButtonIndex,
        title,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          const selectedChatsArr = Object.keys(selectedChats);
          selectedChatsArr.forEach((chatId) => {
            dispatch(deleteChat(chatId));
          });
        }
        dispatch(toggleSelectChatsMode());
      }
    );
  };

  return (
    <View style={styles.bannerContainer}>
      {Object.keys(selectedChats).length > 0 ? (
        <Button variant="link" onPress={handleReadSelected}>
          Read Selected
        </Button>
      ) : (
        <Button variant="link" onPress={handleReadAll}>
          Read All
        </Button>
      )}
      <Button
        variant="link"
        disabled={Object.keys(selectedChats).length === 0}
        onPress={handleDeleteSelected}
      >
        Delete
      </Button>
    </View>
  );
};

export default SelectChatsModeBanner;
