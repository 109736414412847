import React from 'react';
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native';
import HylloText from '../components/HylloText';
import Button from '../components/Button';
import { Screens } from '../navigator/screens';
import { ShadeColor, textSizes, textWeights } from '../utils';

const BackgroundImage = require('../assets/background-image.png');
const LogoSmileImage = require('../assets/logo-smile.png');

const styles = StyleSheet.create({
  walletSuccessContainer: {
    backgroundColor: ShadeColor.Black,
    padding: 24,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  walletSuccessImage: {
    width: 143,
    height: 137,
    marginBottom: 76,
  },
  walletSuccessTitle: {
    color: ShadeColor.White,
  },
  walletSuccessTitleSpacing: {
    marginBottom: 100,
  },
  walletSuccessButton: {
    paddingVertical: 20,
  },
  walletSuccessButtonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
});

const WalletSuccess = ({ navigation }: { navigation: any }) => (
  <ImageBackground
    source={BackgroundImage}
    style={styles.walletSuccessContainer}
    resizeMode="cover"
  >
    <Image source={LogoSmileImage} style={styles.walletSuccessImage} />
    <HylloText size="poppinsH2" style={styles.walletSuccessTitle}>
      Great! You&#8217;re
    </HylloText>
    <HylloText
      size="poppinsH2"
      style={[styles.walletSuccessTitle, styles.walletSuccessTitleSpacing]}
    >
      All Set!
    </HylloText>
    <Button
      variant="primary"
      style={styles.walletSuccessButton}
      textStyle={styles.walletSuccessButtonText}
      onPress={() => {
        navigation.reset({
          index: 0,
          routes: [
            { name: 'TabScreens', params: { screen: Screens.ChatList } },
          ],
        });
      }}
      fullWidth
    >
      Welcome to Hyllo
    </Button>
  </ImageBackground>
);

export default WalletSuccess;
