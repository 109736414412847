import React, { useRef } from 'react';
import { Animated, StyleSheet } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { WarningColor, ShadeColor } from '../../utils';
import { RectButton } from 'react-native-gesture-handler';
import Feather from '@expo/vector-icons/Feather';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useDispatch } from 'react-redux';
import { deleteChat } from '../../slices/chats';
import { AppDispatch } from '../../redux/store';
import ChatListItem from './ChatListItem';
import { defaultActionSheetOptions } from '../../utils/action-sheet';

const styles = StyleSheet.create({
  rightActionButton: { width: 64 },
  rightActionContainer: {
    backgroundColor: WarningColor.Red,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightActionIcon: { color: ShadeColor.White },
});

type ItemProps = {
  id: string;
  address: string;
  time: string;
  displayMessage?: string;
  onPress: () => void;
  containerStyle?: any;
  unreadCount?: number;
  onSwipeOpen?: any;
  onSwipeClose?: any;
};

const SwipeableChatListItem = ({
  id,
  onSwipeOpen,
  onSwipeClose,
  ...props
}: ItemProps) => {
  const swipeRef = useRef(null);
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch: AppDispatch = useDispatch();
  const handleDeletePress = (closeCb: () => void) => {
    closeCb();
    const options = ['Delete', 'Cancel'];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 2;
    const title = 'Delete this chat?';
    showActionSheetWithOptions(
      {
        ...defaultActionSheetOptions,
        options,
        destructiveButtonIndex,
        cancelButtonIndex,
        title,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          dispatch(deleteChat(id));
        }
      }
    );
  };
  const renderRightAction = (
    progress: Animated.AnimatedInterpolation<string | number>,
    _: any,
    componentRef: Swipeable
  ) => {
    const trans = progress.interpolate({
      inputRange: [0, 1],
      outputRange: [64, 0],
    });
    return (
      <RectButton
        style={styles.rightActionButton}
        onPress={() => handleDeletePress(() => componentRef.close())}
      >
        <Animated.View
          style={[
            styles.rightActionContainer,
            { transform: [{ translateX: trans }] },
          ]}
        >
          <Feather name="trash" style={styles.rightActionIcon} size={24} />
        </Animated.View>
      </RectButton>
    );
  };
  return (
    <Swipeable
      ref={swipeRef}
      renderRightActions={renderRightAction}
      rightThreshold={41}
      friction={2}
      onSwipeableOpen={(_, swipableRef) => onSwipeOpen(swipableRef)}
      onSwipeableClose={(_, swipableRef) => onSwipeClose(swipableRef)}
    >
      <ChatListItem
        id={id}
        {...props}
        containerStyle={{ borderBottomWidth: 0 }}
      />
    </Swipeable>
  );
};

export default SwipeableChatListItem;
