import { Image, ImageBackground, StyleSheet, View } from 'react-native';
import HylloText from '../components/HylloText';
import { NeutralColor, ShadeColor, textSizes, textWeights } from '../utils';
import Button from '../components/Button';
import { Screens } from '../navigator/screens';

const BackgroundImage = require('../assets/background-image.png');
const LogoWelcomeImage = require('../assets/logo-welcome.png');

const styles = StyleSheet.create({
  welcomeContainer: {
    flex: 1,
    backgroundColor: ShadeColor.Black,
    paddingTop: 146,
    alignItems: 'center',
  },
  welcomeImage: {
    width: 226,
    height: 173,
  },
  welcomeTitle: {
    marginTop: 40,
    color: ShadeColor.White,
  },
  welcomeDescription: {
    color: NeutralColor.Gray04,
    marginTop: 24,
    paddingHorizontal: 24,
    textAlign: 'center',
  },
  buttonContainer: {
    paddingHorizontal: 24,
    marginTop: 84,
    width: '100%',
    maxWidth: 520,
  },
  welcomeButton: {
    paddingVertical: 16,
  },
  importWalletButtonText: {
    ...textSizes.body,
    ...textWeights.bold,
  },
});

const Welcome = ({ navigation }: { navigation: any }) => (
  <ImageBackground
    source={BackgroundImage}
    style={styles.welcomeContainer}
    resizeMode="cover"
  >
    <Image source={LogoWelcomeImage} style={styles.welcomeImage} />
    <HylloText size="poppinsH1" style={styles.welcomeTitle}>
      Say "Hyllo"
    </HylloText>
    <HylloText style={styles.welcomeDescription}>
      Welcome to a better messaging app where privacy and freedom come first
    </HylloText>
    <View style={styles.buttonContainer}>
      <Button
        variant="primary"
        onPress={() => navigation.navigate(Screens.ImportWallet)}
        style={styles.welcomeButton}
        textStyle={styles.importWalletButtonText}
        fullWidth
      >
        Import Wallet
      </Button>
      <Button
        variant="secondary"
        onPress={() => navigation.navigate(Screens.CreateWallet)}
        style={[styles.welcomeButton, { marginTop: 16 }]}
        textStyle={styles.importWalletButtonText}
        fullWidth
      >
        Create New Wallet
      </Button>
      <Button
        variant="secondary"
        onPress={() => navigation.navigate(Screens.VideoPlayer)}
        style={[styles.welcomeButton, { marginTop: 16 }]}
        textStyle={styles.importWalletButtonText}
        fullWidth
      >
        Streaming Debug
      </Button>
      {/* <Button
        variant="secondary"
        onPress={() => console.log('continue as guest')}
        style={[styles.welcomeButton, { marginTop: 16 }]}
        textStyle={styles.importWalletButtonText}
        fullWidth
      >
        Continue as Guest
      </Button> */}
    </View>
  </ImageBackground>
);

export default Welcome;
