import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from '../slices/user';
import {
  addMessageListener,
  connectClient,
  getConnectStatus,
} from '../modules/client';
import { addMessageSync } from '../slices/chats';

let messageListener: any = null;

const useConnectClient = () => {
  const address = useSelector(getAddress);
  const dispatch = useDispatch();

  useEffect(() => {
    if (address) {
      connectClient(address);
      if (!messageListener) {
        console.log('listening for messages');
        messageListener = addMessageListener((event: any) => {
          console.log('message event', event);
          try {
            const eventObj = JSON.parse(event.body);
            if (eventObj.type === 'message') {
              console.log('message eventObj', eventObj);
              dispatch(addMessageSync(eventObj.content));
            }
          } catch (e) {
            console.log(e);
          }
        });
        console.log('messageListener', messageListener);
      }
    }
  }, [address]);

  const connectionWrapper = useCallback(
    async (func: any) => {
      const connectStatus = getConnectStatus();
      if (connectStatus.dCdn) {
        await connectClient(address);
      }
      return func;
    },
    [address]
  );

  return connectionWrapper;
};

export default useConnectClient;
