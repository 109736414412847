import { Contact } from '../slices/contacts';
import { displayAddress, isAddressEqual } from './address';
import { Chat, Message } from './types';

export const findChatAddress = (chat: Chat, address: string) => {
  return (
    chat.addresses.find((addy: string) => !isAddressEqual(addy, address)) ||
    chat.addresses[0]
  );
};

export const displayChatTitle = (address: string, contacts?: any) => {
  const contactName = contacts?.[address]?.name;
  return contactName || displayAddress(address);
};

export const findChatTitle = (
  chat: Chat,
  address: string,
  contacts?: Record<string, Contact>
) => {
  const chatAddy = findChatAddress(chat, address);
  return displayChatTitle(chatAddy, contacts);
};

export const findNewestMessage = (chat: Chat) => {
  const messagesArr: Message[] = Array.isArray(chat.messages)
    ? chat.messages
    : Object.values(chat.messages || {});
  if (messagesArr.length > 0) {
    return messagesArr.reduce(
      (acc: any, message: Message) => {
        const isNewer = message.created > acc.created;
        if (isNewer) return message;
        return acc;
      },
      { created: 0 }
    );
  }
  return chat;
};

export const isUnreadMessage = (message: Message, address: string) => {
  const notFromUser = !isAddressEqual(message.from, address);
  const noReadReceipt = !message.readReceipts?.[address];
  return notFromUser && noReadReceipt;
};
