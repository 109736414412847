import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseStatus, User } from '../utils';
import { fetchContactsDB, setContactDB } from '../services/contacts';
import { RootState } from '../redux/store';
import { fetchUserDB } from '../services/users';
import { provider } from '../utils/constants';

export interface Contact {
  address: string;
  name: string;
  created: number;
}

interface ContactsSlice {
  contacts: Record<string, Contact>;
  currentContactAddress: string;
  contactInfos: User[];
  status: ResponseStatus;
  error: string;
  newContactOpen: boolean;
}

const initialState: ContactsSlice = {
  contacts: {},
  currentContactAddress: '',
  contactInfos: [],
  status: ResponseStatus.Unfetched,
  error: '',
  newContactOpen: false,
};

const fetchContactsConstant = 'fetchContacts';
const setContactConstant = 'setContact';
const deleteContactConstant = 'deleteContact';
const fetchCurrentContactInfoConstant = 'fetchCurrentContactInfo';
const fetchContactInfosConstant = 'fetchContactInfos';

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    [`${fetchContactsConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchContactsConstant}/fulfilled`]: (state, action) => {
      state.contacts = action.payload || initialState.contacts;
      state.status = ResponseStatus.Success;
    },
    [`${fetchContactsConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${setContactConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${setContactConstant}/fulfilled`]: (state, action) => {
      state.contacts[action.payload.address] = action.payload;
      state.status = ResponseStatus.Success;
    },
    [`${setContactConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${fetchCurrentContactInfoConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchCurrentContactInfoConstant}/fulfilled`]: (state, action) => {
      state.currentContactAddress =
        action.payload.address || initialState.currentContactAddress;
      const currentContactIndex = state.contactInfos.findIndex(
        (info) => info.address == action.payload.address
      );
      state.contactInfos =
        currentContactIndex > -1
          ? state.contactInfos.map((info, index) =>
              index === currentContactIndex ? action.payload : info
            )
          : state.contactInfos.concat(action.payload);
      state.status = ResponseStatus.Success;
    },
    [`${fetchCurrentContactInfoConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${fetchContactInfosConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchContactInfosConstant}/fulfilled`]: (state, action) => {
      state.contactInfos =
        action.payload.filter((x: any) => !!x) || initialState.contactInfos;
      state.status = ResponseStatus.Success;
    },
    [`${fetchContactInfosConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${deleteContactConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${deleteContactConstant}/fulfilled`]: (state, action) => {
      delete state.contacts[action.payload];
      state.status = ResponseStatus.Success;
    },
    [`${deleteContactConstant}/rejected`]: (state, action) => {
      state.error = action.payload;
      state.status = ResponseStatus.Failure;
    },
  },
});

export const fetchContacts = createAsyncThunk(
  `contacts/${fetchContactsConstant}`,
  fetchContactsDB
);

export const setContact = createAsyncThunk(
  `contacts/${setContactConstant}`,
  async (
    { address, data }: { address: string; data: any },
    { getState, dispatch }
  ) => {
    const contact = data.created
      ? { ...data, updated: Date.now() }
      : { ...data, created: Date.now() };
    const contacts = getContacts(getState() as RootState);
    const updatedContacts = {
      ...contacts,
      [contact.address]: contact,
    };
    await setContactDB({ address, data: updatedContacts });
    await dispatch(fetchCurrentContactInfo(contact.address));
    return contact;
  }
);

export const deleteContact = createAsyncThunk(
  `contacts/${deleteContactConstant}`,
  async (
    {
      userAddress,
      contactAddress,
    }: { userAddress: string; contactAddress: string },
    { getState }
  ) => {
    const contacts = getContacts(getState() as RootState);
    const updatedContacts = { ...contacts };
    delete updatedContacts[contactAddress];
    await setContactDB({ address: userAddress, data: updatedContacts });
    return contactAddress;
  }
);

const fetchSingleContactInfo = async (address: string) => {
  const user = await fetchUserDB(address);
  if (address && user?.useEns) {
    const ens = await provider.lookupAddress(address);
    return {
      ...user,
      ...(ens && { ens }),
    };
  }
  return user;
};

export const fetchCurrentContactInfo = createAsyncThunk(
  `contacts/${fetchCurrentContactInfoConstant}`,
  fetchSingleContactInfo
);

export const fetchContactInfos = createAsyncThunk(
  `contacts/${fetchContactInfosConstant}`,
  (addresses: string[]) => Promise.all(addresses.map(fetchSingleContactInfo))
);

export const getContacts = (state: RootState) => state.contacts.contacts;
export const getNewContactOpen = (state: RootState) =>
  state.contacts.newContactOpen;

export const getCurrentContactInfo = (state: RootState) =>
  state.contacts.contactInfos.find(
    (infos) => infos.address === state.contacts.currentContactAddress
  );

export const getContactInfos = (state: RootState) =>
  state.contacts.contactInfos;

export default contactsSlice.reducer;
