import { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, Pressable, StyleSheet, TextInput, View } from 'react-native';
import { getAddress, updateUser } from '../slices/user';
import { useDispatch, useSelector } from 'react-redux';
import Feather from '@expo/vector-icons/Feather';
import Octicons from '@expo/vector-icons/Octicons';

import Button from '../components/Button';
import {
  NeutralColor,
  PrimaryColor,
  ShadeColor,
  SuccessColor,
  WarningColor,
  textSizes,
} from '../utils';
import { AppDispatch } from '../redux/store';
import { Screens } from '../navigator/screens';
import { fetchUserIdDB } from '../services/users';
import HylloText from '../components/HylloText';
import { provider } from '../utils/constants';
import Tooltip from '../components/Tooltip';
import Input from '../components/Input';

const styles = StyleSheet.create({
  createUserContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: ShadeColor.Black,
    color: ShadeColor.White,
  },
  createUserTitle: {
    marginBottom: 36,
    textAlign: 'center',
  },
  userNameContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 600,
    marginHorizontal: 'auto',
  },
  ensContainer: {
    flexDirection: 'row',
    marginBottom: 24,
    width: '100%',
  },
  ensPressable: {
    color: PrimaryColor.PrimaryPurple,
    textDecorationLine: 'underline',
  },
  ensAutoFilled: {
    color: PrimaryColor.PrimaryPurple,
  },
  baseText: {
    color: ShadeColor.White,
  },
  submitButton: {
    borderColor: ShadeColor.White,
    paddingVertical: 16,
    width: '100%',
    marginTop: 44,
  },
  error: {
    color: WarningColor.Red,
  },
  useEnsPressable: { position: 'absolute', right: 12 },
  createUserInnerContainer: { padding: 24, flex: 1, width: '100%' },
  userIdLabelContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  userIdLabel: {
    marginBottom: 12,
    flexDirection: 'row',
  },
  userIdErrorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    marginBottom: 12,
  },
  asterik: {
    color: PrimaryColor.PrimaryPurple,
    marginRight: 6,
  },
  errorIcon: { marginRight: 6 },
  userNicknameLabel: {
    color: ShadeColor.White,
    marginRight: 6,
    marginBottom: 12,
  },
  ensInput: {
    borderColor: PrimaryColor.PrimaryPurple,
    backgroundColor: NeutralColor.Gray09,
  },
  inputCaption: { color: NeutralColor.Gray04, marginBottom: 24 },
  userNicknameContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  errorInput: {
    borderColor: WarningColor.Red,
  },
});

const CreateUser = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const address = useSelector(getAddress);
  const [ens, setEns] = useState('');
  const [useEns, setUseEns] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [nameError, setNameError] = useState('');
  const [userIdInput, setUserIdInput] = useState('');
  const [userIdError, setUserIdError] = useState('');
  const userNicknameRef = useRef<TextInput>(null);

  useEffect(() => {
    const findEns = async () => {
      const ens = await provider.lookupAddress(address);
      if (ens) {
        setEns(ens);
      }
    };
    if (address) {
      findEns();
    }
  }, [address]);

  const handleEraseInput = useCallback(() => {
    if (useEns) {
      setUseEns(false);
    }
    if (userIdInput) {
      setUserIdInput('');
    }
    if (!!userIdError) {
      setUserIdError('');
    }
  }, [
    useEns,
    userIdInput,
    userIdError,
    setUseEns,
    setUserIdInput,
    setUserIdError,
  ]);

  const handleButtonPress = useCallback(async () => {
    if (!userIdInput && !useEns) {
      setUserIdError('ID is required');
      return;
    }
    if ((useEns && ens) || (userIdInput && !userIdError)) {
      await dispatch(
        updateUser({
          address,
          name: nameInput,
          userId: userIdInput.toLowerCase(),
          useEns,
        })
      );
      if (Platform.OS === 'web') {
        return navigation.navigate(Screens.WalletSuccess);
      }
      return navigation.navigate(Screens.Passcode);
    }
  }, [userIdInput, userIdError, useEns, ens, nameInput, address, dispatch]);

  const handleUserIdChange = useCallback(
    async (input: string) => {
      setUserIdInput(input);
      if (input) {
        const includesSpaces = input.match(/[^\w]/g)?.includes;
        if (includesSpaces) {
          setUserIdError('Please use only letters, numbers, and underscores');
          return;
        } else if (input.length > 24) {
          setUserIdError('User ID must be 24 characters or less');
          return;
        }
        const userId = await fetchUserIdDB(input);
        if (userId) {
          setUserIdError('ID already taken');
        } else {
          setUserIdError('');
        }
      }
    },
    [setUserIdInput, setUserIdError]
  );

  const handleNameChange = useCallback(
    (input: string) => {
      setNameInput(input);
      if (input.length > 32) {
        setNameError('Name must be 32 characters or less');
      } else if (!!nameError) {
        setNameError('');
      }
    },
    [setNameInput, setNameError, nameError]
  );

  let userIdSupportText = (
    <Tooltip
      width={200}
      height={70}
      popover={<HylloText>You can use a-z, 0-9, and underscores.</HylloText>}
    >
      <Octicons name="question" size={20} color={NeutralColor.Gray06} />
    </Tooltip>
  );
  if (userIdError && !useEns) {
    userIdSupportText = (
      <View style={styles.userIdErrorContainer}>
        <Feather
          name="alert-circle"
          color={WarningColor.Red}
          style={styles.errorIcon}
          size={20}
        />
        <HylloText style={styles.error}>{userIdError}</HylloText>
      </View>
    );
  } else if (useEns || userIdInput) {
    userIdSupportText = (
      <Feather name="check" color={SuccessColor.Primary} size={20} />
    );
  }

  let nicknameSupportedText = (
    <Tooltip
      width={200}
      height={90}
      popover={
        <HylloText>
          Nicknames can contain up to 20 alphanumeric characters and symbols.
        </HylloText>
      }
    >
      <Octicons name="question" size={20} color={NeutralColor.Gray06} />
    </Tooltip>
  );
  if (nameError) {
    nicknameSupportedText = (
      <View style={styles.userIdErrorContainer}>
        <Feather
          name="alert-circle"
          color={WarningColor.Red}
          style={styles.errorIcon}
          size={20}
        />
        <HylloText style={styles.error}>{nameError}</HylloText>
      </View>
    );
  } else if (nameInput) {
    nicknameSupportedText = (
      <Feather name="check" color={SuccessColor.Primary} size={20} />
    );
  }

  const userIdDisabled = !(userIdInput && !userIdError) && !useEns;

  return (
    <View style={styles.createUserContainer}>
      <View style={styles.createUserInnerContainer}>
        <HylloText size="h2" style={styles.createUserTitle}>
          Create Your Account
        </HylloText>
        <View style={styles.userNameContainer}>
          <View style={styles.userIdLabelContainer}>
            <View style={styles.userIdLabel}>
              <HylloText weight="bold" style={styles.baseText}>
                Unique User ID&nbsp;
              </HylloText>
              <HylloText weight="bold" style={styles.asterik}>
                *
              </HylloText>
            </View>
            {userIdSupportText}
          </View>
          <Input
            style={useEns && styles.ensInput}
            onChangeText={handleUserIdChange}
            value={useEns ? ens : userIdInput}
            placeholder="To help others find your account"
            placeholderTextColor={NeutralColor.Gray07}
            keyboardAppearance="dark"
            editable={!useEns}
            autoCapitalize="none"
            returnKeyType="next"
            onSubmitEditing={() => userNicknameRef.current?.focus()}
            blurOnSubmit={false}
            showCancel={useEns || !!userIdInput}
            onCancel={handleEraseInput}
            hasError={!!userIdError && !useEns}
          />
          {!!ens ? (
            useEns ? (
              <View style={styles.ensContainer}>
                <HylloText style={styles.ensAutoFilled}>
                  ENS auto-filled
                </HylloText>
              </View>
            ) : (
              <View style={styles.ensContainer}>
                <HylloText>Use existing ENS:&nbsp;</HylloText>
                <Pressable onPress={() => setUseEns(true)}>
                  <HylloText style={styles.ensPressable}>{ens}</HylloText>
                </Pressable>
                <HylloText>?</HylloText>
              </View>
            )
          ) : (
            <HylloText size="caption" style={styles.inputCaption}>
              User ID must be unique so that other users can find you on the app
              without needing the crazy-long wallet address.
            </HylloText>
          )}
          <View style={styles.userNicknameContainer}>
            <HylloText weight="bold" style={styles.userNicknameLabel}>
              User Nickname
            </HylloText>
            {nicknameSupportedText}
          </View>
          <Input
            ref={userNicknameRef}
            onChangeText={handleNameChange}
            value={nameInput}
            placeholder="Pick a nickname for yourself"
            placeholderTextColor={NeutralColor.Gray07}
            keyboardAppearance="dark"
            returnKeyType="done"
            onSubmitEditing={handleButtonPress}
            blurOnSubmit={false}
            hasError={!!nameError}
          />
          <HylloText size="caption" style={styles.inputCaption}>
            Nickname will be displayed on your account page. What do you want to
            be called?
          </HylloText>
          <Button
            onPress={handleButtonPress}
            style={styles.submitButton}
            variant="primary"
            disabled={userIdDisabled || !!nameError}
            fullWidth
          >
            Confirm
          </Button>
        </View>
      </View>
    </View>
  );
};

export default CreateUser;
